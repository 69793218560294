import React from 'react';
import { useController } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '../../components';

type Props = {
  name: string;
  label: string;
};
const FormSwitch: React.FC<Props> = ({ name, label }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
  });

  return (
    <FormControlLabel
      ref={ref}
      {...inputProps}
      control={<Switch name={name} checked={!!inputProps.value} onChange={inputProps.onChange} />}
      label={label}/>
  );
};

FormSwitch.displayName = 'FormSwitch';
export default FormSwitch;
