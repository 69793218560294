import React, { forwardRef } from 'react';
import MUIBox, { BoxProps } from '@mui/material/Box';
import { styled } from '../_styles';

type Props = {
  testId?: string;
  className?: string;
  width?: BoxProps['width'];
  height?: BoxProps['height'];
  onClick?: BoxProps['onClick'];
  flexGrow?: BoxProps['flexGrow'];
  flexBasis?: BoxProps['flexBasis'];
  component?: BoxProps['component'];
  position?: BoxProps['position'];
  bgcolor?: BoxProps['bgcolor'];
  color?: BoxProps['color'];
  bottom?: BoxProps['bottom'];
  style?: BoxProps['sx'];
  p?: BoxProps['p'];
  pt?: BoxProps['pt'];
  pb?: BoxProps['pb'];
  pr?: BoxProps['pr'];
  px?: BoxProps['px'];
  py?: BoxProps['py'];
  mt?: BoxProps['mt'];
  minHeight?: BoxProps['minHeight'];
};

const Box: React.FC<Props> = forwardRef(({ testId, style, ...props }, ref) => (
  <MUIBox ref={ref} data-test={testId} sx={style} {...props} />
));

export const AbsoluteBox = styled(Box)<{
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  zIndex?: number;
}>(({
  top, left, right, bottom, zIndex,
}) => ({
  top,
  left,
  right,
  bottom,
  zIndex,
  position: 'absolute',
}));

Box.displayName = 'Box';

export default Box;
