import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

import { FeatureFlag, FeatureFlags, UserAttribute } from './types';

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    mixpanel.track('Experiment Viewed', {
      'Experiment Id': experiment.key,
      'Variant Id': result.variationId,
      dimension: `${experiment.key}-${result.variationId}`,
      $source: 'growthbook',
    });
  },
});

function useFlag<T extends FeatureFlag>(flag?: T): FeatureFlags[T] | null {
  return growthbook.feature(flag ?? '').value;
}

function setUserAttribute(attributes: Partial<{ [kei in UserAttribute]: string }>) {
  growthbook.setAttributes(attributes);
}

const FeatureFlagProvider: React.FC<{
  apiKey: string;
}> = ({ children, apiKey }) => {
  // todo improve loading management and default state
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // todo improve this and refresh them
    fetch(`https://cdn.growthbook.io/api/features/${apiKey}`)
      .then(res => res.json())
      .then(parsed => {
        growthbook.setFeatures(parsed.features);
        setLoaded(true);
      });
  }, [apiKey]);

  return loaded ? (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  ) : null;
};

export { FeatureFlagProvider, useFlag, setUserAttribute };
