import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';

// @ts-ignore
export const datePickerStyleOverrides: ComponentsOverrides<Theme>['MuiCalendarPicker'] = {
  root: ({ theme }: { theme: Theme }) => ({
    '.PrivatePickersFadeTransitionGroup-root, .MuiButtonBase-root': {
      fontWeight: 600,
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
    },
    '.MuiYearPicker-root, .MuiMonthPicker-root, .MuiDayPicker-root': {
      fontWeight: 600,
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
      borderRadius: 8,
      boxShadow: 'none',
    },
    '.PrivatePickersYear-yearButton, .PrivatePickersMonth-root': {
      fontWeight: 600,
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
      borderRadius: 8,
      boxShadow: 'none',
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderColor: theme.palette.secondary.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.light,
      },
    },
    '.MuiButtonBase-root': {
      borderRadius: 8,
      color: theme.palette.text.light,
      '&.Mui-selected, &.Mui-selected.range,&.Mui-selected:hover, &.Mui-selected.MuiPickersDay-today':
        {
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          borderRadius: 8,
          fontWeight: 600,
          borderColor: theme.palette.secondary.main,
        },
      '&.MuiPickersDay-today': {
        color: theme.palette.secondary.main,
        border: '2px solid',
        borderColor: theme.palette.secondary.main,
      },
      '&.MuiPickersDay-today.Mui-selected': {
        color: 'white',
      },
      '&.range': {
        backgroundColor: theme.palette.secondary.superLight,
      },
      '&.Mui-disabled': {
        backgroundColor: 'white',
        color: theme.palette.grey[200],
      },
    },
  }),
};
