import { Box, Stack, styled } from '@financial-tool/components';

const CARD_MARGIN = 20;

export const CarouselWrapper = styled(Stack)({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
});

CarouselWrapper.displayName = 'CarouselWrapper';

export const CarouselContent = styled(Stack, {
  shouldForwardProp: (p: string) => !['pos'].includes(p),
})<{ pos: number }>(({ pos }) => ({
  height: '100%',
  flexDirection: 'row',
  transition: 'transform 0.5s ease',
  transform: pos > 0 ? `translateX(calc(-${pos * 100}% - ${pos * CARD_MARGIN}px))` : '',
}));

CarouselContent.displayName = 'CarouselContent';

export const CarouselSlot = styled(Box)({
  flex: '1 0 100%',
  fleBasis: '100%',
  marginRight: `${CARD_MARGIN}px`,
  height: '100%',
});

CarouselSlot.displayName = 'CarouselSlot';
