import React, { useMemo } from 'react';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { CloseIcon as DefaultCloseIcon } from '@financial-tool/icons';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide, { SlideProps } from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslate } from '@xtreamsrl/react-i18n';
import IconButton from '../IconButton/IconButton';
import Typography from '../Typography/Typography';
import Stack from '../Stack';
import Button from '../Button/Button';

type ButtonProps = Pick<
React.ComponentProps<typeof Button>,
'disabled' | 'onClick' | 'testId' | 'loading' | 'startIcon' | 'size' | 'color'
> & { text?: string };
export type Actions = {
  [k in 'primary' | 'secondary' | 'tertiary']?: ButtonProps;
};

const SlideUp = React.forwardRef<unknown, SlideProps>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

type Props = {
  testId?: string;
  className?: string;
  title?: React.ReactNode | string;
  body?: string;
  mode?: 'form' | 'default' | 'cta';
  titleProps?: Partial<React.ComponentProps<typeof Typography>>;
  open: boolean;
  onClose?: () => void;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  closeIcon?: React.ReactNode;
  buttons?: Actions;
  customActions?: React.ReactNode;
  contentStyle?: DialogContentProps['sx'];
  noClose?: boolean;
  noActions?: boolean;
  fullScreen?: boolean;
  animation?: 'slide';
};

const Dialog: React.FC<Props> = ({
  testId,
  className,
  open,
  onClose,
  title,
  fullWidth,
  maxWidth,
  body,
  buttons,
  contentStyle,
  closeIcon = <DefaultCloseIcon />,
  children,
  mode = 'default',
  noClose = false,
  noActions = false,
  customActions,
  animation,
  fullScreen,
}) => {
  const t = useTranslate();

  const formActions = useMemo(
    () => (mode === 'form' ? (
      <>
        <Button onClick={onClose} {...buttons?.tertiary} variant="text" color="secondary">
          {buttons?.tertiary?.text ?? t('buttons.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          {...buttons?.primary}
          loading={buttons?.primary?.loading}>
          {buttons?.primary?.text ?? t('buttons.save')}
        </Button>
      </>
    ) : undefined),
    [buttons?.primary, buttons?.tertiary, mode, onClose, t],
  );

  const defaultActions = buttons ? (
    <>
      {buttons.tertiary && (
        <Button variant="text" color="secondary" {...buttons.tertiary}>
          {buttons.tertiary?.text ?? t('buttons.cancel')}
        </Button>
      )}
      {buttons.secondary && (
        <Button variant="contained" color="secondary" {...buttons.secondary}>
          {buttons.secondary.text}
        </Button>
      )}
      {buttons.primary && (
        <Button variant="contained" color="primary" {...buttons.primary}>
          {buttons.primary.text}
        </Button>
      )}
    </>
  ) : undefined;

  const TransitionComponent = animation === 'slide' ? SlideUp : undefined;

  return (
    <MUIDialog
      fullScreen={fullScreen}
      data-test={testId}
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      sx={
        mode === 'cta'
          ? {
            textAlign: 'center',
            '.MuiDialogContent-root': { border: 'none' },
            '.MuiDialogActions-root': { paddingBottom: '32px' },
          }
          : undefined
      }
      onClose={onClose}
      TransitionComponent={TransitionComponent}>
      {(title || !noClose) && (
        <DialogTitle>
          {title}
          {!noClose && (
            <IconButton
              testId="close-icon-button"
              aria-label="close"
              onClick={onClose}
              style={{
                position: 'absolute',
                right: 2,
                top: 2,
                color: theme => theme.palette.grey.main,
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme => theme.palette.grey.dark,
                  transform: 'scale(1.05)',
                },
              }}>
              {closeIcon}
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent dividers sx={contentStyle}>
        {body && <DialogContentText id="alert-dialog-description">{body}</DialogContentText>}
        {children}
      </DialogContent>
      {!noActions && (
        <DialogActions>
          <Stack
            direction="row"
            width="100%"
            spacing={2}
            alignItems="center"
            justifyContent={mode === 'cta' ? 'center' : 'flex-end'}>
            {customActions ?? formActions ?? defaultActions}
          </Stack>
        </DialogActions>
      )}
    </MUIDialog>
  );
};

Dialog.displayName = 'Dialog';

export default Dialog;
