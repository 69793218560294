import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import loadBilledAmountsAPI from '../apis/loadBilledAmounts';

const {
  hook: useGetBilledAmounts,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('estimated/load', () => loadBilledAmountsAPI());

export { useGetBilledAmounts };

export default {
  fulfilled,
  rejected,
  pending,
};
