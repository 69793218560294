import React, { useCallback } from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { DeleteIcon, FilterIcon } from '@financial-tool/icons';
import {
  Divider, Stack, Typography, useDialog,
} from '@financial-tool/components';
import Button from '../Button/Button';
import Dialog from '../Dialog/Dialog';
import { styled } from '../_styles';
import IconButton from '../IconButton/IconButton';

const FiltersButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'selected',
})<{
  selected?: boolean;
}>(({ theme, selected }) => {
  const selectedStyle = !selected
    ? {
      color: theme.palette.text.light,
      backgroundColor: 'white',
    }
    : undefined;
  return {
    padding: '4px 16px',
    textTransform: 'unset',
    fontSize: '16px',
    ...selectedStyle,
    '&:hover': {
      color: 'white',
    },
  };
});

type Props = {
  onFiltersApplied: () => void;
  clearFilters: () => void;
  resetFilters: () => void;
  areFiltersApplied: boolean;
  filters: { label: string; filterElement: React.ReactNode }[];
  title: string;
};
const MoreFiltersDialog: React.FC<Props> = ({
  title,
  onFiltersApplied,
  clearFilters,
  resetFilters,
  filters,
  areFiltersApplied,
}) => {
  const t = useTranslate();
  const [isOpen, open, close] = useDialog();
  const label = areFiltersApplied ? t('buttons.filtersApplied') : t('buttons.allFilters');

  const closeAndClear = useCallback(() => {
    close();
    clearFilters();
  }, [clearFilters, close]);

  const closeAndApply = useCallback(() => {
    onFiltersApplied();
    close();
  }, [onFiltersApplied, close]);

  return (
    <>
      <FiltersButton
        onClick={open}
        variant="contained"
        color="secondary"
        startIcon={<FilterIcon />}
        selected={areFiltersApplied}>
        {label}
      </FiltersButton>
      {areFiltersApplied && (
        <IconButton onClick={resetFilters} style={{ backgroundColor: 'white' }}>
          <DeleteIcon color="error" />
        </IconButton>
      )}
      <Dialog
        open={isOpen}
        onClose={closeAndClear}
        title={title}
        buttons={{
          primary: {
            onClick: closeAndApply,
            text: t('buttons.applyFilters'),
            color: 'secondary',
          },
          tertiary: { onClick: closeAndClear, text: t('buttons.cancelFilters') },
        }}>
        <Stack divider={<Divider />} spacing={3}>
          {filters.map(({ label: filterLabel, filterElement }) => (
            <Stack spacing={1}>
              <Typography fontSize="13px" fontWeight={700} color="text.light">
                {filterLabel}
              </Typography>
              {filterElement}
            </Stack>
          ))}
        </Stack>
      </Dialog>
    </>
  );
};

MoreFiltersDialog.displayName = 'MoreFiltersDialog';
export default MoreFiltersDialog;
