/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  IconButton,
  Stack,
  styled,
  Typography,
  useColors,
  useMenu,
  DemoRegisterButton,
} from '@financial-tool/components';
import { ArrowDownIcon } from '@financial-tool/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Path } from '@financial-tool/routing';
import { useEnvVars } from '@xtreamsrl/react-config';
import { accountantMenuItems, dashboardMenuItems } from '../menuLinks';
import { useAccountantMode } from '../../../../user-session/hooks/useAccountantMode';
import SupportButton from '../SupportButton';
import { linkStyle } from '../linkStyle';
import PopupMobileMenu from '../PopupMobileMenu';

const StyledNavLink = styled(NavLink)(({ theme }) => linkStyle(theme));
const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  borderBottom: `2px solid ${theme.palette.grey.light}`,
  width: '100%',
  position: 'fixed',
  zIndex: 10,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const MobileMenu: React.VFC = () => {
  const t = useTranslate();
  const { demo } = useEnvVars();
  const { text } = useColors();
  const { pathname } = useLocation();
  const { accountantMode } = useAccountantMode();
  const {
    openMenu, isOpen, anchorEl, closeMenu,
  } = useMenu();

  const menuItems = accountantMode ? accountantMenuItems : dashboardMenuItems;

  const current = useMemo(
    () => menuItems.find(({ path }) => path !== Path.Home && pathname.includes(path)) ?? menuItems[0],
    [menuItems, pathname],
  );

  useEffect(() => {
    closeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Container direction="row" justifyContent="space-between">
      <StyledNavLink to={current.path}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            direction="row"
            alignItems="center"
            style={{ overflow: 'hidden', cursor: 'pointer' }}>
            {current.icon}
            <Typography ml={1} noWrap>
              {t(`menu.${current.translationLabel}`)}
            </Typography>
          </Stack>
          <IconButton
            onClick={e => {
              e.preventDefault();
              openMenu(e.currentTarget);
            }}>
            <ArrowDownIcon sx={{ fill: text.main }} />
          </IconButton>
        </Stack>
      </StyledNavLink>

      <PopupMobileMenu
        title={t('menu.sections')}
        menuItems={menuItems}
        isOpen={isOpen}
        anchorEl={anchorEl}
        closeMenu={closeMenu}/>

      {demo ? (
        <Stack pr={2} justifyContent="center">
          <DemoRegisterButton withIcon />
        </Stack>
      ) : (
        <SupportButton />
      )}
    </Container>
  );
};

MobileMenu.displayName = 'MobileMenu';
export default MobileMenu;
