import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { UserParams } from '../types/user';
import signUpAPI from '../apis/signUp';

const {
  hook: useSignUp,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('user/onboard', (user: UserParams) => signUpAPI(user));

export { useSignUp };

export default {
  fulfilled,
  rejected,
  pending,
};
