import React from 'react';
import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';

type Props = {
  testId?: string;
  disableRipple?: IconButtonProps['disableRipple'];
  disabled?: IconButtonProps['disabled'];
  size?: IconButtonProps['size'];
  onClick?: IconButtonProps['onClick'];
  color?: IconButtonProps['color'];
  style?: IconButtonProps['sx'];
  variant?: 'filled';
  onMouseDown?: IconButtonProps['onMouseDown'];
};

const IconButton: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ testId, style, ...props }, ref) => (
    <MUIIconButton data-test={testId} {...props} sx={style} ref={ref} />
  ),
);

IconButton.displayName = 'IconButton';

export default IconButton;
