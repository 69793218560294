import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import {
  TransactionFiltersParams,
  TransactionSortingParams,
} from './types/transactionFiltersParams';

type State = {
  filters: TransactionFiltersParams;
  sorting: TransactionSortingParams;
};

const initialState: State = {
  filters: {
    keyword: '',
    bank: 'all',
    transactionType: 'all',
    minAmount: undefined,
    maxAmount: undefined,
    startDate: new Date().subtract(30, 'd').toISOString(),
    endDate: new Date().toISOString(),
  },
  sorting: {
    sortBy: 'executionDate',
    direction: 'desc',
  },
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    changeSorting(state, action: PayloadAction<Partial<TransactionSortingParams>>) {
      state.sorting = { ...state.sorting, ...action.payload };
    },
    changeFilters(state, action: PayloadAction<Partial<TransactionFiltersParams>>) {
      let update = action.payload;
      const { startDate, endDate } = update;

      if (startDate && new Date(startDate).isSame(new Date(initialState.filters.startDate), 'd')) {
        update = { ...update, startDate: initialState.filters.startDate };
      }

      if (endDate && new Date(endDate).isSame(new Date(initialState.filters.endDate), 'd')) {
        update = { ...update, endDate: initialState.filters.endDate };
      }
      state.filters = { ...state.filters, ...update };
    },
    resetFilters(state) {
      state.filters = { ...state.filters, ...initialState.filters };
    },
  },
});
export default transactionsSlice.reducer;

export const { changeSorting, changeFilters, resetFilters } = transactionsSlice.actions;

const selectTransactionsState = (state: RootState) => state.transactions;
export const selectTransactionsFilters = createSelector(selectTransactionsState, s => s.filters);
export const selectFiltersApplied = createSelector(
  selectTransactionsFilters,
  filters => !!Object.entries(filters).find(([key, value]) => {
    const initalState = initialState.filters;
    const filter = key as keyof TransactionFiltersParams;
    return initalState[filter] !== value;
  }),
);
export const selectTransactionsSorting = createSelector(selectTransactionsState, s => s.sorting);
