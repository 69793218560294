import React from 'react';
import { useController } from 'react-hook-form';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

type Props = {
  name: string;
  options: { value: string; label: string }[];
};
const FormRadioGroup: React.FC<Props> = ({ name, options }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
  });

  return (
    <RadioGroup {...inputProps}>
      {options.map(({ value, label }) => (
        <FormControlLabel data-test={`${value}-option`} value={value} control={<Radio color="secondary" />} label={label} />
      ))}
    </RadioGroup>
  );
};

FormRadioGroup.displayName = 'FormRadioGroup';
export default FormRadioGroup;
