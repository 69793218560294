import React from 'react';
import { ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';

const ThemeProvider: React.FC = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  </StyledEngineProvider>
);

ThemeProvider.displayName = 'ThemeProvider';

export default ThemeProvider;
