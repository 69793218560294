import React, { useCallback, useEffect } from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useEnvVars } from '@xtreamsrl/react-config';
import Dialog from '../Dialog';
import Stack from '../Stack';
import Typography from '../Typography';
import { styled } from '../_styles';
import ProductImg from '../ProductImg';
import { DemoEvents, useTracker } from '../../analytics/demo';

const ResponsiveStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    'div:last-child': {
      marginLeft: '48px',
    },
  },
}));

type Props = {
  open: boolean;
  closeDialog: () => void;
};
const DemoModal: React.FC<Props> = ({ open, closeDialog }) => {
  const t = useTranslate();
  const { productUrl } = useEnvVars();
  const { track } = useTracker();

  const signUpForProduct = useCallback(() => {
    track(DemoEvents.DemoSignupClick);
    window.location.replace(productUrl);
  }, [productUrl, track]);

  useEffect(() => {
    if (open) track(DemoEvents.DemoSignupModalOpen);
  }, [open, track]);

  return (
    <Dialog
      testId="demo-modal"
      maxWidth="md"
      open={open}
      onClose={closeDialog}
      noClose
      buttons={{
        secondary: {
          onClick: signUpForProduct,
          text: t('splash.signup'),
        },
        tertiary: {
          onClick: closeDialog,
          text: t('general.demo.keepGoing'),
        },
      }}>
      <ResponsiveStack>
        <Stack spacing={1}>
          <Typography fontWeight="bold" color="secondary" textTransform="uppercase">
            {t('general.demo.product')}
          </Typography>
          <Typography variant="h3" fontWeight="bold">
            {t('general.demo.title')}
          </Typography>
          <Typography color="text.light">{t('general.demo.body')}</Typography>
        </Stack>

        <Stack direction="column" flex={1} alignItems="flex-end" justifyContent="center">
          <ProductImg width="100%" p="16px 0 0 0" />
        </Stack>
      </ResponsiveStack>
    </Dialog>
  );
};

DemoModal.displayName = 'DemoModal';
export default DemoModal;
