import React from 'react';
import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';

type Props = {
  text: React.ReactChild;
  children: React.ReactElement;
  placement?: TooltipProps['placement'];
  disabled?: boolean;
  style?: TooltipProps['sx'];
  // todo improve this type
  bgcolor?: string;
  maxWidth?: string;
};

const Tooltip: React.FC<Props> = ({
  text,
  children,
  disabled = false,
  style,
  bgcolor,
  maxWidth,
  ...rest
}) => (
  <MUITooltip
    open={disabled ? false : undefined}
    title={text}
    sx={{ cursor: 'pointer', ...style }}
    children={<span>{children}</span>}
    componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: bgcolor,
          maxWidth,
        },
      },
    }}
    {...rest}/>
);

Tooltip.displayName = 'Tooltip';

export default Tooltip;
