import React from 'react';
import {
  StaticDatePicker as MuiStaticDatePicker,
  StaticDatePickerProps as MuiProps,
} from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

export type StaticDatePickerProps = MuiProps<string>;

type Props = {
  views: StaticDatePickerProps['views'];
  value: StaticDatePickerProps['value'];
  renderDay?: StaticDatePickerProps['renderDay'];
  onChange: StaticDatePickerProps['onChange'];
  shouldDisableDate?: StaticDatePickerProps['shouldDisableDate'];
};
const StaticDatePicker: React.FC<Props> = props => (
  <MuiStaticDatePicker
    {...props}
    displayStaticWrapperAs="desktop"
    openTo="day"
    renderInput={params => <TextField {...params} />}/>
);

StaticDatePicker.displayName = 'StaticDatePicker';
export default StaticDatePicker;
