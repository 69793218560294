import { Components, Theme } from '@mui/material';

export const dialogStyleOverrides: Components<Theme> = {
  MuiBackdrop: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&[class*="MuiDialog-backdrop"]': { backgroundColor: theme.palette.background.overlay },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiDialogContent-dividers': {
          border: 'none',
          borderBottom: `2px solid ${theme.palette.grey.superLight}`,
        },
      }),
    },
  },
  MuiDialogActions: { styleOverrides: { root: { padding: '16px' } } },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: '24px',
        fontWeight: 700,
        color: theme.palette.grey.dark,
        padding: '32px 24px 16px 24px',
      }),
    },
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: ({ theme }) => ({ fontSize: '16px', fontWeight: 400, color: theme.palette.grey.dark }),
    },
  },
};
