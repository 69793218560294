import { errorMessages } from '@financial-tool/utils';
import { useMemo } from 'react';
import { useGetVatInfo } from '../asyncHandlers/getVatInfo';

export default function useValidateVat() {
  const {
    execute: getVatInfo,
    loading,
    error,
    response,
    success,
    errorCode,
    errorMessage,
  } = useGetVatInfo();

  const validationErrorMessage = useMemo(() => {
    if (error) {
      const { code } = response as unknown as { code: 400 | 404 | 502 };
      return errorMessages.vatValidation[code];
    }
    return undefined;
  }, [error, response]);

  return {
    error,
    loading,
    getVatInfo,
    response,
    errorMessage: validationErrorMessage,
    errorInfo: errorCode && errorMessage ? { code: errorCode, message: errorMessage } : undefined,
    success,
  };
}
