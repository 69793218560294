import { BilledAmountEntity } from '../../estimated-billed-amounts/types/billedAmount';
import { Expense } from '../../expenses/types/expense';
import { InstallmentTypeEnum } from '../../invoices/types/invoice';
import { PlanningFrequency } from '../../planning/types';

type Invoice = {
  id: string;
  title: string;
  date: string;
};

interface InvoicePayment {
  id: string;
  reason: InstallmentTypeEnum;
  ordinal: number | undefined;
}

export interface InstallmentAndItsInvoice {
  installment: InvoicePayment;
  invoice: Invoice;
}

interface Transaction {
  bankLogoUrl?: string;
}

export type TransactionCashFlowEvent = CashFlowEventBase & {
  type: 'transaction';
  details: Transaction;
};
export type EstimatedExpenseCashFlowEvent = CashFlowEventBase & {
  type: 'estimatedExpense';
  details: Expense;
};
export type InvoiceCashFlowEvent = CashFlowEventBase & {
  type: 'invoice';
  details: InstallmentAndItsInvoice;
};
export type BilledAmountCashFlowEvent = CashFlowEventBase & {
  type: 'estimatedIncome';
  details: BilledAmountEntity;
};

type CashFlowEventBase = {
  date: string;
  status: 'actual' | 'expected' | 'estimated';
  description: string;
  amount: number;
};

export type CashFlowEventResp =
  | TransactionCashFlowEvent
  | EstimatedExpenseCashFlowEvent
  | InvoiceCashFlowEvent
  | BilledAmountCashFlowEvent;

export type PlanningProblemParams = {
  planned: number;
  billed: number;
  actual: number;
  diff: number;
  totalEstimated: number;
};

export enum CashFLowPeriodHintEnum {
  INFLOW_PLANNING_PROBLEM = 'INFLOW_PLANNING_PROBLEM',
  OUTFLOW_PLANNING_PROBLEM = 'OUTFLOW_PLANNING_PROBLEM',
}

export type CashFLowPeriodHintMapType = {
  [CashFLowPeriodHintEnum.INFLOW_PLANNING_PROBLEM]?: PlanningProblemParams;
  [CashFLowPeriodHintEnum.OUTFLOW_PLANNING_PROBLEM]?: PlanningProblemParams;
};

export type CashflowMonthItem = {
  date: string;
  endDate: string;
  totalActualIncome: number;
  totalIncome: number;
  totalActualSpent: number;
  totalSpent: number;
  totalRemaining: number;
  initialMonthAmount: number;
  totalPlannedExpense: number;
  totalPlannedIncome: number;
  totalBilledExpense: number;
  totalBilledIncome: number;
  hints: CashFLowPeriodHintMapType;
};

export type CashFlowBody = Omit<CashFlow, 'months' | 'initialAmount' | 'hints'>;

export enum CashFLowHintEnum {
  LIQUIDITY_OK = 'LIQUIDITY_OK',
  LIQUIDITY_PROBLEM = 'LIQUIDITY_PROBLEM',
}

export type CashFlowHints = {
  [CashFLowHintEnum.LIQUIDITY_OK]?: {};
  [CashFLowHintEnum.LIQUIDITY_PROBLEM]: {
    month: string;
  };
};

export enum BalanceHintEnum {
  BALANCE_OK = 'BALANCE_OK',
  BALANCE_KO = 'BALANCE_KO',
  BALANCE_WARNING = 'BALANCE_WARNING',
}
export type BalanceHint = {
  [BalanceHintEnum.BALANCE_WARNING]: {
    requiredInflow: number;
  };
  [BalanceHintEnum.BALANCE_OK]?: {};
  [BalanceHintEnum.BALANCE_KO]?: {};
};

export type BalanceHintWithData =
  | {
    key: BalanceHintEnum.BALANCE_WARNING;
    data: {
      requiredInflow: number;
    };
  }
  | {
    key: Omit<BalanceHintEnum, BalanceHintEnum.BALANCE_WARNING>;
    data?: undefined;
  };

export type CashFlow = {
  initialAmount: number;
  periodStart: string;
  periodEnd: string;
  hint?: CashFlowHints & BalanceHint;
  months: CashflowMonthItem[];
};

export type CashflowPlanningCellUpdate = {
  flow: 'in' | 'out';
  amount: number;
  date: string;
  endDate?: string | null;
  frequency: PlanningFrequency;
};

export type EstimatedCashflowUpdate = Pick<
CashflowMonthItem,
'totalRemaining' | 'initialMonthAmount' | 'totalPlannedIncome' | 'totalPlannedExpense'
>;
