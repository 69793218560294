import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { CheckIcon } from '@financial-tool/icons';
import Badge from '../Badge';

type Props = {
  testId?: string;
  label: string;
  icon?: React.ReactElement;
  onClick?: MenuItemProps['onClick'];
  disabled?: boolean;
  selected?: boolean;
  notification?: string | number;
  style?: MenuItemProps['sx'];
  variant?: 'popup';
};

const MenuItem: React.FC<Props> = ({
  testId,
  label,
  icon,
  onClick,
  disabled,
  style,
  selected = false,
  notification = 0,
  variant,
}) => (
  // @ts-ignore
  <MUIMenuItem
    variant={variant}
    data-test={testId}
    onClick={onClick}
    disableRipple
    sx={{ width: 300, ...style }}
    disabled={disabled}
    selected={selected}>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}

    <Badge content={notification}>
      <ListItemText
        sx={{
          '.MuiTypography-root': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}>
        {label}
      </ListItemText>
    </Badge>
    <ListItemIcon style={{ flex: 1 }}>
      {selected && variant === 'popup' && (
        <CheckIcon color="primary" style={{ margin: '0 0 0 auto' }} />
      )}
    </ListItemIcon>
  </MUIMenuItem>
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
