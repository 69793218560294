import { useTranslate } from '@xtreamsrl/react-i18n';
import { Updater, useInfiniteQuery } from '@tanstack/react-query';
import { queryClient } from './config';

type Options<TData> = {
  staleTime?: number;
  getNextPageParam: (lastPage: TData) => number | undefined;
};

type Error = {
  code?: string;
  message?: string;
};

function useInfiniteQueryTask<TData, Params extends Array<any>>(
  key: string[],
  fetchFunction: (pageParams: { pageParam: number }, ...args: Params) => Promise<TData>,
  params: Params,
  options?: Options<TData>,
) {
  const {
    data,
    error,
    refetch,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<TData, Error>(
    [...key, params],
    ({ pageParam }) => fetchFunction({ pageParam }, ...params),
    {
      retry: false,
      staleTime: options?.staleTime || 5 * 60 * 1000,
      getNextPageParam: options?.getNextPageParam,
    },
  );

  const translate = useTranslate();
  const code = error?.code;
  const errorCode = code ? +code : null;
  const message = error?.message;
  const errorMessage = message
    ? translate('errors.error') + message
    : translate('errors.dialog.default');

  return {
    isSuccess,
    isLoading,
    isError,
    refetch,
    errorCode,
    errorMessage,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
}

export function createInfiniteQueryTask<TData, Params extends Array<any>>(
  key: string[],
  fetchFunction: (pageParams: { pageParam: number }, ...args: Params) => Promise<TData>,
  options?: Options<TData>,
) {
  function useAsyncTaskEmbedded(...params: Params) {
    return useInfiniteQueryTask(key, fetchFunction, params, options);
  }

  function update(updater: Updater<TData | undefined, TData | undefined>) {
    return queryClient.setQueryData(key, updater);
  }

  function invalidate() {
    return queryClient.invalidateQueries(key);
  }

  return {
    hook: useAsyncTaskEmbedded,
    update,
    invalidate,
  };
}
