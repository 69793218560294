import React from 'react';
import MUISkeleton from '@mui/material/Skeleton';
import { styled } from '../_styles';

const StyledSkeleton = styled(MUISkeleton)({ transform: 'unset' });

type StyledSkeletonProps = React.ComponentProps<typeof StyledSkeleton>;

type Props = {
  className?: string;
  variant?: StyledSkeletonProps['variant'];
  width?: StyledSkeletonProps['width'];
  height?: StyledSkeletonProps['height'];
};

const Skeleton: React.FC<Props> = props => <StyledSkeleton {...props} />;

Skeleton.displayName = 'Skeleton';

export default Skeleton;
