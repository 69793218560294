import React from 'react';
import MUIToolbar, { ToolbarProps } from '@mui/material/Toolbar';

type Props = {
  disableGutters?: ToolbarProps['disableGutters'];
  color?: ToolbarProps['color'];
  style?: ToolbarProps['sx'];
  variant?: ToolbarProps['variant'];
};
const Toolbar: React.FC<Props> = ({
  color, variant, style, disableGutters, children,
}) => (
  <MUIToolbar color={color} variant={variant} sx={style} disableGutters={disableGutters}>
    {children}
  </MUIToolbar>
);

Toolbar.displayName = 'Toolbar';
export default Toolbar;
