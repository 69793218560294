import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getInvoicesSync from '../apis/getInvoicesSync';

const {
  hook: useGetInvoicesSync,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('/invoicing/sync', () => getInvoicesSync());

export { useGetInvoicesSync };

export default {
  fulfilled,
  rejected,
  pending,
};
