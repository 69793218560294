import React from 'react';
import {
  Box, Drawer, IconButton, Stack, Typography,
} from '@financial-tool/components';
import { CloseIcon } from '@financial-tool/icons';
import Divider from '../Divider';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
};
const TableRowDrawer: React.FC<Props> = ({
  open, onClose, title, children,
}) => (
  <Drawer anchor="right" open={open} onClose={onClose}>
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between" py={2} px={3}>
        <Typography variant="h5" fontWeight={600}>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={3} width="500px">
        {children}
      </Box>
    </Box>
  </Drawer>
);

TableRowDrawer.displayName = 'TableRowDrawer';
export default TableRowDrawer;
