import React, {
  lazy, Suspense, useEffect, useRef,
} from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { Grid, styled } from '@financial-tool/components';
import { useGlobalTracker } from '@financial-tool/analytics';
import { useAppDispatch } from '@financial-tool/store';
import {
  Feature,
  FeatureFlag,
  FeatureWrapper,
  setUserAttribute,
  useFlag,
  UserAttribute,
} from '@financial-tool/feature-flags';
import { useEnvVars } from '@xtreamsrl/react-config';
import { Path } from '@financial-tool/routing';
import { useBreakpoints } from '@financial-tool/hooks';
import Menu from '../Menu';
import useUserData from '../../../user-session/hooks/useUserData';
import { changeRoute } from '../../../hotjarIntegration';
import MenuAppbar from '../MenuAppbar';
import SmallScreenDialog from '../SmallScreenDialog';
import { mainNavMenuItems } from '../Menu/menuLinks';
import useCurrentUser from '../../../user-session/hooks/useCurrentUser';
import SplashScreen from '../../../user-session/views/SplashScreen';
import WelcomeModal from '../../../dashboard/components/WelcomeModal';

const Clients = lazy(() => import('../../../clients/views/Clients'));
const Dashboard = lazy(() => import('../../../dashboard/views/Dashboard'));
const Settings = lazy(() => import('../../../settings/views/Settings'));
const Transactions = lazy(() => import('../../../transactions/views/Transactions'));
const Forecasts = lazy(() => import('../../../forecasts/views/Forecasts'));
const Invoices = lazy(() => import('../../../invoices/views/Invoices'));
const Company = lazy(() => import('../../../company/views/Company'));
const AccountantDashboard = lazy(() => import('../../../accountant/views/AccountantDashboard'));
const Planning = lazy(() => import('../../../planning/views/Planning'));

const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginTop: '68px',
    flexDirection: 'row',
  },
  flexDirection: 'column',
  width: '100vw',
  marginTop: '58px',
  flexGrow: 1,
}));

const MenuContainer = styled(Grid)(() => ({}));

const FeatureRoute: React.FC<{ feature: Feature }> = ({ feature, children }) => (
  <FeatureWrapper feature={feature} fallback={<Redirect to={Path.Home} />}>
    {children}
  </FeatureWrapper>
);

const Main: React.FC = () => {
  const redirectUnifiedLogin = useFlag(FeatureFlag.RedirectUnifiedLogin);
  const {
    futuro: { dashboardUrl },
  } = useEnvVars();
  const {
    user, loading, accountantMode, isHandlingACompany,
  } = useUserData();
  const { identifyUser, trackPageView } = useGlobalTracker();
  // todo move this
  const dispatch = useAppDispatch();
  const { workspaceId } = useCurrentUser();
  const userIdenfiedRef = useRef(false);
  const { isMobile } = useBreakpoints();

  const location = useLocation();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user && !userIdenfiedRef.current) {
      identifyUser({
        name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        id: user.id,
      });
      setUserAttribute({
        [UserAttribute.email]: user.email,
        [UserAttribute.id]: user.id,
      });
      userIdenfiedRef.current = true;
    }
    if (!user && redirectUnifiedLogin) window.location.replace(dashboardUrl);
  }, [user, identifyUser, redirectUnifiedLogin, dashboardUrl, loading]);

  useEffect(() => {
    trackPageView(location.pathname);
    dispatch(changeRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, trackPageView]);

  return !loading && user ? (
    <>
      <MenuAppbar user={user} items={mainNavMenuItems} />
      <Container container wrap="nowrap" spacing={0}>
        <MenuContainer>
          <Menu accountantMode={!!accountantMode} />
        </MenuContainer>
        <WelcomeModal />
        <Grid item flexGrow={1}>
          <Suspense fallback={null}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {accountantMode === true ? (
              <Switch>
                <Route path={Path.AccountantDashboard}>
                  <AccountantDashboard />
                </Route>
                <Route path={Path.Home}>
                  <Redirect to={Path.AccountantDashboard} />
                </Route>
              </Switch>
            ) : workspaceId ? (
              <Switch>
                <Route path={Path.Invoices}>
                  {isMobile ? <SmallScreenDialog /> : <Invoices />}
                </Route>
                <Route path={Path.Clients}>{isMobile ? <SmallScreenDialog /> : <Clients />}</Route>
                <Route path={Path.Forecasts}>
                  <FeatureRoute feature={Feature.FORECASTS}>
                    {isMobile ? <SmallScreenDialog /> : <Forecasts />}
                  </FeatureRoute>
                </Route>
                <Route path={Path.Planning}>
                  <FeatureRoute feature={Feature.PLANNING}>
                    {isMobile ? <SmallScreenDialog /> : <Planning />}
                  </FeatureRoute>
                </Route>
                <Route path={Path.Settings}>
                  {isMobile ? <SmallScreenDialog /> : <Settings />}
                </Route>
                <Route path={Path.Company}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isMobile ? (
                    <SmallScreenDialog />
                  ) : isHandlingACompany ? (
                    <Redirect to={Path.Home} />
                  ) : (
                    <Company />
                  )}
                </Route>
                <Route path={Path.Transactions}>
                  <FeatureRoute feature={Feature.TRANSACTIONS}>
                    {isMobile ? <SmallScreenDialog /> : <Transactions />}
                  </FeatureRoute>
                </Route>
                <Route path={Path.Home}>
                  <Dashboard />
                </Route>
              </Switch>
            ) : (
              <></>
            )}
          </Suspense>
        </Grid>
      </Container>
    </>
  ) : (
    <SplashScreen loading={loading || (!!redirectUnifiedLogin && !user)} />
  );
};

Main.displayName = 'Main';
export default Main;
