/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Dialog } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useHistory } from 'react-router-dom';
import { Path } from '@financial-tool/routing';

type Props = {
  close?: () => void;
};
const SmallScreenDialog: React.FC<Props> = ({ close }) => {
  const t = useTranslate();
  const history = useHistory();

  return (
    <Dialog
      onClose={close ?? (() => history.push(Path.Home))}
      mode="cta"
      open
      title={t('general.smallScreenDialog.title')}
      body={t('general.smallScreenDialog.content')}
      titleProps={{ variant: 'h5', fontWeight: 'bold', textAlign: 'center' }}
      buttons={
        close
          ? undefined
          : {
              primary: {
                onClick: () => history.push(Path.Home),
                text: t('general.smallScreenDialog.button'),
              },
      }
      }/>
  );
};

SmallScreenDialog.displayName = 'SmallScreenDialog';

export default SmallScreenDialog;
