declare global {
  interface Window {
    HubSpotConversations?: {
      widget: {
        load: () => void;
        open: () => void;
        remove: () => void;
      };
    };
  }
}

export const useInAppChat = () => {
  const openChat = () => {
    window.HubSpotConversations?.widget.load();
    window.HubSpotConversations?.widget.open();
  };

  const closeChat = () => {
    window.HubSpotConversations?.widget.remove();
  };

  return {
    openChat,
    closeChat,
  };
};
