import { Expense, ExpenseWithSeries } from '../../expenses/types/expense';

export const errorMessages = {
  options: 'errors.options',
  form_general: 'errors.form.general',
  form_required: 'errors.form.required',
  integer: 'errors.form.number.integer',
  positive: 'errors.form.number.positive',
  monthCode: 'errors.form.date.monthCode',
  fullDate: 'errors.form.date.fullDate',
  past: 'errors.form.date.past',
  endDate: 'errors.form.date.endDate',
  client: 'errors.client',
  parent: 'errors.form.date.parent',
  email: 'errors.form.email',
  password: 'errors.form.password',
  confirmEmail: 'errors.form.confirmEmail',
  sameEmail: 'errors.form.sameEmail',
  confirmPassword: 'errors.form.confirmPassword',
  vatNumber: 'errors.form.vatNumber',
  vatValidation: {
    400: 'errors.vatValidation.400',
    404: 'errors.vatValidation.404',
    502: 'errors.vatValidation.502',
  },
};

export const compareDates = (a: Expense, b: Expense) => {
  const startDateA = new Date(a.startDate);
  const startDateB = new Date(b.startDate);
  if (startDateA < startDateB) {
    return 1;
  }
  if (startDateA > startDateB) {
    return -1;
  }
  return 0;
};

type Options = {
  digits?: number;
  currency?: string;
};
export const UIAmount = (amount: number, options?: Options): string => {
  const { currency, digits } = {
    currency: 'EUR',
    digits: 2,
    ...options,
  };
  const notAvailable = amount === undefined || amount === null || Number.isNaN(amount);
  return notAvailable
    ? '--/-- €'
    : new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency,
      maximumFractionDigits: digits,
    }).format(amount);
};

export const computeTotalAmount = (expenses: ExpenseWithSeries[]) => {
  let total = 0;
  expenses.forEach(expense => {
    if (!expense.seriesId || (expense.seriesId && expense.current)) {
      total += expense.amount;
    }
  });
  return UIAmount(total);
};

export const acronym = (str: string) => str
  .split(/\s/)
  .slice(0, 2)
  .reduce((response, word) => {
    response += word.slice(0, 1);
    return response;
  }, '');

export const capitalizeFL = (value: string) => value[0] + value.slice(1).toLowerCase();

export const queryStringify = (
  object: any,
  options: {
    skipNull: boolean;
    arraySeparator: string;
    encode: boolean;
  } = {
    skipNull: true,
    arraySeparator: ',',
    encode: true,
  },
) => Object.keys(object)
  .filter(
    k => object[k] !== undefined && ((options.skipNull && object[k] !== null) || !options.skipNull),
  )
  .map(key => {
    // @ts-ignore
    const value = object[key];
    let result;
    if (Array.isArray(value)) {
      result = value.join(options.arraySeparator);
    } else {
      result = value;
    }
    return `${options.encode ? encodeURIComponent(key) : key}=${
      options.encode ? encodeURIComponent(result) : result
    }`;
  })
  .join('&');

export const notNullish = <TValue>(value: TValue | null | undefined): value is TValue => {
  // Refer to https://stackoverflow.com/a/46700791/4621184
  if (value === null || value === undefined) return false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const testDummy: TValue = value;
  return true;
};
