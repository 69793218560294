import React from 'react';
import { Image } from '@financial-tool/components';
import { BoxProps } from '@mui/material/Box';
import img from './cashoo-home.png';

type Props = {
  width?: BoxProps['width'];
  height?: BoxProps['width'];
  p?: BoxProps['p'];
};
const ProductImg: React.FC<Props> = props => <Image src={img} {...props} />;

ProductImg.displayName = 'ProductImg';
export default ProductImg;
