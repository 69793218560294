import React from 'react';
import MUIAvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { styled } from '@financial-tool/components';

const StyledAvatarGroup = styled(MUIAvatarGroup)<{ overlap?: number }>(
  ({ overlap = 8, theme }) => ({
    '.MuiAvatar-root': {
      boxShadow: '2px 3px 20px 5px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%)',
      border: 'none',
      marginLeft: `-${overlap}px`,
      backgroundColor: 'white',
    },
    '[class*="MuiAvatar-root-MuiAvatarGroup-avatar"]': {
      backgroundColor: theme.palette.grey.dark,
      fontSize: theme.typography.fontSize,
    },
  }),
);

type Props = {
  testId?: string;
  max?: AvatarGroupProps['max'];
  overlap?: number;
};
const AvatarGroup: React.FC<Props> = ({ testId, ...props }) => (
  <StyledAvatarGroup data-test={testId} {...props} />
);

AvatarGroup.displayName = 'AvatarGroup';
export default AvatarGroup;
