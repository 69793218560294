import { FeatureFlag, useFlag } from '@financial-tool/feature-flags';
import { useSelector } from 'react-redux';
import { selectAccountantMode } from '../../../user-session/slice/userSlice';

export const useWelcomeModalType = () => {
  const welcomeType = useFlag(FeatureFlag.WelcomeType);
  const accountantMode = useSelector(selectAccountantMode);

  return accountantMode ? 'accountant' : welcomeType;
};
