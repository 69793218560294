import React from 'react';
import MUICardContent, { CardContentProps } from '@mui/material/CardContent';

type Props = {
  style?: CardContentProps['sx']
};

const CardContent: React.FC<Props> = ({ style, ...props }) => (
  <MUICardContent {...props} sx={style} />);

CardContent.displayName = 'CardContent';

export default CardContent;
