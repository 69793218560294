import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import deleteExpenseAPI from '../apis/deleteExpense';

const {
  hook: useDeleteExpense,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('expenses/delete', async (id: string) => {
  await deleteExpenseAPI(id);
  return id;
});

export { useDeleteExpense };

export default {
  fulfilled,
  rejected,
  pending,
};
