import { createAsyncTaskHook } from '@financial-tool/hooks';
import { createAsyncThunk } from '@reduxjs/toolkit';

export function createAsyncTaskHandlerWithActions<Returned, Params = void>(
  typePrefix: string,
  payloadCreator: ((parameters: Params) => Promise<Returned>) | (() => Promise<Returned>),
) {
  const thunk = createAsyncThunk(typePrefix, payloadCreator);

  return {
    hook: createAsyncTaskHook(thunk),
    fulfilled: thunk.fulfilled,
    rejected: thunk.rejected,
    pending: thunk.pending,
    start: thunk,
  };
}
