import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import resendInvitationAPI from '../apis/resendInvitation';

const {
  hook: useResendInvitation,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invitation/resend', async (invitationId: string) => resendInvitationAPI(invitationId));

export { useResendInvitation };

export default {
  fulfilled,
  rejected,
  pending,
};
