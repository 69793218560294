import {
  createAction, createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import getCashFlow from '../asyncHandlers/getCashFlow';
import { BalanceHintWithData, BalanceHintEnum, CashFlow } from '../types/cashFlow';
import { YearTotals } from '../../planning/types';
import { computeCashflowUpdate } from '../../planning/utils';

type CashFlowState = CashFlow & {
  initialAmount: number;
  currentMonth: string | null;
  hasChanged: boolean;
};

export const setCurrentMonth = createAction<string>('cashflow/set');

const defaultMonths = new Array(16).fill(null).map((_, index) => ({
  totalActualIncome: 0,
  totalIncome: 0,
  endDate: new Date()
    .add(index - 3, 'M')
    .endOf('M')
    .format('YYYY-MM-DD'),
  date: new Date()
    .add(index - 3, 'M')
    .startOf('M')
    .format('YYYY-MM-DD'),
  totalRemaining: 0,
  initialMonthAmount: 0,
  totalSpent: 0,
  totalActualSpent: 0,
  totalPlannedExpense: 0,
  totalPlannedIncome: 0,
  totalBilledExpense: 0,
  totalBilledIncome: 0,
  hints: {},
}));

const cashFlowSlice = createSlice({
  name: 'cashflow',
  initialState: {
    initialAmount: 0,
    periodStart: new Date().subtract(3, 'M').startOf('M').toISOString(),
    periodEnd: new Date().add(12, 'M').endOf('M').toISOString(),
    months: defaultMonths,
    currentMonth: defaultMonths[3].date,
    hasChanged: false,
  } as CashFlowState,
  reducers: {
    setHasChanged: (state, action: PayloadAction<boolean>) => {
      state.hasChanged = action.payload;
    },
    updateEstimatedCashflow: (
      state,
      action: PayloadAction<{ totals: YearTotals; flow: 'in' | 'out' }>,
    ) => {
      const { flow, totals } = action.payload;
      const cashflow = state.months;
      const updates = computeCashflowUpdate(cashflow, totals, flow);
      state.months = state.months.map(({ date: month, ...rest }) => ({
        ...rest,
        date: month,
        ...updates[new Date(month).format('YYYY-MM')],
      }));
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCashFlow.fulfilled, (state, action) => ({
        ...action.payload,
        hasChanged: false,
        currentMonth:
          state.currentMonth === null
            ? action.payload.months.filter(m => m.date.includes(new Date().format('YYYY-MM')))[0]
              .date
            : state.currentMonth,
      }))
      .addCase(setCurrentMonth, (state, action) => ({
        ...state,
        currentMonth: action.payload,
      }));
  },
});

export const { setHasChanged, updateEstimatedCashflow } = cashFlowSlice.actions;
const selectCashFlow = (state: RootState): CashFlowState => state.cashflow;
export const selectCashFlowMonths = createSelector(selectCashFlow, cashflow => cashflow.months);
export const selectDefaultCashFlowMonths = createSelector(selectCashFlow, () => defaultMonths);
export const selectCashFlowHints = createSelector(selectCashFlow, cashflow => cashflow.hint);
export const selectBalanceHint = createSelector(selectCashFlow, cashflow => {
  if (cashflow.hint === undefined) return undefined;

  const cashflowHintKeys = Object.keys(BalanceHintEnum);
  return Object.entries(cashflow.hint).reduce(
    (prev, [key, value]) => (cashflowHintKeys.includes(key)
      ? {
        key,
        data: 'requiredInflow' in value ? value : undefined,
      }
      : prev),
    {},
  ) as BalanceHintWithData;
});
export const selectHasCashflowChanged = createSelector(
  selectCashFlow,
  cashflow => cashflow.hasChanged,
);
export const selectInitialAmount = createSelector(
  selectCashFlow,
  cashflow => cashflow.initialAmount,
);
export const selectCurrent = createSelector(
  selectCashFlow,
  selectCashFlowMonths,
  (cashflow, months) => months.find(month => month.date === cashflow.currentMonth),
);
export default cashFlowSlice.reducer;
