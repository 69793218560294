import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Link = styled(RouterLink)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

type Props = {
  to: string;
  children: React.ReactNode;
};
const StyledLink: React.FC<Props> = ({ to, children }) => <Link to={to}>{children}</Link>;

StyledLink.displayName = 'StyledLink';
export default StyledLink;
