import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { SelectField, SelectFilter, Stack } from '@financial-tool/components';
import { InputLabel } from '@mui/material';

type ChipVariant = {
  name: string;
  label: string;
  searchable?: undefined;
  required?: boolean;
  options: { value: string; label: string }[];
  variant: 'chips';
};

type Props =
  | ChipVariant
  | {
    name: string;
    label: string;
    searchable?: boolean;
    required?: boolean;
    options: { key: string; label: string }[] | string[];
    variant?: undefined;
  };
const FormSelect: React.FC<Props> = ({
  name,
  label,
  options,
  searchable,
  required = false,
  variant,
}) => {
  const translate = useTranslate();
  const {
    field: { ref, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
  });
  return variant === 'chips' ? (
    <Stack spacing={1}>
      <InputLabel htmlFor={name} required={required}>
        {label}
      </InputLabel>
      <SelectFilter
        testId={inputProps.name}
        selected={inputProps.value}
        options={options}
        onClick={inputProps.onChange}/>
    </Stack>
  ) : (
    <SelectField
      {...inputProps}
      searchable={searchable}
      required={required}
      disabled={options.length === 0}
      error={invalid}
      inputRef={ref}
      label={label}
      options={options}
      helperText={error ? translate(error?.message!) : ' '}/>
  );
};

FormSelect.displayName = 'FormSelect';
export default FormSelect;
