import { fetch } from '@financial-tool/fetch';

export default function removeUserFromWorkspace({
  workspaceId,
  userId,
}: {
  workspaceId: string;
  userId: string;
}) {
  return fetch.delete(
    `/workspaces/${workspaceId}/members${userId ? `?userId=${userId}` : ''}`,
    {},
    false,
  );
}
