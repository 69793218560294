import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEnvVars } from '@xtreamsrl/react-config';
import { FeatureFlag, useFlag } from '@financial-tool/feature-flags';

import useCurrentUser from './useCurrentUser';
import { useGetUserData } from '../asyncHandlers/getUserData';
import actions from '../../workspaces/asyncHandlers/getUserWorkspace';
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectDefaultWorkSpace,
  selectNeedSignupAuthenticated,
  setNeedSignup,
} from '../slice/userSlice';
import { useAuthentication } from './useAuthentication';
import { useAccountantMode } from './useAccountantMode';

// This hook must be used only once inside the application due to its logic
// TODO: create two different hooks - one for selectors and one for logic
export default function useUserData() {
  const { futuro } = useEnvVars();
  const { user } = useCurrentUser();
  const {
    getUserInvitation,
    accountantMode,
    accountantModeChecked,
    invitationsSuccess,
    isHandlingACompany,
  } = useAccountantMode();

  const [restored, setRestored] = useState(false);

  const {
    isAuthenticated, restore, isLoading, isAuthLoading,
  } = useAuthentication();

  const [configurationCompleted, setConfigurationCompleted] = useState(false);

  const dispatch = useDispatch();
  const redirectUnifiedLogin = useFlag(FeatureFlag.RedirectUnifiedLogin);

  const {
    execute: getUserData,
    loading: getUserLoading,
    error,
    success,
    errorCode: userErrorCode,
  } = useGetUserData();
  const defaultWorkspace = useSelector(selectDefaultWorkSpace);
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);
  const needsSignup = useSelector(selectNeedSignupAuthenticated);

  useEffect(() => {
    if ((!error && isAuthenticated && !getUserLoading) || needsSignup === false) {
      getUserData();
      getUserInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, needsSignup]);

  useEffect(() => {
    if (accountantModeChecked && user) {
      const workspaceId = currentWorkspaceId ?? defaultWorkspace ?? '';
      const workspace = user.workspaces.find(w => w.id === workspaceId);
      if (workspace) {
        dispatch(actions.fulfilled(workspace, '', ''));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountantModeChecked, dispatch, user]);

  useEffect(() => {
    if (error) {
      if (userErrorCode === 403) {
        dispatch(setNeedSignup(true));
      } else {
        if (redirectUnifiedLogin) window.location.replace(futuro.dashboardUrl);
        setConfigurationCompleted(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, redirectUnifiedLogin, userErrorCode]);

  useEffect(() => {
    if (!isAuthLoading && !restored) {
      restore();
      setRestored(true);
    }
  }, [isAuthLoading, restore, isAuthenticated, restored]);

  useEffect(() => {
    restore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invitationsSuccess && (accountantMode || currentWorkspace)) {
      setConfigurationCompleted(true);
    }
  }, [invitationsSuccess, accountantMode, currentWorkspace]);

  return {
    user,
    isHandlingACompany,
    loading: isLoading || (isAuthenticated && !configurationCompleted),
    isAuthenticated,
    success,
    currentWorkspace,
    restore,
    accountantMode,
  };
}
