import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { InvoicesFilters } from '../types/invoice';
import getInvoicesAPI from '../apis/getInvoices';

const {
  hook: useFetchInvoices,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invoices/get', (filters: InvoicesFilters) => getInvoicesAPI(filters));

export { useFetchInvoices };

export default {
  fulfilled,
  rejected,
  pending,
};
