import { fetch } from '@financial-tool/fetch';
import { queryStringify } from '@financial-tool/utils';
import { PlanningResponse } from '../types';

export default function getPlanningAPI() {
  return fetch.get<PlanningResponse[]>(
    `/plannings?${queryStringify({
      start: new Date().subtract(3, 'M').format('YYYY-MM'),
      end: new Date().add(12, 'M').format('YYYY-MM'),
    })}`,
  );
}
