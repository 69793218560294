import React from 'react';
import MUIChip from '@mui/material/Chip';
import { lighten } from '@financial-tool/components';
import { styled } from '../_styles';

const ColoredChip = styled(MUIChip, {
  shouldForwardProp: propName => propName !== 'colorVariant',
})<{
  colorVariant?:
  | 'error'
  | 'primary'
  | 'secondary'
  | 'incomes'
  | 'neutral'
  | 'expenses'
  | 'grey'
  | 'warning';
}>(
  ({ theme, colorVariant }) => colorVariant && {
    fontWeight: 'bold',
    // @ts-ignore
    color: theme.palette[colorVariant].main,
    // @ts-ignore
    backgroundColor: lighten(theme.palette[colorVariant].main, 0.7),
  },
);

const StyledChip = styled(MUIChip)({});

type Props = {
  label: string;
};

// @ts-ignore
const Chip: React.VFC<Props> = props => <StyledChip {...props} />;

Chip.displayName = 'Chip';

export default Chip;

export { ColoredChip };
