import React from 'react';
import { ReactComponent as ErrorIcon } from './error.svg';

type Props = {};

const CredimiErrorIcon: React.VFC<Props> = () => <ErrorIcon />;

CredimiErrorIcon.displayName = 'CredimiErrorIcon';

export default CredimiErrorIcon;
