import { Feature, FeatureFlag } from './types';

export const featuresMap: { [k in Feature]: { flag?: FeatureFlag } } = {
  [Feature.DASHBOARD]: {},
  [Feature.INVOICES]: {},
  [Feature.SETTINGS]: {},
  [Feature.FORECASTS]: {
    flag: FeatureFlag.ShowOldPlanningPage,
  },
  [Feature.CLIENTS]: {},
  [Feature.BANK_CONNECTIONS]: {},
  [Feature.BILLING_SYSTEM]: {},
  [Feature.PLANNING]: {
    flag: FeatureFlag.ShowNewPlanningPage,
  },
  [Feature.COLLEAGUE]: {
    flag: FeatureFlag.InviteAccountant,
  },
  [Feature.COMPANY]: {
    flag: FeatureFlag.CollaboratorsPage,
  },
  [Feature.UNIFIED_EXPERIENCE]: {
    flag: FeatureFlag.RedirectUnifiedLogin,
  },
  [Feature.CREDIMI_LINKS]: {
    flag: FeatureFlag.CredimiLinks,
  },
  [Feature.ACCOUNTANT]: {
    flag: FeatureFlag.InviteAccountant,
  },
  [Feature.TRANSACTIONS]: {
    flag: FeatureFlag.ShowTransactionsPage,
  },
  [Feature.LOGOUT]: {
    flag: FeatureFlag.ShowLogout,
  },
};
