enum StringDateFormat {
  ONLY_YEAR_MONTH_STANDARD = 'date.formats.default',
  FULL_DATE = 'date.formats.full',
  FULL_DATE_DASH = 'date.formats.fullDash',
  FULL_DATE_MONTH_NAME = 'date.formats.fullMonthName',
  FULL_DATE_MONTH_NAME_AND_YEAR = 'date.formats.fullMonthNameAndYear',
  MONTH_NUMBER = 'date.formats.month.number',
  SHORT_MONTH = 'date.formats.month.short',
  FULL_MONTH = 'date.formats.month.full',
  FULL_YEAR = 'date.formats.year',
  MONTH_YEAR_SHORT_SPACE = 'date.formats.monthYear.short.space',
  MONTH_YEAR_SHORT_DASH = 'date.formats.monthYear.short.dash',
  MONTH_YEAR_SHORT_DASH_REVERSE = 'date.formats.monthYear.short.dashReverse',
  MONTH_YEAR_SHORT_SLASH = 'date.formats.monthYear.short.slash',
  MONTH_YEAR_SPACE = 'date.formats.monthYear.full.space',
  MONTH_YEAR_DASH = 'date.formats.monthYear.full.dash',
  YEAR_MONTH = 'date.formats.monthYear.full.reverse',
  DAY_FULL_MONTH = 'date.formats.dayFullMonth',
  FULL_DATE_TIME = 'time.formats.fullDateTime',
}
export default StringDateFormat;
