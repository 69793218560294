import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '@financial-tool/store';
import { useErrorManagement } from '@xtreamsrl/react-feedback-management';
import { Path } from '@financial-tool/routing';
import useCurrentUser from '../../../user-session/hooks/useCurrentUser';
import { styled } from '../_styles';
import Stack from '../Stack';
import CredimiErrorIcon from '../CredimiErrorIcon';
import Typography from '../Typography';
import Button from '../Button';
import { setCurrentWorkspace } from '../../../user-session/slice/userSlice';
import { useGetUserData } from '../../../user-session/asyncHandlers/getUserData';
import { useAccountantMode } from '../../../user-session/hooks/useAccountantMode';

const Screen = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey.superLight,
  zIndex: 100000,
}));

const RevokedAccessScreen: React.VFC = () => {
  const t = useTranslate();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { error } = useErrorManagement();
  const isError = error !== null && error.code === 403;

  const [display, setDisplay] = useState(false);
  const [cachedWs, setCachedWS] = useState<string>();

  const { user } = useCurrentUser();
  const { setErrorMessage } = useErrorManagement();
  const { switchAccountantMode } = useAccountantMode();
  const { execute: getUserData, success } = useGetUserData();

  const localStorageWs = localStorage.getItem('workspace');
  const stillHasCompanies = user && user!.workspaces.length > 1;

  useEffect(() => {
    if (isError && localStorageWs) {
      localStorage.removeItem('workspace');
      setCachedWS(localStorageWs);
      dispatch({ type: 'state/reset' });
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, localStorageWs]);

  useEffect(() => {
    if (success && cachedWs && !user!.workspaces.find(({ id }) => id === cachedWs)) {
      setErrorMessage(null);
      setDisplay(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, isError]);

  const goToDashboard = useCallback(() => {
    dispatch(setCurrentWorkspace(user!.workspaces[0].id));
    history.push(Path.Home);
    setDisplay(false);
    localStorage.removeItem('workspace');
  }, [dispatch, history, user]);

  const goToAccountantDashboard = useCallback(() => {
    switchAccountantMode(true);
    history.push(Path.AccountantDashboard);
    setDisplay(false);
  }, [switchAccountantMode, history]);

  return display ? (
    <Screen spacing={2} testId="revoked-access-screen">
      <CredimiErrorIcon />
      <Typography variant="h5" fontWeight="bold">
        {t('errors.revokedAccess.title')}
      </Typography>
      <Typography>{t('errors.revokedAccess.body')}</Typography>
      <Stack direction="row" spacing={2}>
        <Button testId="to-dashboard-button" variant="contained" onClick={goToDashboard}>
          {t('buttons.goToDashboard')}
        </Button>
        {stillHasCompanies && (
          <Button
            testId="to-accountant-button"
            variant="outlined"
            color="secondary"
            onClick={goToAccountantDashboard}>
            {t('buttons.goToClient')}
          </Button>
        )}
      </Stack>
    </Screen>
  ) : (
    <></>
  );
};

RevokedAccessScreen.displayName = 'RevokedAccessScreen';
export default RevokedAccessScreen;
