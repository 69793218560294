import { Path } from '../routing';

export const pageViewMapping: { [key in Path]: string } = {
  [Path.AccountantDashboard]: 'pageAccountDashboard',
  [Path.Home]: 'pageHome',
  [Path.Invoices]: 'pageInvoices',
  [Path.Clients]: 'pageClients',
  [Path.ForecastsIncomes]: 'pageForecastsIncomes',
  [Path.ForecastsExpenses]: 'pageForecastsExpenses',
  [Path.Forecasts]: 'pageForecasts',
  [Path.Planning]: 'pagePlanning',
  [Path.Settings]: 'pageSettings',
  [Path.Company]: 'pageCompany',
  [Path.Transactions]: 'pageTransactions',
  [Path.Credimi]: 'pageCredimi',
};
