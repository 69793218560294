import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getPlanningAPI from '../apis/getPlanning';

const { hook: useGetPlanning, fulfilled } = createAsyncTaskHandlerWithActions(
  'planning/get',
  getPlanningAPI,
);

export { useGetPlanning };

export default {
  fulfilled,
};
