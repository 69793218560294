import React, { useEffect, useState } from 'react';
import Card from '../Card/Card';
import Stack from '../Stack';

type Props = {
  testId?: string;
  data: React.ReactNode[];
};
const CardCarousel: React.FC<Props> = ({ data, testId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setCurrentIndex(0);
  }, [data.length]);

  return (
    <Stack
      data-test={testId}
      spacing={0}
      alignItems="center"
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setClicked(false)}
      onClick={() => setCurrentIndex(p => (data.length + p - 1) % data.length)}>
      {data.length > 1 && (
        <>
          <Card
            className={!clicked ? 'effect' : ''}
            style={{
              boxShadow: 4,
              borderRadius: '20px 20px 0 0',
              marginBottom: -3,
              width: 'calc(100% - 100px)',
              cursor: 'pointer',
              '@keyframes zoom-in-zoom-out': {
                '0%, 100%': {
                  marginBottom: -3,
                },
                '50%': {
                  marginBottom: -4,
                },
              },
              '&.effect': { animation: 'zoom-in-zoom-out .4s ease-in-out' },
            }}>
            {' '}
          </Card>

          {!clicked && (
            <Card
              style={{
                boxShadow: 4,
                borderRadius: '20px 20px 0 0',
                marginBottom: -3,
                width: 'calc(100% - 50px)',
                cursor: 'pointer',
              }}>
              {' '}
            </Card>
          )}
        </>
      )}

      <Card
        testId={`${testId}-${currentIndex}`}
        p={3}
        style={{ width: '100%', boxShadow: 4, zIndex: 2 }}>
        {data[currentIndex]}
      </Card>
    </Stack>
  );
};

CardCarousel.displayName = 'CardCarousel';
export default CardCarousel;
