import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslate } from '@xtreamsrl/react-i18n';
import TextField from '../../components/TextField';

export type FormInputProps = {
  name: string;
  type?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  helperText?: string | React.ReactNode;
  error?: boolean;
  onBlur?: () => void;
};

const FormInput: React.FC<FormInputProps> = ({
  name,
  type,
  label,
  placeholder,
  disabled = false,
  required = false,
  error: externalError = false,
  autoComplete,
  helperText = ' ',
  onBlur,
}) => {
  const translate = useTranslate();
  const {
    field: { ref, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
    // @ts-ignore
    rules: type === 'number' ? { valueAsNumber: true } : undefined,
  });

  return (
    <TextField
      id={name}
      {...inputProps}
      onBlur={onBlur}
      label={label}
      error={invalid || externalError}
      helperText={invalid ? translate(error?.message!) : helperText}
      inputRef={ref}
      name={name}
      type={type}
      disabled={disabled}
      required={required}
      placeholder={placeholder ?? label}
      autoComplete={autoComplete}/>
  );
};

FormInput.displayName = 'FormInput';
export default FormInput;
