import React from 'react';
import { ConnectBankImage } from '@financial-tool/components';
import { ReactComponent as BankAccounts } from './bank-accounts.svg';
import { ReactComponent as InvoiceConnected } from './invoice-connected.svg';
import { ReactComponent as Planning } from './planning.svg';
import { ReactComponent as AccountantInvite } from './accountant-invite.svg';
import DemoWelcomeCard from './DemoWelcomeCard';

const demoModalList = [
  {
    component: DemoWelcomeCard,
    id: 'demo',
  },
];
const defaultModalList = [
  {
    image: <ConnectBankImage />,
    titleKey: 'welcome.default.step1Title',
    contentKey: 'welcome.default.step1Content',
    id: 'welcome',
  },
  {
    image: <BankAccounts />,
    titleKey: 'welcome.default.step2Title',
    contentKey: 'welcome.default.step2Content',
    id: 'banks',
  },
  {
    image: <InvoiceConnected />,
    titleKey: 'welcome.default.step3Title',
    contentKey: 'welcome.default.step3Content',
    id: 'invoices',
  },
  {
    id: 'planning',
    image: <Planning />,
    titleKey: 'welcome.default.step4Title',
    contentKey: 'welcome.default.step4Content',
  },
];
const accountantModalList = [
  {
    image: <ConnectBankImage />,
    titleKey: 'welcome.accountant.step1Title',
    contentKey: 'welcome.accountant.step1Content',
    id: 'welcome',
  },
  {
    image: <BankAccounts />,
    titleKey: 'welcome.accountant.step2Title',
    contentKey: 'welcome.accountant.step2Content',
    id: 'banks',
  },
  {
    image: <InvoiceConnected />,
    titleKey: 'welcome.accountant.step3Title',
    contentKey: 'welcome.accountant.step3Content',
    id: 'invoices',
  },
  {
    id: 'planning',
    image: <Planning />,
    titleKey: 'welcome.default.step4Title',
    contentKey: 'welcome.default.step4Content',
  },
  {
    id: 'invite',
    image: <AccountantInvite />,
    titleKey: 'welcome.accountant.step5Title',
    contentKey: 'welcome.accountant.step5Content',
  },
];
export const modalList = {
  default: defaultModalList,
  demo: demoModalList,
  accountant: accountantModalList,
};
