import React, { useState } from 'react';
import { StackProps } from '@mui/material/Stack';
import { lighten, styled } from '../_styles';
import Stack from '../Stack';
import Typography from '../Typography';
import IconButton from '../IconButton';
import { CloseIcon, WarningIcon } from '../../icons';

type Props = {
  variant: 'error';
  content: string;
  mb?: StackProps['mb'];
  hideClose?: boolean;
};

const BannerContainer = styled(Stack)(({ theme: t }) => ({
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '2px',
  backgroundColor: lighten(t.palette.error.superLight, 0.1),
  color: t.palette.error.dark,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const Banner: React.FC<Props> = ({ content, hideClose = false, ...props }) => {
  const [hidden, setHidden] = useState(false);

  if (hidden) {
    return null;
  } else {
    return (
      <BannerContainer direction="row" p={hideClose ? 2 : 1} style={{}} {...props}>
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningIcon color="inherit" fontSize="small" />
          <Typography variant="body2" fontWeight={600}>
            {content}
          </Typography>
        </Stack>

        {!hideClose && (
          <IconButton color="inherit" onClick={() => setHidden(true)}>
            <CloseIcon />
          </IconButton>
        )}
      </BannerContainer>
    );
  }
};

Banner.displayName = 'Banner';

export default Banner;
