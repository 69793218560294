import React from 'react';
import Box from '../Box';
import { styled } from '../_styles';
import LoadingAnimation from '../LoadingAnimation';

const CenteredOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
});

const LoadingOverlay: React.VFC = () => (
  <CenteredOverlay>
    <LoadingAnimation />
  </CenteredOverlay>
);

LoadingOverlay.displayName = 'LoadingOverlay';

export default LoadingOverlay;
