import React from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { ColoredChip } from '../Chip';

const ComingSoonChip: React.VFC = () => {
  const t = useTranslate();

  return <ColoredChip label={t('labels.comingSoon')} colorVariant="secondary" size="small" />;
};

ComingSoonChip.displayName = 'ComingSoonChip';
export default ComingSoonChip;
