import React, { useEffect } from 'react';
import {
  Dialog, Logo, Stack, styled, Typography,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useBreakpoints } from '@financial-tool/hooks';
import { useGlobalTracker } from '@financial-tool/analytics';
import SignupForm from '../SignupForm';
import OnboardingScreen from './onboarding-screen.webp';
import { useSignupForm } from '../../hooks/useSignupForm';
import { useAuthentication } from '../../hooks/useAuthentication';

const DialogWithBackdrop = styled(Dialog)(() => ({
  '.MuiBackdrop-root': {
    background: `top / cover url(${OnboardingScreen})`,
    filter: 'blur(2px)',
  },
}));

const SignupDialog: React.VFC<{ onClose: () => void }> = ({ onClose }) => {
  const t = useTranslate();
  const { isMobile } = useBreakpoints();
  const { loading, formProps, saveForm } = useSignupForm();
  const { auth0Id, isAuthenticated } = useAuthentication();
  const { aliasUser } = useGlobalTracker();

  useEffect(() => {
    if (isAuthenticated && auth0Id) aliasUser(auth0Id);
  }, [isAuthenticated, auth0Id, aliasUser]);

  return (
    <DialogWithBackdrop
      open
      testId="onboard-dialog"
      mode="form"
      buttons={{
        primary: {
          onClick: formProps.handleSubmit(saveForm),
          text: t('signup.submit'),
          testId: 'onboard-submit',
          loading,
          disabled: !formProps.formState.isValid,
        },
        tertiary: {
          onClick: onClose,
          text: t('buttons.logoutForm'),
          testId: 'onboard-logout',
        },
      }}
      title={
        <Stack alignItems="center" spacing={2}>
          <Logo />
          <Stack alignItems="center">
            <Typography>{t('signup.title')}</Typography>
          </Stack>
        </Stack>
      }
      fullScreen={isMobile}
      noClose>
      <SignupForm formProps={formProps} onSubmit={saveForm} />
    </DialogWithBackdrop>
  );
};

SignupDialog.displayName = 'SignupDialog';
export default SignupDialog;
