import { useCallback, useState } from 'react';

export const useDialog = (isInitialOpen?: boolean): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(isInitialOpen);

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen || false, openDialog, closeDialog];
};
