import { DemoEvents } from './events';

export const demoEventsMapping = {
  [DemoEvents.DemoSignupModalOpen]: () => ({
    type: 'signupModalOpen',
  }),
  [DemoEvents.DemoSignupClick]: () => ({
    type: 'signupClick',
  }),
};
