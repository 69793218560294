import { Box, styled } from '@financial-tool/components';

const Indicator = styled(Box)<{ imgSrc?: string; dashed?: boolean; color?: string }>(
  ({
    imgSrc, dashed, theme, color,
  }) => ({
    width: '40px',
    height: '40px',
    boxSizing: 'border-box',
    borderRadius: '50%',
    borderStyle: dashed ? 'dashed' : 'none',
    borderColor: color || 'initial',
    backgroundColor: dashed ? 'transparent' : color || 'transparent',
    overflow: 'hidden',
    padding: theme.spacing(1),
    ...(imgSrc
      ? {
        backgroundImage: `url("${imgSrc}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }
      : {}),
  }),
);

Indicator.displayName = 'Indicator';

export default Indicator;
