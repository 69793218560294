import React from 'react';
import { FileIcon } from '@financial-tool/icons';
import Indicator from '../Indicator';

type Props = {
  direction?: 'in' | 'out';
  dashed?: boolean;
};

const InvoiceIndicator: React.VFC<Props> = ({ direction, dashed }) => {
  let color = 'grey.dark';
  if (direction === 'out') {
    color = 'expenses.main';
  } else if (direction === 'in') {
    color = 'incomes.main';
  }

  return (
    <Indicator dashed={dashed}>
      <FileIcon
        sx={{
          color,
          width: '100%',
          height: '100%',
        }}/>
    </Indicator>
  );
};

InvoiceIndicator.displayName = 'InvoiceIndicator';

export default InvoiceIndicator;
