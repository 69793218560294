import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSuccessManagement } from '@xtreamsrl/react-feedback-management';

const SuccessToast: React.VFC = () => {
  const { success: successMessage, setSuccessMessage } = useSuccessManagement();
  const handleClose = () => setSuccessMessage(null);

  return (
    <Snackbar
      data-test="success-toast"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={successMessage !== null}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert sx={{ width: '100%' }} onClose={handleClose} severity="success" variant="filled">
        {successMessage}
      </Alert>
    </Snackbar>
  );
};

SuccessToast.displayName = 'SuccessToast';
export default SuccessToast;
