import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  Link,
  Stack,
  styled,
  Typography,
  DemoRegisterButton,
} from '@financial-tool/components';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { ArrowLeftIcon, ArrowRightIcon, CookieIcon } from '@financial-tool/icons';

import Tab from '@mui/material/Tab';
import { FeatureComponent } from '@financial-tool/feature-flags';
import { Path } from '@financial-tool/routing';
import { useEnvVars } from '@xtreamsrl/react-config';
import SupportButton from './SupportButton';
import { accountantMenuItems, dashboardMenuItems } from './menuLinks';
import { linkStyle } from './linkStyle';
import WorkspaceSwitch from '../../../workspaces/components/WorkspaceSwitch';
import { useAccountantMode } from '../../../user-session/hooks/useAccountantMode';
import useCurrentUser from '../../../user-session/hooks/useCurrentUser';
import MobileMenu from './MobileMenu';
import { CloseMenuButton, MenuTabs, StyledDrawer } from './Menu.styled';

declare global {
  interface Window {
    _iub?: {
      cs: {
        api: {
          openPreferences(): void;
        };
      };
    };
  }
}

const StyledNavLink = styled(NavLink)(({ theme }) => linkStyle(theme));
const StyledLink = styled(Link)(({ theme }) => linkStyle(theme));

type Props = {
  accountantMode: boolean;
};
const Menu: React.FC<Props> = ({ accountantMode }) => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const { company } = useCurrentUser();
  const { demo } = useEnvVars();
  const { pendingInvitations } = useAccountantMode();
  const items = accountantMode ? accountantMenuItems : dashboardMenuItems;
  const [selectedTab, setSelectedTab] = useState<string | false>(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (pathname === Path.Home) return setSelectedTab(Path.Home);
    const tab = items.reduce(
      (prev, { path }) => (path !== Path.Home && pathname.includes(path) ? path : prev),
      false as string | false,
    );
    return setSelectedTab(tab);
  }, [items, pathname]);

  useEffect(() => {
    if (accountantMode) setOpen(true);
  }, [accountantMode]);

  const collapseMenu = () => setOpen(p => !p);

  return (
    <>
      <MobileMenu />
      <StyledDrawer variant="permanent" anchor="left" open={open}>
        {!accountantMode && (
          <CloseMenuButton onClick={collapseMenu}>
            {open ? <ArrowLeftIcon color="secondary" /> : <ArrowRightIcon color="secondary" />}
          </CloseMenuButton>
        )}
        <Stack spacing={2} flex={1} alignItems="flex-start" width="100%">
          <Stack spacing={2} width="100%" px={2}>
            <Typography
              textTransform="uppercase"
              fontWeight="bold"
              variant="body2"
              color="text.light">
              {translate('menu.handle')}
            </Typography>
            <WorkspaceSwitch currentWorkspace={company ?? ''} />
          </Stack>

          <Divider width="100%" height={2} my={2} />

          <Stack
            width="100%"
            height="70%"
            spacing={2}
            alignItems="flex-start"
            justifyContent="flex-start"
            flex={1}>
            <Typography
              textTransform="uppercase"
              pl={2}
              fontWeight="bold"
              variant="body2"
              color="text.light">
              {translate('menu.sections')}
            </Typography>
            <MenuTabs orientation="vertical" value={selectedTab}>
              {items.map(({
                path, icon, translationLabel, feature, testId,
              }) => (
                <FeatureComponent
                  key={path}
                  feature={feature}
                  Component={Tab}
                  style={{ padding: 0, minHeight: 0, opacity: 1 }}
                  value={path}
                  label={
                    <StyledNavLink data-test={`menu-${testId}`} exact to={path}>
                      {icon}
                      <Badge content={accountantMode ? pendingInvitations.length : undefined}>
                        <Typography ml={1} style={{ wordBreak: 'keep-all' }}>
                          {translate(`menu.${translationLabel}`)}
                        </Typography>
                      </Badge>
                    </StyledNavLink>
                  }/>
              ))}
            </MenuTabs>
          </Stack>

          {demo && (
            <DemoRegisterButton
              withIcon
              collapsed={!open}
              onClick={!open ? collapseMenu : undefined}/>
          )}

          <Divider width="80%" height={2} style={{ alignSelf: 'center' }} my={2} />

          <Box>
            <SupportButton />

            {/* eslint-disable-next-line no-underscore-dangle */}
            <StyledLink onClick={() => window._iub?.cs.api.openPreferences()} mt={0}>
              <CookieIcon />
              <Typography ml={1} style={{ wordBreak: 'keep-all' }}>
                {translate('menu.cookie')}
              </Typography>
            </StyledLink>
          </Box>
        </Stack>
      </StyledDrawer>
    </>
  );
};

Menu.displayName = 'Menu';
export default Menu;
