import React from 'react';
import MUITextField, { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { CloseIcon } from '@financial-tool/icons';
import Stack from '../Stack';

export type TFProps = {
  id?: string;
  value?: string | number | null;
  name?: string;
  required?: boolean;
  multiline?: boolean;
  label?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  placeholder?: string;
  type?: TextFieldProps['type'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputRef?: any;
  error?: boolean;
  helperText?: string | React.ReactNode;
  autoComplete?: string;
  onFieldCleared?: () => void;
  onBlur?: () => void;
};
const TextField: React.FC<TFProps> = ({
  error,
  helperText,
  inputRef,
  name,
  disabled,
  value,
  required,
  multiline,
  label,
  variant = 'outlined',
  placeholder,
  type,
  onChange,
  autoComplete,
  startIcon,
  onFieldCleared,
  ...rest
}) => (
  <Stack spacing={1} flex={1}>
    <MUITextField
      label={label}
      error={error}
      inputRef={inputRef}
      name={name}
      helperText={helperText}
      disabled={disabled}
      value={value}
      multiline={multiline}
      required={required}
      variant={variant}
      InputProps={{
        startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end" onClick={onFieldCleared}>
            <CloseIcon
              style={onFieldCleared === undefined || value === '' ? { visibility: 'hidden' } : {}}/>
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      autoComplete={autoComplete}
      {...rest}/>
  </Stack>
);

TextField.displayName = 'TextField';
export default TextField;
