import { useTranslate } from '@xtreamsrl/react-i18n';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from './config';

type Error = {
  code?: string;
  message?: string;
};

type Options<Data, Payload> = {
  onSuccess?: (data: Data, payload: Payload) => void | Promise<void>;
  keysToInvalidate?: string[][];
};

function useMutationTask<Data, Payload = void>(
  mutationKey: string[],
  mutatingFunction: (data: Payload) => Promise<Data>,
  options?: Options<Data, Payload>,
) {
  const {
    isSuccess, isLoading, isError, data, mutate, error, mutateAsync,
  } = useMutation<
  Data,
  Error,
  Payload
  >(mutatingFunction, {
    mutationKey,
    onSuccess: (mutationResult, payload) => {
      options?.onSuccess?.(mutationResult, payload);
      if (options?.keysToInvalidate) {
        options.keysToInvalidate.forEach(k => queryClient.invalidateQueries(k));
      }
    },
  });
  const translate = useTranslate();
  const code = error?.code;
  const errorCode = code ? +code : null;
  const message = error?.message;
  const errorMessage = message
    ? translate('errors.error') + message
    : translate('errors.dialog.default');

  return {
    isSuccess,
    isLoading,
    isError,
    execute: mutate,
    executeAsync: mutateAsync,
    errorCode,
    errorMessage,
    data,
  };
}

export function createMutationTask<Data, Payload = void>(
  mutationKey: string[],
  mutatingFunction: (data: Payload) => Promise<Data>,
  options?: Options<Data, Payload>,
) {
  const onSuccessSubscriptions: (() => void)[] = [];

  const onSuccessCallback = (data: Data, payload: Payload) => {
    options?.onSuccess?.(data, payload);
    onSuccessSubscriptions.forEach(f => f());
  };

  function useAsyncTaskEmbedded() {
    return useMutationTask(mutationKey, mutatingFunction, {
      ...(options || {}),
      onSuccess: onSuccessCallback,
    });
  }

  function onSuccessEvent(listener: () => void) {
    onSuccessSubscriptions.push(listener);
  }

  return {
    hook: useAsyncTaskEmbedded,
    key: mutationKey,
    onSuccessEvent,
  };
}
