export enum FeatureFlag {
  RedirectUnifiedLogin = 'redirect-unified-login',
  CredimiLinks = 'credimi-links',
  ImportInvoicesFromAde = 'import-invoices-ade',
  CollaboratorsPage = 'collaborators-page',
  InviteAccountant = 'invite-accountant',
  ShowTransactionsPage = 'transactions-page',
  AllowSkipInWelcome = 'allow-welcome-skip',
  WelcomeType = 'welcome-modale-type',
  ShowNewPlanningPage = 'new-planning-page',
  ShowOldPlanningPage = 'old-planning-page',
  ShowLogout = 'show-logout',
}

export interface FeatureFlags {
  [FeatureFlag.RedirectUnifiedLogin]: boolean;
  [FeatureFlag.CredimiLinks]: boolean;
  [FeatureFlag.ImportInvoicesFromAde]: boolean;
  [FeatureFlag.CollaboratorsPage]: boolean;
  [FeatureFlag.InviteAccountant]: boolean;
  [FeatureFlag.ShowTransactionsPage]: boolean;
  [FeatureFlag.AllowSkipInWelcome]: boolean;
  [FeatureFlag.ShowNewPlanningPage]: boolean;
  [FeatureFlag.ShowOldPlanningPage]: boolean;
  [FeatureFlag.ShowLogout]: boolean;
  [FeatureFlag.WelcomeType]: 'default' | 'demo';
}

export enum Feature {
  DASHBOARD = 'DASHBOARD',
  INVOICES = 'INVOICES',
  FORECASTS = 'FORECASTS',
  CLIENTS = 'CLIENTS',
  SETTINGS = 'SETTINGS',
  UNIFIED_EXPERIENCE = 'UNIFIED_EXPERIENCE',
  CREDIMI_LINKS = 'CREDIMI_LINKS',
  ACCOUNTANT = 'ACCOUNTANT',
  TRANSACTIONS = 'TRANSACTIONS',
  COLLEAGUE = 'COLLEAGUE',
  BANK_CONNECTIONS = 'BANK_CONNECTIONS',
  BILLING_SYSTEM = 'BILLING_SYSTEM',
  COMPANY = 'COMPANY',
  PLANNING = 'PLANNING',
  LOGOUT = 'LOGOUT',
}

export enum UserAttribute {
  email = 'email',
  id = 'id',
}
