import React, { createRef, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useController } from 'react-hook-form';
import { CheckBoxOutlineSharpIcon, CheckBoxSharpIcon } from '@financial-tool/icons';
import { Stack, Typography } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { styled } from '../../components/_styles';

const FormControlCheckBox = styled(FormControlLabel)(({ theme }) => ({
  '@keyframes zoom-in-zoom-out': {
    '0%': {
      transform: 'scale(1, 1)',
    },
    '50%': {
      transform: 'scale(1.2, 1.2)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },
  alignItems: 'flex-start',
  transition: 'transform .2s',
  '.MuiTypography-caption': {
    whiteSpace: 'pre',
  },
  '&.highlighted': {
    '.MuiSvgIcon-root': {
      color: theme.palette.error.main,
      animation: 'zoom-in-zoom-out 1s',
    },
  },
  '.MuiFormControlLabel-label': {
    padding: '8px',
    p: {
      color: theme.palette.grey.dark,
    },
  },
}));

type Props = {
  name: string;
  label: React.ReactNode;
  helperText?: string;
};
const FormCheckBox: React.FC<Props> = ({ name, label, helperText }) => {
  const t = useTranslate();
  const [isHighlighted, setIsHighlighted] = useState(false);
  const checkboxRef = createRef<HTMLInputElement>();
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
  });

  const help = error ? t(error.message!) : helperText;

  useEffect(() => {
    if (error?.message && inputProps.value === false) {
      setIsHighlighted(true);
    }
  }, [error, inputProps.value]);

  useEffect(() => {
    if (inputProps.value === true) {
      setIsHighlighted(false);
    }
  }, [inputProps.value]);

  return (
    <FormControlCheckBox
      ref={ref}
      className={isHighlighted ? 'highlighted' : undefined}
      inputRef={checkboxRef}
      {...inputProps}
      control={
        <Checkbox
          checked={inputProps.value}
          disableRipple
          icon={<CheckBoxOutlineSharpIcon />}
          checkedIcon={<CheckBoxSharpIcon />}/>
      }
      label={
        <Stack>
          <Typography variant="body2">{label}</Typography>

          <Typography
            testId="checkbox-helper"
            variant="caption"
            color={error ? 'error' : 'text.info'}>
            {help ?? ' '}
          </Typography>
        </Stack>
      }/>
  );
};

FormCheckBox.displayName = 'FormCheckBox';
export default FormCheckBox;
