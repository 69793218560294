import React from 'react';
import { useFlag } from '../context';
import { Feature } from '../types';
import { featuresMap } from '../featuresMap';

type Props = {
  feature: Feature;
  fallback?: React.ReactElement | null;
};
const FeatureWrapper: React.FC<Props> = ({ feature, fallback = null, children }) => {
  const featureFlag = useFlag(featuresMap[feature]?.flag);
  const hasAFlag = !!featuresMap[feature]?.flag;

  const isEnabled = hasAFlag ? !!featureFlag : true;

  return isEnabled ? <>{children}</> : fallback;
};

FeatureWrapper.displayName = 'FeatureWrapper';
export default FeatureWrapper;
