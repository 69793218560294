import React from 'react';
import MUIBadge, { BadgeProps } from '@mui/material/Badge';

type Props = {
  content: BadgeProps['badgeContent'];
};
const Badge: React.FC<Props> = ({ content, children }) => (
  <MUIBadge badgeContent={content} color="secondary">
    {children}
  </MUIBadge>
);

Badge.displayName = 'Badge';
export default Badge;
