import { useState } from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (el: HTMLElement) => {
    setAnchorEl(el);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    isOpen: open,
    openMenu,
    anchorEl,
    closeMenu: handleClose,
  };
};
