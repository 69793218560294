import React from 'react';
import IconButton from '@mui/material/IconButton';
import { SouthEastIcon } from '../../icons';
import { lighten } from '../_styles';

type Props = {
  color?: string;
  bgColor?: string;
  onClick?: () => void;
  size?: 'large' | 'small';
};
const ArrowDownIcon: React.FC<Props> = ({
  onClick,
  color = '#E73939',
  bgColor = lighten(color, 0.8),
  size = 'large',
}) => (
  <IconButton
    disabled={!onClick}
    style={{ backgroundColor: bgColor, padding: 0 }}
    size={size}
    onClick={onClick}>
    <SouthEastIcon
      sx={{
        color,
        padding: size === 'large' ? 0.7 : 0.3,
        width: size === 'large' ? '35px' : '24px',
        height: size === 'large' ? '35px' : '24px',
      }}/>
  </IconButton>
);

ArrowDownIcon.displayName = 'ArrowDownIcon';
export default ArrowDownIcon;
