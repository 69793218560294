import React from 'react';
import { BankIcon } from '@financial-tool/icons';
import Indicator from '../Indicator';

type Props = {
  bankImg?: string;
  direction: 'in' | 'out';
};

const TransactionIndicator: React.VFC<Props> = ({ bankImg, direction }) => {
  const children = bankImg ? null : (
    <BankIcon
      sx={{
        color: direction === 'out' ? 'expenses.main' : 'incomes.main',
        width: '100%',
        height: '100%',
      }}/>
  );
  return <Indicator imgSrc={bankImg} children={children} />;
};

TransactionIndicator.displayName = 'TransactionIndicator';

export default TransactionIndicator;
