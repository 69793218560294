import React, { forwardRef } from 'react';
import MUIStack, { StackProps } from '@mui/material/Stack';
import { styled } from '../_styles';

const AugmentedMuiStack = styled(MUIStack, {
  shouldForwardProp: (propName: string) => propName !== 'clickable',
})<{ clickable?: boolean }>(({ clickable }) => ({
  cursor: clickable ? 'pointer' : 'inherit',
  ...(clickable
    ? {
      '& > *': {
        cursor: 'pointer',
      },
    }
    : {}),
}));

type Props = {
  className?: string;
  testId?: string;
  direction?: StackProps['direction'];
  spacing?: StackProps['spacing'];
  justifyContent?: StackProps['justifyContent'];
  alignItems?: StackProps['alignItems'];
  width?: StackProps['width'];
  height?: StackProps['height'];
  padding?: StackProps['padding'];
  flex?: StackProps['flex'];
  flexGrow?: StackProps['flexGrow'];
  flexWrap?: StackProps['flexWrap'];
  bgColor?: StackProps['bgcolor'];
  color?: StackProps['color'];
  onClick?: StackProps['onClick'];
  ml?: StackProps['ml'];
  pr?: StackProps['pr'];
  pl?: StackProps['pl'];
  px?: StackProps['pl'];
  py?: StackProps['py'];
  pt?: StackProps['pt'];
  pb?: StackProps['pb'];
  p?: StackProps['p'];
  my?: StackProps['my'];
  mt?: StackProps['mt'];
  mb?: StackProps['mb'];
  mr?: StackProps['mr'];
  m?: StackProps['m'];
  borderRadius?: StackProps['borderRadius'];
  divider?: StackProps['divider'];
  display?: StackProps['display'];
  style?: StackProps['sx'];
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>;
  role?: StackProps['role'];
  position?: StackProps['position'];
};

const Stack: React.FC<Props> = forwardRef(({
  bgColor, testId, style, ...props
}, ref) => (
  <AugmentedMuiStack
    ref={ref}
    bgcolor={bgColor}
    clickable={!!props.onClick}
    data-test={testId}
    sx={style}
    {...props}/>
));

Stack.displayName = 'Stack';

export default Stack;
