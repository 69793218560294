import React from 'react';
import {
  Avatar,
  Button,
  Card,
  ColoredChip,
  ComingSoonChip,
  Stack,
  Typography,
  styled,
  HideOnDevice,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Feature, FeatureWrapper } from '@financial-tool/feature-flags';

type Props = {
  buttonTestId?: string;
  titleIcon: React.ReactNode;
  setUpAction?: () => void;
  title: string;
  body: string;
  titleChip?: string;
  comingSoon?: boolean;
  feature: Feature;
  loading?: boolean;
  dominantColor?: 'primary' | 'secondary';
  customActionButton?: {
    icon: React.ReactNode;
    label: string;
  };
};

const StyledStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-end',
  [breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 32,
  },
}));

const TextContainer = styled(Stack)(({ theme: { breakpoints } }) => ({
  margin: '0 32px',
  [breakpoints.down('sm')]: {
    margin: 0,
  },
}));

const SetUpCard: React.FC<Props> = ({
  titleChip,
  title,
  body,
  titleIcon,
  setUpAction,
  comingSoon = false,
  feature,
  loading = false,
  dominantColor = 'primary',
  customActionButton,
  buttonTestId,
}) => {
  const t = useTranslate();

  return (
    <Card p={4}>
      <Stack direction="row" flexWrap="wrap">
        <HideOnDevice device="mobile">
          <Avatar bgColor="grey.main" style={{ width: 35, height: 35 }}>
            {titleIcon}
          </Avatar>
        </HideOnDevice>

        <TextContainer justifyContent="flex-start" spacing={2} flex={1}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">{title}</Typography>
            <HideOnDevice device="mobile">
              {titleChip && <ColoredChip label={titleChip} colorVariant={dominantColor} />}
            </HideOnDevice>
          </Stack>
          <Typography variant="body1" color="text.info">
            {body}
          </Typography>
        </TextContainer>
        <StyledStack>
          {comingSoon ? (
            <ComingSoonChip />
          ) : (
            <FeatureWrapper feature={feature} fallback={<ComingSoonChip />}>
              {setUpAction && (
                <Button
                  loading={loading}
                  testId={buttonTestId}
                  color={dominantColor}
                  startIcon={customActionButton?.icon}
                  size="large"
                  variant="contained"
                  style={{ minWidth: 110 }}
                  onClick={setUpAction}>
                  {customActionButton?.label ?? t('buttons.start')}
                </Button>
              )}
            </FeatureWrapper>
          )}
        </StyledStack>
      </Stack>
    </Card>
  );
};

SetUpCard.displayName = 'SetUpCard';
export default SetUpCard;
