import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';

export const textFieldStyleOverrides: ComponentsOverrides<Theme>['MuiInputBase'] = {
  root: ({ theme: t }) => ({
    backgroundColor: 'white',
    '&.MuiOutlinedInput-root:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: t.palette.grey.main,
      },
      '.MuiInputLabel-root': {
        color: t.palette.grey.main,
      },
    },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: t.palette.secondary.main,
      },
    },
    '&.MuiOutlinedInput-root.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: t.palette.error.main,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: t.palette.grey.light,
    },
  }),
};

export const lableStyleOverrides: ComponentsOverrides<Theme>['MuiInputLabel'] = {
  root: ({ theme: t }) => ({
    color: t.palette.grey.main,
  }),
};
export const adornmentStyleOverrides: ComponentsOverrides<Theme>['MuiInputAdornment'] = {
  root: ({ theme: t }) => ({
    color: t.palette.grey.light,
  }),
};
