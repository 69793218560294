import React from 'react';
import MUILink, { LinkProps } from '@mui/material/Link';

type Props = {
  testId?: string;
  openInNewTab?: boolean;
  onClick?: LinkProps['onClick'];
  color?: LinkProps['color'];
  underline?: LinkProps['underline'];
  fontWeight?: LinkProps['fontWeight'];
  variant?: LinkProps['variant'];
  href?: LinkProps['href'];
  rel?: LinkProps['rel'];
  mt?: LinkProps['mt'];
  mr?: LinkProps['mr'];
  textAlign?: LinkProps['textAlign'];
  flex?: LinkProps['flex'];
  justifySelf?: LinkProps['justifySelf'];
  target?: LinkProps['target'];
  email?: string;
  subject?: string;
  emailBody?: string;
  className?: string;
};

const Link: React.FC<Props> = ({
  href,
  openInNewTab,
  testId,
  email,
  subject,
  emailBody,
  color = 'primary.main',
  underline = 'none',
  fontWeight = 'bold',
  variant = 'body1',
  children,
  ...props
}) => {
  const emailhref = email
    ? `mailto:${email}?subject=${encodeURIComponent(subject ?? '') || ''}&body=${encodeURIComponent(
      emailBody ?? '',
    )}`
    : href;

  return (
    <MUILink
      data-test={testId}
      sx={{ cursor: 'pointer', '& > * ': { cursor: 'pointer' } }}
      href={emailhref}
      underline={underline}
      fontWeight={fontWeight}
      color={color}
      variant={variant}
      target={openInNewTab ? '_blank' : undefined}
      {...props}>
      {children}
    </MUILink>
  );
};

Link.displayName = 'Link';

export default Link;
