import React, { forwardRef } from 'react';
import MUICard from '@mui/material/Card';
import { spacing } from '@mui/system';
import { styled } from '../_styles';

const CardWithSpacing = styled(MUICard)(
  ({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    padding: '16px',
    borderRadius: 8,
  }),
  spacing,
);

const StyledCard = styled(CardWithSpacing, {
  shouldForwardProp: (propName: string) => !['height', 'width'].includes(propName),
})<{ height?: number | string; width?: number | string }>(({ height, width }) => ({
  height,
  width,
}));

type Props = {
  sx?: React.ComponentProps<typeof StyledCard>['sx'];
  style?: React.ComponentProps<typeof StyledCard>['sx'];
  testId?: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  p?: React.ComponentProps<typeof StyledCard>['p'];
  mt?: React.ComponentProps<typeof StyledCard>['mt'];
  height?: number | string;
  width?: number | string;
  level?: 0 | 1 | 2 | 3;
};

const Card = forwardRef<HTMLDivElement, Props>(
  ({
    testId, children, sx, style, level = 0, ...props
  }, ref) => (
    <StyledCard
      ref={ref}
      elevation={level}
      data-test={testId}
      {...props}
      sx={{
        ':hover': { boxShadow: theme => theme.shadows[level + 1] },
        ...(sx || style || {}),
      }}>
      {children}
    </StyledCard>
  ),
);

Card.displayName = 'Card';

export default Card;
