import React from 'react';
import Box from '../Box';
import Stack from '../Stack';
import Typography from '../Typography';
import { styled } from '../_styles';
import { scrollbarStyle } from '../ScrollableContainer/ScrollableContainer.styled';

const PageContainerShell = styled(Box)<{ columnContainer?: boolean }>(
  ({ columnContainer, theme }) => ({
    padding: '40px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      paddingTop: '72px',
      width: '100vw',
    },
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    ...scrollbarStyle,
    ...(columnContainer ? { display: 'flex', flexDirection: 'column' } : {}),
  }),
);

PageContainerShell.displayName = 'PageContainerShell';

type Props = {
  title?: string;
  actions?: React.ReactNode;
  columnContainer?: boolean;
};

const PageContainer: React.FC<Props> = ({
  children, columnContainer, title, actions,
}) => (
  <PageContainerShell columnContainer={columnContainer}>
    <Stack direction="row" alignItems="center" mb={title || actions ? 1 : 0}>
      <Stack flex={1}>
        {title && (
          <Typography variant="h4" fontWeight="bold">
            {title}
          </Typography>
        )}
      </Stack>
      {actions}
    </Stack>
    {children}
  </PageContainerShell>
);

PageContainer.displayName = 'PageContainer';

export default PageContainer;
