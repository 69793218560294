import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';

export const checkboxStyleOverrides: ComponentsOverrides<Theme>['MuiCheckbox'] = {
  root: ({ theme: t }) => ({
    '@keyframes checked': {
      '0%': {
        backgroundColor: t.palette.secondary.main,
        fill: 'white',
        clipPath: 'inset(3px)',
      },
      '100%': {
        fill: t.palette.secondary.main,
        clipPath: 'inset(3px)',
      },
    },
    color: t.palette.grey.light,
    transition: 'all 300ms ease 150ms',
    ':hover': {
      color: t.palette.grey.main,
      backgroundColor: 'transparent',
    },
    svg: {
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: t.palette.secondary.main,
      svg: {
        animation: 'checked 1s',
      },
    },
  }),
};
