import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { NumberFormatInput } from '@financial-tool/components';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
};

const FormInputAmount: React.FC<Props> = ({
  name,
  label,
  placeholder,
  disabled = false,
  required = false,
}) => {
  const translate = useTranslate();
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
  });

  return (
    <NumberFormatInput
      {...inputProps}
      label={label}
      onValueChange={values => onChange({ target: { value: values.floatValue } })}
      error={invalid}
      helperText={invalid ? translate(error?.message!) : ' '}
      inputRef={ref}
      name={name}
      disabled={disabled}
      required={required}
      placeholder={placeholder ?? label}/>
  );
};

FormInputAmount.displayName = 'FormInputAmount';
export default FormInputAmount;
