import React from 'react';
import {
  HideOnDevice, Stack, styled, Tooltip, Typography,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useBreakpoints } from '@financial-tool/hooks';
import { WarningIcon } from '@financial-tool/icons';
import { useAccountantMode } from '../../../user-session/hooks/useAccountantMode';

const Banner = styled(Stack)(({ theme: { palette } }) => ({
  color: palette.warning.main,
  backgroundColor: palette.warning.superLight,
  padding: '8px 16px',
  borderRadius: 8,
}));

const AccountantBanner: React.VFC = () => {
  const t = useTranslate();
  const { isMobile } = useBreakpoints();
  const { isHandlingACompany } = useAccountantMode();

  return isHandlingACompany ? (
    <Tooltip disabled={!isMobile} text={t('accountant.banner.full')}>
      <Banner direction="row" justifyContent="center" alignItems="center">
        <HideOnDevice device="mobile">
          <WarningIcon color="warning" sx={{ mr: 2 }} />
        </HideOnDevice>

        <Typography noWrap>
          {isMobile ? t('accountant.banner.mobile') : t('accountant.banner.full')}
        </Typography>
      </Banner>
    </Tooltip>
  ) : (
    <></>
  );
};

AccountantBanner.displayName = 'AccountantBanner';
export default AccountantBanner;
