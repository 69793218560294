import React from 'react';
import { Stack, styled, Typography } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import WelcomeModalControls from '../WelcomeModalControls';

export type WelcomeCardProps = {
  image: React.ReactNode;
  titleKey: string;
  contentKey: string;
  skip: () => void;
  goNext: () => void;
  skipNotAllowed: boolean;
  isEnd: boolean;
  disabled?: boolean;
  hideControls?: boolean;
};

const ImageContainer = styled(Stack)({
  height: '180px',
  '> svg': {
    height: '100%',
    width: 'auto',
    maxWidth: '100%',
  },
});

const WelcomeModalCard: React.FC<WelcomeCardProps> = ({
  image,
  contentKey,
  titleKey,
  children,
  hideControls,
  ...controls
}) => {
  const t = useTranslate();

  return (
    <Stack height="100%" direction="column" alignItems="center" width="100%">
      <Stack direction="column" alignItems="center" width="80%">
        <ImageContainer>{image}</ImageContainer>

        <Typography variant="h5" fontWeight="bold" textAlign="center" mt={1}>
          {t(titleKey)}
        </Typography>
      </Stack>
      <Typography textAlign="center" mt={1}>
        {t(contentKey)}
      </Typography>
      {children}
      {!hideControls && <WelcomeModalControls {...controls} />}
    </Stack>
  );
};

WelcomeModalCard.displayName = 'WelcomeModalCard';
export default WelcomeModalCard;
