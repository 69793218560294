/* eslint-disable max-len */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGlobalError } from '@xtreamsrl/react-feedback-management';
import { Path } from '@financial-tool/routing';
import { InvitationStatus, UserRole } from '../types/user';
import { useGetInvitationsForUser } from '../../company/asyncHandlers/getInvitationsForUser';
import { InvitationType } from '../../company/types';
import {
  selectAccountantMode,
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectDefaultWorkSpace,
  selectInvitations,
  selectInvitationsByStatus,
  toggleAccountantMode,
} from '../slice/userSlice';
import useCurrentUser from './useCurrentUser';
import { useGetUserWorkspace } from '../../workspaces/asyncHandlers/getUserWorkspace';

export const useAccountantMode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useCurrentUser();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const defaultWorkspace = useSelector(selectDefaultWorkSpace);
  const currentWorkspaceId = useSelector(selectCurrentWorkspaceId);
  const accountantMode = useSelector(selectAccountantMode);
  const invitations = useSelector(selectInvitations.selectAll);
  const pendingInvitations = useSelector(selectInvitationsByStatus(InvitationStatus.PENDING));
  const {
    execute: getUserInvitation,
    loading,
    success: invitationsSuccess,
  } = useGetInvitationsForUser();
  const {
    execute: getWorkspace, error, errorMessage, errorCode,
  } = useGetUserWorkspace();

  useGlobalError({ error, code: errorCode, message: errorMessage });

  const isAnAccountant = !!user?.workspaces.find(({ roles }) => roles.find(role => role === UserRole.accountant));
  const isHandlingACompany = user?.workspaces.some(
    ({ id, roles }) => currentWorkspaceId === id && roles.find(role => role === UserRole.accountant),
  ) && currentWorkspaceId !== defaultWorkspace;

  const hasPendingAccInvitations = !!invitations?.find(
    ({ type, status }) => type === InvitationType.ACCOUNTANT && status === InvitationStatus.PENDING,
  );

  const enableAccountantView = isAnAccountant || hasPendingAccInvitations;

  useEffect(() => {
    if (enableAccountantView && accountantMode === undefined && currentWorkspaceId === undefined) {
      dispatch(toggleAccountantMode(true));
    }
  }, [dispatch, enableAccountantView, accountantMode, currentWorkspaceId]);

  useEffect(() => {
    if (!enableAccountantView && accountantMode) {
      dispatch(toggleAccountantMode(false));
      history.push(Path.Home);
    }
  }, [dispatch, enableAccountantView, accountantMode, history]);

  const goToWorkspace = useCallback(
    async (workspaceId: string) => {
      const workspace = await getWorkspace(workspaceId);

      if (workspace?.id) {
        localStorage.setItem('workspace', workspaceId);
        dispatch(toggleAccountantMode(false));
        dispatch({ type: 'state/reset' });
        history.push(Path.Home);
      }
    },
    [dispatch, getWorkspace, history],
  );

  const switchAccountantMode = useCallback(
    (switchToAccMode: boolean) => {
      dispatch(toggleAccountantMode(switchToAccMode));
      if (switchToAccMode) {
        localStorage.removeItem('workspace');
      }
    },
    [dispatch],
  );

  return {
    user,
    isHandlingACompany,
    getUserInvitation,
    accountantMode,
    loading,
    invitations,
    pendingInvitations,
    company: currentWorkspace?.name ?? '',
    switchEnabled:
      enableAccountantView || !!currentWorkspace?.roles.find(role => role === UserRole.accountant),
    goToWorkspace,
    switchAccountantMode,
    accountantModeChecked: invitationsSuccess && !enableAccountantView,
    invitationsSuccess,
  };
};
