export enum PlanningFrequency {
  None = 'None',
  Spot = 'SPOT',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export enum PlanningFlow {
  Inflow = 'INFLOW',
  Outflow = 'OUTFLOW',
}

export type Planning = {
  in: PlanningData;
  out: PlanningData;
  error: boolean;
};

export type PlanningData = {
  totals: YearTotals;
  categories: PlanningCategory[];
};

export type YearTotals = { [k: string]: number };

export type PlanningCategory = {
  loading?: boolean;
  categoryId: string;
  name: string;
  values: RowEntries;
};

export type RowEntries = {
  [k: string]: {
    amount: number;
    currency: string;
    frequency: PlanningFrequency;
  };
};

export type PlanningRowBody = {
  name: string;
  direction: PlanningFlow;
};

export type PlanningEntryBody = {
  start: string;
  end?: string;
  frequency: PlanningFrequency;
  amount: number;
  categoryId: string;
};

export type PlanningResponse = {
  id: string;
  name: string;
  direction: PlanningFlow;
  entries: PlanningEntryResponse[];
};

export type PlanningEntryResponse = {
  month: string;
  frequency: PlanningFrequency;
  amount: number;
  currency: string;
};

export type PlanningEntryParams = {
  flow: 'in' | 'out';
  categoryId: string;
  month: string;
  value: number;
};

export type AddRowFormValues = {
  amount: number;
  frequency: PlanningFrequency;
  endDate?: string | null;
  date: string;
};

export enum UpdateMode {
  Single = 'Single',
  TillEnd = 'TillEnd',
  TillDate = 'TillDate',
}
export type EditRecurrenceFormValues = {
  update: UpdateMode;
  date: string;
  endDate?: string | null;
};
