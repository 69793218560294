import { SettingsEvents } from './events';

export const settingsEventsMapping = {
  [SettingsEvents.AddBank]: (param: { bank: string }) => ({
    type: 'addBank',
    props: param?.bank
      ? {
        institute: param?.bank,
      }
      : undefined,
  }),
  [SettingsEvents.StartAddBank]: ({ bank }: { bank: string }) => ({
    type: 'startAddBank',
    props: bank
      ? {
        institute: bank,
      }
      : undefined,
  }),
  [SettingsEvents.RemoveBankAccount]: () => ({
    type: 'removeBank',
  }),
  [SettingsEvents.RefreshBankData]: () => ({
    type: 'refreshBankData',
  }),
  [SettingsEvents.DownloadInvoices]: () => ({
    type: 'downloadInvoices',
  }),
  [SettingsEvents.ReconnectBank]: () => ({
    type: 'reconnectBank',
  }),
  [SettingsEvents.OpenChat]: () => ({
    type: 'openChat',
  }),
  [SettingsEvents.AdEModal]: () => ({
    type: 'adeModal',
  }),
  [SettingsEvents.AdEModalClose]: () => ({
    type: 'adeModalClose',
  }),
  [SettingsEvents.AdEConnectionError]: (param: { code: number }) => ({
    type: 'adeError',
    props: {
      code: param.code,
    },
  }),
  [SettingsEvents.BankModal]: () => ({
    type: 'bankModal',
  }),
};
