import React from 'react';
import ReactNumberFormat, { NumberFormatProps } from 'react-number-format';
import { TFProps } from '../TextField/TextField';

type Props = NumberFormatProps<TFProps>;

const NumberFormat = (props: Props) => (
  <ReactNumberFormat
    displayType="input"
    thousandSeparator="."
    suffix={'\xa0€'}
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
    {...props}/>
);

NumberFormat.displayName = 'NumberFormat';
export default NumberFormat;
