import { DashboardEvents } from './events';

export const dashboardEventsMapping = {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  [DashboardEvents.WelcomeView]: ({ variant }: { variant: string }) => ({
    type: 'welcomeView',
    props: {
      variant,
    },
  }),
  [DashboardEvents.WelcomeSlideView]: ({ id, position }: { id: string; position: number }) => ({
    type: 'welcomeSlideView',
    props: {
      id,
      position,
    },
  }),
  [DashboardEvents.WelcomeEnd]: () => ({
    type: 'welcomeEnd',
  }),
  [DashboardEvents.WelcomeSkip]: () => ({
    type: 'welcomeSkip',
  }),
};
