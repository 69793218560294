import React from 'react';
import { styled } from '../_styles';
import Button from '../Button';

const StyledButton = styled(Button)({
  position: 'fixed',
  bottom: '20px',
  right: '20px',
});

type Props = {
  testId?: string;
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => void;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

const FixedButton: React.FC<Props> = ({
  testId,
  variant,
  onClick,
  children,
  loading,
  disabled,
}) => (
  <StyledButton
    testId={testId}
    variant={variant}
    onClick={onClick}
    loading={loading}
    disabled={disabled}>
    {children}
  </StyledButton>
);

FixedButton.displayName = 'FixedButton';
export default FixedButton;
