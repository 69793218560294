import { configureTranslation } from '@xtreamsrl/react-i18n';
import { setFetchConfig } from '@financial-tool/fetch';
import { store } from '@financial-tool/store';
import { config } from '@xtreamsrl/react-config';

import {
  selectCurrentWorkspaceId,
  selectToken,
  selectUser,
  setAuthToken,
} from '../user-session/slice/userSlice';
import itTranslations from './i18n/it/itTranslations';

export function bootstrap() {
  configureTranslation({
    it: itTranslations,
  });
  setFetchConfig({
    getToken: () => selectToken(store.getState()),
    baseUrl: config.apiBaseUrl,
    getWorkspaceId: () => {
      const currentWs = selectCurrentWorkspaceId(store.getState());
      return currentWs || null;
    },
  });

  const user = selectUser(store.getState());
  if (!user && config.demo) {
    store.dispatch(setAuthToken('DEMO_TOKEN'));
  }
}
