import React from 'react';
import MUICardMedia, { CardMediaProps } from '@mui/material/CardMedia';

type Props = {
  component: React.ElementType;
  image?: CardMediaProps['image'];
  src?: CardMediaProps['src'];
  alt?: string;
  style?: CardMediaProps['sx'];
};

const CardMedia: React.FC<Props> = ({ style, ...props }) => <MUICardMedia {...props} sx={style}/>;

CardMedia.displayName = 'CardMedia';

export default CardMedia;
