import React from 'react';
import {
  Box,
  ConfirmationDialog,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useColors,
  useMenu,
  useDialog,
  styled,
  HideOnDevice,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { CompanyIcon, LogoutRoundedIcon, PersonRoundedIcon } from '@financial-tool/icons';
import { Feature, FeatureWrapper } from '@financial-tool/feature-flags';
import { config } from '@xtreamsrl/react-config';
import { useAuthentication } from '../../../user-session/hooks/useAuthentication';
import { User } from '../../../user-session/types/user';
import useCurrentUser from '../../../user-session/hooks/useCurrentUser';

const UserProfile = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  [theme.breakpoints.down('sm')]: {
    display: config.demo ? 'none' : '',
  },
}));

const MenuContent: React.FC<{ user: User | null; onLogout: () => void }> = ({ user, onLogout }) => {
  const colors = useColors();
  const t = useTranslate();
  const { company } = useCurrentUser();

  return (
    <>
      <Stack padding={2} spacing={1} style={{ maxWidth: '300px' }}>
        <Stack direction="row" spacing={1}>
          <PersonRoundedIcon />
          <Typography testId="user-profile" noWrap>
            {`${user?.firstName} ${user?.lastName}` || ''}
          </Typography>
        </Stack>
        {company && (
          <Stack direction="row" spacing={1}>
            <CompanyIcon />
            <Typography noWrap>{company}</Typography>
          </Stack>
        )}
        <Divider />
      </Stack>
      <FeatureWrapper feature={Feature.LOGOUT}>
        <MenuItem
          variant="popup"
          label={t('buttons.logOut')}
          icon={<LogoutRoundedIcon style={{ color: colors.error.main }} />}
          onClick={onLogout}
          style={{ color: colors.error.main, ':hover': { color: colors.error.main } }}/>
      </FeatureWrapper>
    </>
  );
};

type Props = {
  user: User | null;
};

const LoggedUser: React.FC<Props> = ({ user }) => {
  const {
    isOpen, anchorEl, closeMenu, openMenu,
  } = useMenu();
  const [isDialogOpen, openDialog, closeDialog] = useDialog();
  const t = useTranslate();
  const { logout } = useAuthentication();

  return (
    <UserProfile>
      <Stack
        testId="company-button"
        onClick={e => openMenu(e.currentTarget)}
        spacing={1}
        flex={1}
        direction="row">
        <Divider orientation="vertical" />
        <PersonRoundedIcon />
        <HideOnDevice device="mobile">
          <Typography noWrap>{`${user?.firstName} ${user?.lastName}`}</Typography>
        </HideOnDevice>
      </Stack>
      <FeatureWrapper feature={Feature.LOGOUT}>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isOpen}
          onClose={closeMenu}>
          <MenuContent user={user} onLogout={openDialog} />
        </Menu>
      </FeatureWrapper>

      <ConfirmationDialog
        open={isDialogOpen}
        close={closeDialog}
        onConfirm={logout}
        title={t('buttons.logOut')}
        content={t('confirmation.logOut')}/>
    </UserProfile>
  );
};

LoggedUser.displayName = 'LoggedUser';

export default LoggedUser;
