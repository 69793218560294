import {
  BarChartIcon,
  CloudIcon,
  FileIcon,
  ForecastIcon,
  GroupIcon,
  HomeIcon,
  RightLeftIcon,
  SpreadedGroupIcon,
  TrendingUpIcon,
} from '@financial-tool/icons';
import React from 'react';
import { config } from '@xtreamsrl/react-config';
import { Feature } from '@financial-tool/feature-flags';
import { Path } from '@financial-tool/routing';

export type MenuItem = {
  icon: React.ReactNode;
  translationLabel: string;
  testId: string;
  path: Path;
  onClick?: () => void;
  feature: Feature;
};

export const dashboardMenuItems: MenuItem[] = [
  {
    translationLabel: 'cdHome',
    testId: 'home',
    path: Path.Home,
    icon: <BarChartIcon />,
    feature: Feature.DASHBOARD,
  },
  {
    translationLabel: 'transactions',
    testId: 'transactions',
    path: Path.Transactions,
    icon: <RightLeftIcon />,
    feature: Feature.TRANSACTIONS,
  },
  {
    translationLabel: 'invoices',
    testId: 'invoices',
    path: Path.Invoices,
    icon: <FileIcon />,
    feature: Feature.INVOICES,
  },
  {
    translationLabel: 'planning',
    testId: 'planning',
    path: Path.Planning,
    icon: <ForecastIcon />,
    feature: Feature.PLANNING,
  },
  {
    translationLabel: 'forecasts',
    testId: 'forecasts',
    path: Path.Forecasts,
    icon: <ForecastIcon />,
    feature: Feature.FORECASTS,
  },
  {
    translationLabel: 'clients',
    testId: 'clients',
    path: Path.Clients,
    icon: <GroupIcon />,
    feature: Feature.CLIENTS,
  },
  {
    translationLabel: 'settings',
    testId: 'settings',
    path: Path.Settings,
    icon: <CloudIcon />,
    feature: Feature.SETTINGS,
  },
];

export const mainNavMenuItems: MenuItem[] = [
  {
    translationLabel: 'platformHome',
    testId: 'platformHome',
    onClick: () => window.location.replace(config.futuro.dashboardUrl),
    path: Path.Credimi,
    icon: <HomeIcon />,
    feature: Feature.CREDIMI_LINKS,
  },
  {
    translationLabel: 'cassaDigitale',
    testId: 'cassaDigitale',
    onClick: () => {},
    path: Path.Home,
    icon: <TrendingUpIcon />,
    feature: Feature.DASHBOARD,
  },
  {
    translationLabel: 'lending',
    testId: 'lending',
    onClick: () => window.location.replace(config.futuro.lendingUrl),
    path: Path.Credimi,
    icon: <BarChartIcon />,
    feature: Feature.CREDIMI_LINKS,
  },
];

export const accountantMenuItems: MenuItem[] = [
  {
    path: Path.AccountantDashboard,
    icon: <SpreadedGroupIcon />,
    translationLabel: 'accountantClients',
    feature: Feature.ACCOUNTANT,
    testId: 'accountantClients',
  },
];
