import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { NewExpenseVersion } from '../types/expense';
import createNewExpenseVersionAPI from '../apis/createNewExpenseVersion';

type Args = {
  id: string;
  newVersion: NewExpenseVersion;
};

const {
  hook: useCreateNewExpenseVersion,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('expenses/version', async ({ id, newVersion }: Args) => {
  const res = await createNewExpenseVersionAPI(id, newVersion);
  return {
    id,
    res,
  };
});

export { useCreateNewExpenseVersion };

export default {
  fulfilled,
  rejected,
  pending,
};
