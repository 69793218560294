import React, { useCallback } from 'react';
import {
  Avatar, ColumnsDefinition, Stack, Table, Typography,
} from '@financial-tool/components';
import { Feature } from '@financial-tool/feature-flags';
import SetUpCard from '../SetUpCard';

type Props<T extends object> = {
  count: number;
  title: string;
  titlePrefix?: string;
  data: T[];
  titleIcon: React.ReactNode;
  setUpAction?: () => void;
  tableColumns: ColumnsDefinition<T>;
  keyExtractor: (data: T) => string;
  tableInitialSort?: { id: string; desc: boolean }[];
  noItemsTitle?: string;
  noItemsBody?: string;
  titleChip?: string;
  tableHeight?: number | string;
  dataLoading?: boolean;
  listTestId?: string;
  feature: Feature;
};
function TableCardWithTitle<T extends object>({
  count,
  titleChip,
  title,
  titlePrefix,
  noItemsTitle,
  noItemsBody,
  titleIcon,
  setUpAction,
  data,
  tableColumns,
  keyExtractor,
  tableInitialSort,
  tableHeight,
  dataLoading = false,
  listTestId,
  feature,
}: Props<T>) {
  const rowTestIdBuilder = useCallback(
    (item: T) => `${listTestId}-${keyExtractor(item)}`,
    [keyExtractor, listTestId],
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack pr={1} justifyContent="center">
          <Avatar
            bgColor={count > 0 ? 'primary.main' : 'grey.main'}
            style={{ width: 35, height: 35 }}>
            {titleIcon}
          </Avatar>
        </Stack>
        {titlePrefix && <Typography variant="h6">{titlePrefix}</Typography>}
        <Typography
          data-test={`${listTestId}-count`}
          variant="h5"
          fontWeight="bold"
          color={count > 0 ? 'primary' : 'grey.main'}>
          {count}
        </Typography>
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
      </Stack>

      {count === 0 && noItemsTitle && noItemsBody ? (
        <SetUpCard
          feature={feature}
          loading={dataLoading}
          buttonTestId={`${listTestId}-start`}
          setUpAction={setUpAction}
          titleIcon={titleIcon}
          title={noItemsTitle}
          body={noItemsBody}
          titleChip={titleChip}/>
      ) : (
        <Table
          listTestId={listTestId}
          rowTestIdBuilder={rowTestIdBuilder}
          loading={dataLoading}
          columnsDefinition={tableColumns}
          data={data}
          itemsToShowBeforeScroll={tableHeight ? undefined : 3}
          style={tableHeight ? { height: tableHeight } : {}}
          keyExtractor={keyExtractor}
          itemCount={count}
          noItemsPlaceholder={<></>}
          initialSort={tableInitialSort}/>
      )}
    </Stack>
  );
}

TableCardWithTitle.displayName = 'TableCardWithTitle';
export default TableCardWithTitle;
