import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { Expense } from '../types/expense';
import updateExpenseAPI from '../apis/updateExpense';

const {
  hook: useUpdateExpense,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('expenses/update', async (expense: Partial<Expense>) => {
  const exp = expense?.endDate ? expense : { ...expense, endDate: null };
  const updatedExpense = await updateExpenseAPI(exp as Partial<Expense>);
  const { id, ...changes } = updatedExpense;
  return {
    id,
    changes: { ...changes },
  };
});

export { useUpdateExpense };

export default {
  fulfilled,
  rejected,
  pending,
};
