import { fetch } from '@financial-tool/fetch';
import { UserRole, Workspace } from '../../user-session/types/user';

type WorkspaceUser = {
  id: string;
  roles: UserRole[];
};
type WorkspaceResponse = Workspace & { users: WorkspaceUser[] };

export default function getUserWorkspaceAPI(workspaceId: string) {
  return fetch.get<WorkspaceResponse>(`/workspaces/${workspaceId}`);
}
