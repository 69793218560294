import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getUserData from '../apis/getUserData';

const {
  hook: useGetUserData,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('user/me', () => getUserData());

export { useGetUserData };

export default {
  fulfilled,
  rejected,
  pending,
};
