import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import { config } from '@xtreamsrl/react-config';
import { pageViewMapping } from './pageViewMapping';

type UserData = { name: string; last_name: string; email: string; id: string };

export function createDomainTracker<Mapping extends { [key: string]: (...args: any) => any }>(
  eventsMapping: Mapping) {
  const mp = config.projectTrackingId ? mixpanel : undefined;
  const track = <T extends keyof typeof eventsMapping>(
    event: T,
    ...args: Parameters<typeof eventsMapping[T]>[0] extends object
      ? [Parameters<typeof eventsMapping[T]>[0]]
      : [undefined?]
  ) => {
    const mappedEvent = eventsMapping[event](...args);
    const type = config.demo ? `demo_${mappedEvent.type}` : mappedEvent.type;
    mp?.track(type, mappedEvent.props);
  };

  function useTracker() {
    const trackStart = useCallback(
      <T extends keyof typeof eventsMapping>(event: T) => {
        const mappedEvent = eventsMapping[event]();
        const type = config.demo ? `demo_${mappedEvent.type}` : mappedEvent.type;
        mp?.time_event(type);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mp],
    );

    const trackPageView = useCallback(
      (page: string) => {
        // hack to easily parse the path
        const url = new URL(page, 'http://example.com');
        const pageName = pageViewMapping[url.pathname as keyof typeof pageViewMapping];
        const event = `pageView=${pageName}`;

        mp?.track(config.demo ? `demo_${event}` : event);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mp],
    );

    const setEmailOnce = useCallback(
      (email: string) => {
        mp?.people.set_once({
          $name: 'Demo Lead',
          $email: email,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mp],
    );

    const identifyUser = useCallback(
      (user: UserData | null) => {
        if (user) {
          const id = mp?.get_distinct_id();
          if (id) {
            mp?.alias(user.id);
          } else {
            mp?.identify(user.id);
          }
        } else {
          mp?.identify();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mp],
    );

    const aliasUser = useCallback((id: string) => {
      mp?.alias(id);
    }, []);

    const forgetUser = useCallback(() => {
      mp?.reset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mp]);

    return {
      track,
      trackStart,
      identifyUser,
      aliasUser,
      trackPageView,
      forgetUser,
      setEmailOnce,
    };
  }

  return {
    hook: useTracker,
    track,
  };
}
