import React from 'react';
import { FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { styled } from '@financial-tool/components';
import { ReturnUserForm } from '../useForm';

const FormCanvas = styled('form')(() => ({
  '.MuiFormControl-root': {
    width: '100%',
  },
}));

type Props<T> = ReturnUserForm<T>['formProps'] & {
  testId?: string;
  children: React.ReactNode;
  onSubmit: (data: T) => void;
  autoComplete?: string;
};

const Form = <T extends object>({
  children,
  onSubmit,
  testId,
  autoComplete,
  ...props
}: Props<T>) => (
  <FormProvider {...props}>
    <FormCanvas
      data-test={testId}
      onSubmit={props.handleSubmit(onSubmit as any)}
      noValidate
      autoComplete={autoComplete}>
      {children}
    </FormCanvas>
    <DevTool control={props.control} placement="top-right" />
  </FormProvider>
  );

Form.displayName = 'Form';

export default Form;
