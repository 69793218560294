import * as yup from 'yup';
import { errorMessages } from '@financial-tool/utils';

const signUpFormSchema = yup.object().shape({
  firstName: yup.string().required(errorMessages.form_required),
  lastName: yup.string().required(errorMessages.form_required),
  vatNumber: yup
    .string()
    .required(errorMessages.form_required)
    .matches(/^[0-9]{11}$/, errorMessages.vatNumber),
  companyName: yup.string().required(errorMessages.form_required),
  privacy: yup
    .boolean()
    .required(errorMessages.form_required)
    .transform(v => (v === false ? undefined : true)),
  terms: yup
    .boolean()
    .required(errorMessages.form_required)
    .transform(v => (v === false ? undefined : true)),
  companyAddress: yup.string().required(errorMessages.form_required),
});
export default signUpFormSchema;
