import React from 'react';
import MUIDivider from '@mui/material/Divider';
import {
  spacing, sizing, SpacingProps, SizingProps,
} from '@mui/system';
import { styled } from '../_styles';

const StyledMUIDivider = styled(MUIDivider)<SpacingProps & SizingProps>`
  ${spacing}
  ${sizing}
`;

type DividerProps = React.ComponentProps<typeof StyledMUIDivider>;

type Props = {
  className?: string;
  orientation?: DividerProps['orientation'];
  flexItem?: DividerProps['flexItem'];
  height?: DividerProps['height'];
  m?: DividerProps['m'];
  my?: DividerProps['my'];
  mx?: DividerProps['mx'];
  style?: DividerProps['style'];
  width?: DividerProps['width'];
  color?: DividerProps['color'];
};

const Divider: React.FC<Props> = props => <StyledMUIDivider {...props} />;

Divider.displayName = 'Divider';

export default Divider;
