import { fetch } from '@financial-tool/fetch';
import { queryStringify } from '@financial-tool/utils';
import { PlanningCategory, PlanningResponse } from '../types';

export default function updatePlanningRowNameAPI({
  categoryId,
  name,
}: Pick<PlanningCategory, 'categoryId' | 'name'>) {
  return fetch.patch<PlanningResponse>(
    `/plannings/${categoryId}?${queryStringify({
      start: new Date().subtract(3, 'M').format('YYYY-MM'),
      end: new Date().add(12, 'M').format('YYYY-MM'),
    })}`,
    { name },
  );
}
