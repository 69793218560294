import React from 'react';
import { Tooltip } from '@financial-tool/components';
import Stack from '../Stack';
import { ColoredChip } from '../Chip';
import Avatar from '../Avatar';

type Option<T> = {
  icon?: string;
  value: T;
  label: string;
};

type SingleSelection<T extends string = string> = {
  testId?: string;
  maxItemsToShow?: number;
  selected?: string;
  options: Option<T>[];
  onClick: (value: T) => void;
};

export type Props<T extends string = string> = SingleSelection<T>;
const SelectFilter = <T extends string = string>({
  testId,
  options,
  maxItemsToShow = 1,
  onClick,
  selected,
}: Props<T>) => {
  const handleChange = (value: T) => {
    if (selected !== value) onClick(value);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1} testId={testId} flexWrap="wrap">
      {options.map(option => {
        const { icon, value, label } = option;
        const smallVersion = icon && maxItemsToShow && maxItemsToShow + 1 < options.length;

        return (
          <Tooltip key={label} text={label} disabled={!smallVersion}>
            <ColoredChip
              style={{ margin: '4px 0' }}
              key={value}
              data-test={`${testId}-${value}`}
              avatar={icon ? <Avatar src={icon} /> : undefined}
              variant={smallVersion ? 'avatar' : 'filter'}
              className={selected === value ? 'selected' : ''}
              clickable
              onClick={() => handleChange(option.value)}
              label={label}
              color="secondary"/>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

SelectFilter.displayName = 'SelectFilter';
export default SelectFilter;
