import { fetch } from '@financial-tool/fetch';
import { queryStringify } from '@financial-tool/utils';
import { InvoicesData, InvoicesFilters } from '../types/invoice';

export default function getInvoicesAPI({ status, ...otherFilters }: InvoicesFilters) {
  return fetch.get<InvoicesData>(
    `/invoicing/installments?${queryStringify(
      {
        ...otherFilters,
        status: status === 'all' ? undefined : status,
        pageSize: 20,
      },
      {
        encode: false,
        arraySeparator: ',',
        skipNull: true,
      },
    )}`,
  );
}
