import React from 'react';
import { HideOnDevice } from '@financial-tool/components';
import PageContainer from '../PageContainer';
import Box from '../Box';
import Stack from '../Stack';
import Typography from '../Typography';
import Button from '../Button';
import Grid from '../Grid';
import { styled } from '../_styles';

type Props = {
  section: string;
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  image: React.ReactNode;
  primaryAction?: {
    label: string;
    testId?: string;
    onClick: () => void;
    endIcon?: React.ReactNode;
  };
  secondaryAction?: () => void;
};

const ImageContainer = styled(Stack)(({ theme: { breakpoints } }) => ({
  height: '200px',
  '> svg': {
    height: '200px',
    width: 'auto',
  },
  [breakpoints.up('sm')]: {
    height: '100%',
    '> svg': {
      height: '100%',
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme: { breakpoints } }) => ({
  textAlign: 'center',
  [breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));

const StyledStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  justifyContent: 'center',
  [breakpoints.up('sm')]: {
    justifyContent: 'flex-start',
  },
}));

const GridContainer = styled(Grid)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column-reverse',
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const GridItem = styled(Grid)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    maxWidth: 'unset',
  },
}));

const EmptyView: React.FC<Props> = ({
  section,
  image,
  icon,
  title,
  subtitle,
  primaryAction,
  children,
}) => (
  <PageContainer>
    <Box>
      <GridContainer container>
        <GridItem item xs={6}>
          <HideOnDevice device="mobile">
            <Stack direction="row" alignItems="center" color="secondary.main" spacing={1}>
              {icon}
              <Typography fontWeight="bold"> {section}</Typography>
            </Stack>
          </HideOnDevice>

          <Box>
            <StyledTypography variant="h5" fontWeight="bold" mt={2}>
              {title}
            </StyledTypography>

            <StyledTypography color="text.light" mt={1}>
              {subtitle}
            </StyledTypography>
          </Box>
          <StyledStack direction="row" spacing={2} mt={2}>
            {primaryAction && (
              <Button
                color="secondary"
                variant="contained"
                testId={primaryAction.testId}
                onClick={primaryAction.onClick}
                endIcon={primaryAction?.endIcon}>
                {primaryAction.label}
              </Button>
            )}
          </StyledStack>
        </GridItem>
        <GridItem item xs={6}>
          <ImageContainer direction="row" justifyContent="center">
            {image}
          </ImageContainer>
        </GridItem>
      </GridContainer>
      {children}
    </Box>
  </PageContainer>
);

EmptyView.displayName = 'EmptyView';

export default EmptyView;
