import React from 'react';
import IconButton from '@mui/material/IconButton';
import { NorthEastIcon } from '../../icons';
import { lighten } from '../_styles';

type Props = {
  color?: string;
  bgColor?: string;
  onClick?: () => void;
  size?: 'large' | 'small';
};
const ArrowUpIcon: React.FC<Props> = ({
  onClick,
  color = '#599A57',
  bgColor = lighten(color, 0.8),
  size = 'large',
}) => (
  <IconButton
    disabled={!onClick}
    style={{ backgroundColor: bgColor, padding: size === 'large' ? 0 : 1 }}
    size={size}
    onClick={onClick}>
    <NorthEastIcon
      sx={{
        color,
        padding: size === 'large' ? 0.7 : 0.3,
        width: size === 'large' ? '35px' : '24px',
        height: size === 'large' ? '35px' : '24px',
      }}/>
  </IconButton>
);

ArrowUpIcon.displayName = 'ArrowUpIcon';
export default ArrowUpIcon;
