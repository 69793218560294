import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import createPlanningRowAPI from '../apis/createPlanningRow';
import { PlanningFlow, PlanningRowBody } from '../types';
import { PlanningEvents, track } from '../analytics';

const {
  hook: useCreatePlanningRow,
  fulfilled,
  pending,
  rejected,
} = createAsyncTaskHandlerWithActions('planning/create', async (row: PlanningRowBody) => {
  const res = await createPlanningRowAPI(row);
  const { id, direction } = res;
  track(PlanningEvents.AddPlanningRow, {
    id,
    direction: direction === PlanningFlow.Inflow ? 'inflow' : 'outflow',
  });
  return res;
});

export { useCreatePlanningRow };

export default {
  fulfilled,
  pending,
  rejected,
};
