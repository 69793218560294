export enum SettingsEvents {
  AddBank = 'AddBank',
  StartAddBank = 'StartAddBank',
  RemoveBankAccount = 'RemoveBankAccount',
  ReconnectBank = 'ReconnectBank',
  RefreshBankData = 'RefreshBankData',
  DownloadInvoices = 'DownloadInvoices',
  OpenChat = 'OpenChat',
  AdEModal = 'AdEModal',
  AdEModalClose = 'AdEModalClose',
  AdEConnectionError = 'AdEConnectionError',
  BankModal = 'BankModal',
}
