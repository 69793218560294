/* eslint-disable react/jsx-indent */
import React from 'react';
import { styled } from '@financial-tool/components';
import MUIMenu, { MenuProps } from '@mui/material/Menu';

const StyledMenu = styled(MUIMenu)(({ theme, sx }) => ({
  '.MuiPaper-root': {
    marginLeft: 0,
    borderRadius: '10px',
    minWidth: '300px',
    padding: '20px',
    '.MuiMenuItem-root': {
      borderRadius: '10px',
      marginY: '8px',
    },
    ...sx,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '24px',
    },
  },
}));

type Props = {
  className?: string;
  close: () => void;
  anchorEl: HTMLElement | null;
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  style?: MenuProps['sx'];
};
const FloatingMenu: React.FC<Props> = ({
  close,
  className,
  style,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  children,
}) => (
  <StyledMenu
    className={className}
    anchorEl={anchorEl}
    open={true}
    onClose={close}
    sx={style}
    disableScrollLock={true}
    anchorOrigin={
      anchorOrigin ?? {
        vertical: 'bottom',
        horizontal: 'right',
      }
    }
    transformOrigin={
      transformOrigin ?? {
        vertical: 'bottom',
        horizontal: 'left',
      }
    }>
    {children}
  </StyledMenu>
);

FloatingMenu.displayName = 'FloatingMenu';
export default FloatingMenu;
