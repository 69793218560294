import { fetch } from '@financial-tool/fetch';
import { BilledAmountEntity } from '../types/billedAmount';

export default function updateBilledAmountAPI(billedAmount: BilledAmountEntity) {
  const { id, ...rest } = billedAmount;
  return fetch.put<BilledAmountEntity>(`/estimated/${id}`, {
    ...rest,
    clientId: rest.clientId || undefined,
  });
}
