import React from 'react';
import Box from '../Box';
import Typography from '../Typography';
import { styled } from '../_styles';

const Amount = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
}));

type Props = {
  label: string;
  value: string;
  reverse?: boolean;
  alignLabel?: React.ComponentProps<typeof Typography>['textAlign'];
};

const OutputValue: React.FC<Props> = ({
  label, value, reverse, alignLabel,
}) => {
  const labelEl = (
    <Typography color="text.info" textTransform="uppercase" textAlign={alignLabel}>
      {label}
    </Typography>
  );

  return (
    <Box>
      {!reverse && labelEl}
      <Amount variant="h3">{value}</Amount>
      {reverse && labelEl}
    </Box>
  );
};

OutputValue.displayName = 'OutputValue';

export default OutputValue;
