import { User, UserRole, InvitationStatus } from '../../user-session/types/user';

export type Invitation = {
  id: string;
  company: string;
  type: InvitationType;
  firstName: string;
  lastName: string;
  email: string;
  status?: InvitationStatus;
  lastSentDate: Date;
  rejectedDate?: Date;
  acceptedDate?: Date;
  sentBy: Partial<User> & { roles: UserRole[] };
  workspace: string;
};

export enum InvitationType {
  ACCOUNTANT = 'ACCOUNTANT',
  COLLEAGUE = 'COLLEAGUE',
}

export type WorkspaceUser = Pick<User, 'firstName' | 'lastName' | 'email'> & {
  userId?: string;
  roles: UserRole[];
  invitation?: Pick<Invitation, 'id' | 'status' | 'lastSentDate'>;
};
