import { styled } from '../_styles';
import Box from '../Box';

export const scrollbarStyle = {
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    margin: '5px 0 5px 0',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '20px',
    background: '#8CA1A9',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#476976',
  },
};

const ScrollableContainer = styled(Box)(() => ({
  ...scrollbarStyle,
  overflow: 'auto',
}));

export default ScrollableContainer;
