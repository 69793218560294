import { PlanningEvents } from './events';

export const planningEventsMapping = {
  [PlanningEvents.AddPlanningRow]: ({
    direction,
    id,
  }: {
    direction: 'inflow' | 'outflow';
    id: string;
  }) => ({
    type: 'addPlanningRow',
    props: {
      direction,
      id,
    },
  }),
  [PlanningEvents.AddPlanningRowIntent]: ({
    origin,
  }: {
    origin: 'table-plus' | 'top-page-cta';
  }) => ({
    type: 'addPlanningRowIntent',
    props: {
      origin,
    },
  }),
  [PlanningEvents.DeletePlanningRow]: ({ id }: { id: string }) => ({
    type: 'deletePlanningRow',
    props: { id },
  }),
  [PlanningEvents.UpdatePlanningCell]: ({ id, lag }: { id: string; lag: number }) => ({
    type: 'updatePlanningCell',
    props: {
      id,
      lag,
    },
  }),
  [PlanningEvents.PlanningError]: ({ cause }: { cause?: string }) => ({
    type: 'planningError',
    props: { cause },
  }),
  [PlanningEvents.RecurrenceClick]: () => ({
    type: 'recurrenceClick',
  }),
};
