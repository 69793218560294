import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { ExpenseCreationBody } from '../types/expense';
import createExpenseAPI from '../apis/createExpense';

const {
  hook: useCreateExpense,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('expenses/create', (expense: ExpenseCreationBody) => createExpenseAPI(expense));

export { useCreateExpense };

export default {
  fulfilled,
  rejected,
  pending,
};
