/* eslint-disable no-extend-native */
/* eslint-disable func-names */
import dayjs from 'dayjs';
import 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const quarterOfYear = require('dayjs/plugin/quarterOfYear');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const utc = require('dayjs/plugin/utc');

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(relativeTime);

// TODO: avoid duplicated extensions between frontend and backend
export function augmentDate() {
  Date.prototype.isBefore = function (date: Date, inclusive = false, unit: TimeUnit = 's') {
    const thisDate = dayjs(this);
    return thisDate.isBefore(date, unit) || (inclusive ? thisDate.isSame(date, unit) : false);
  };

  Date.prototype.isAfter = function (date: Date, inclusive = false, unit: TimeUnit = 's') {
    const thisDate = dayjs(this);
    return thisDate.isAfter(date, unit) || (inclusive ? thisDate.isSame(date, unit) : false);
  };

  Date.prototype.isBetween = function (start: Date, end: Date, inclusive = false) {
    return this.isAfter(start, inclusive) && this.isBefore(end, inclusive);
  };

  Date.prototype.add = function (n: number, unit: TimeUnit) {
    return dayjs(this).add(n, unit).toDate();
  };

  Date.prototype.subtract = function (n: number, unit: TimeUnit) {
    return dayjs(this).subtract(n, unit).toDate();
  };

  Date.prototype.endOf = function (unit: TimeUnit) {
    return dayjs(this).endOf(unit).toDate();
  };

  Date.prototype.startOf = function (unit: TimeUnit) {
    return dayjs(this).startOf(unit).toDate();
  };

  Date.prototype.format = function (ftm: string) {
    return dayjs(this).format(ftm);
  };

  Date.prototype.toUtc = function () {
    return dayjs(this).utc().toDate();
  };

  Date.prototype.toDateKey = function (format = 'YYYY-MM-DD') {
    return dayjs(this).format(format);
  };

  Date.prototype.isSame = function (date: Date, resetTimeUnit?: TimeUnit) {
    return resetTimeUnit
      ? dayjs(this).startOf(resetTimeUnit).isSame(date.startOf(resetTimeUnit))
      : dayjs(this).isSame(date);
  };

  Date.prototype.getFormattedDistanceFrom = function (date: Date) {
    return dayjs(this).from(date);
  };

  Date.fromString = function (s: string, format = 'YYYY-MM-DD') {
    const date = dayjs(s, format).toDate();
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date: ${s} , format: ${format}`);
    }
    return date;
  };

  Date.isValidFormat = function (s: string, format: string) {
    return dayjs(s, format, true).isValid();
  };
}
