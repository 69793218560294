export enum Path {
  AccountantDashboard = '/accountant',
  Home = '/',
  Invoices = '/invoices',
  Clients = '/clients',
  Forecasts = '/forecasts', // todo
  ForecastsIncomes = '/forecasts/incomes', // todo
  ForecastsExpenses = '/forecasts/expenses', // todo
  Settings = '/settings',
  Company = '/company',
  Transactions = '/transactions',
  Planning = '/planning',
  Credimi = '/credimi',
}
