import React, { forwardRef, useMemo } from 'react';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

type Props<T> = {
  itemSize: FixedSizeListProps<T>['itemSize'];
  itemCount: FixedSizeListProps<T>['itemCount'];
  list: FixedSizeListProps<T>['itemData'];
  RowComponent: FixedSizeListProps<T>['children'];
  infiniteLoader?: {
    isItemLoaded: (index: number) => boolean;
    loadMoreItems: (startIndex: number, stopIndex: number) => void;
    threshold?: number;
  };
  renderBuffer?: FixedSizeListProps<T>['overscanCount'];
  keyExtractor?: FixedSizeListProps<T>['itemKey'];
  style?: React.CSSProperties;
  testId?: string;
};

export type RenderRowProps<K> = ListChildComponentProps<Array<K>>;

const VirtualizedList = <T extends object>({
  style,
  itemSize,
  itemCount,
  list,
  infiniteLoader,
  renderBuffer = 15,
  RowComponent,
  keyExtractor,
  testId,
}: Props<T>) => {
  const ComponentWrapper = useMemo(
    () => forwardRef<HTMLDivElement>((props, innerRef) => (
      <div data-test={testId} ref={innerRef} style={{ width: 'unset' }} {...props} />
    )),
    [testId],
  );

  return infiniteLoader ? (
    <AutoSizer
      style={{
        height: '100%',
        ...style,
      }}>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={infiniteLoader.isItemLoaded}
          itemCount={itemCount + 1}
          loadMoreItems={infiniteLoader.loadMoreItems}
          threshold={infiniteLoader.threshold}>
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className="custom-scrollbar"
              innerElementType={ComponentWrapper}
              height={height}
              width={width}
              itemSize={itemSize}
              itemCount={itemCount}
              onItemsRendered={onItemsRendered}
              overscanCount={renderBuffer}
              itemData={list}
              style={style}
              itemKey={keyExtractor}
              ref={ref}>
              {RowComponent}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  ) : (
    <AutoSizer
      style={{
        height: '100%',
        ...style,
      }}>
      {({ height, width }) => (
        <FixedSizeList
          className="custom-scrollbar"
          innerElementType={ComponentWrapper}
          height={height}
          width={width}
          itemSize={itemSize}
          itemCount={itemCount}
          overscanCount={renderBuffer}
          itemData={list}
          style={style}
          itemKey={keyExtractor}>
          {RowComponent}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

VirtualizedList.displayName = 'VirtualizedList';

const MemoList = React.memo(VirtualizedList) as typeof VirtualizedList;

export default MemoList;
