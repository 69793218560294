import { useEffect } from 'react';
import { useGlobalError } from '@xtreamsrl/react-feedback-management';
import { useForm } from '@financial-tool/forms';
import { useSignUp } from '../asyncHandlers/signup';
import signUpFormSchema from '../components/SignupForm/signUpFormSchema';
import { useTracker, AuthEvents } from '../analytics';

export type SignUpFormValues = {
  lastName: string;
  firstName: string;
  companyName: string;
  companyAddress: string;
  vatNumber: string;
  privacy: boolean;
  terms: boolean;
};

export function useSignupForm() {
  const {
    execute: executeSignUp,
    loading: loadingSignUp,
    error: signUpError,
    success: signupSuccess,
    errorCode: signUpCode,
    errorMessage: signUpMessage,
  } = useSignUp();

  const { track } = useTracker();

  const saveForm = (data: SignUpFormValues) => {
    const {
      lastName, firstName, companyName, vatNumber, companyAddress,
    } = data as SignUpFormValues;
    executeSignUp({
      lastName,
      firstName,
      companyName,
      vatNumber,
      companyAddress,
    });
  };

  const { formProps } = useForm<SignUpFormValues>({
    validationSchema: signUpFormSchema,
    initialValues: {
      lastName: '',
      firstName: '',
      companyName: '',
      companyAddress: '',
      vatNumber: '',
      privacy: false,
      terms: false,
    },
  });

  useGlobalError({ error: signUpError, code: signUpCode, message: signUpMessage });

  useEffect(() => {
    if (signupSuccess) {
      track(AuthEvents.Onboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupSuccess]);

  return {
    loading: loadingSignUp,
    formProps,
    saveForm,
  };
}
