import { AsyncThunk } from '@reduxjs/toolkit';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useAppDispatch } from '../store/store';

function useAsyncTask<Returned, ThunkArg, ThunkApiConfig>(
  asyncTask: AsyncThunk<Returned, ThunkArg, ThunkApiConfig>,
) {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [{
    loading, success, error, errorMessage, response, errorCode,
  }, setStates] = useState<{
    loading: boolean;
    success: boolean;
    error: boolean;
    errorCode: number | null;
    errorMessage: string | null;
    response: Returned | null;
  }>({
    loading: false,
    success: false,
    error: false,
    errorCode: null,
    errorMessage: null,
    response: null,
  });

  const unmountedRef = useRef(false);

  const execute = useCallback(
    // eslint-disable-next-line consistent-return
    async (args: ThunkArg): Promise<Returned | undefined> => {
      setStates({
        loading: true,
        error: false,
        success: false,
        errorCode: null,
        errorMessage: null,
        response: null,
      });

      try {
        // @ts-ignore
        const res = await dispatch(asyncTask(args)).unwrap();
        if (!unmountedRef.current) {
          setStates(prevState => ({
            ...prevState,
            success: true,
            loading: false,
            response: res,
          }));
        }
        return res;
      } catch (res: any) {
        if (!unmountedRef.current) {
          setStates(prevState => ({
            ...prevState,
            error: true,
            loading: false,
            errorCode: +res.code,
            errorMessage: res.message
              ? translate('errors.error') + res.message
              : translate('errors.dialog.default'),
            response: res as Returned,
          }));
        }
      }
    },
    [asyncTask, dispatch, translate],
  );
  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  return {
    execute,
    loading,
    error,
    success,
    errorCode,
    errorMessage,
    response,
  };
}

export function createAsyncTaskHook<Returned, ThunkArg, ThunkApiConfig>(
  asyncTask: AsyncThunk<Returned, ThunkArg, ThunkApiConfig>,
) {
  return function useAsyncTaskEmbedded() {
    const {
      loading, success, error, errorMessage, execute, response, errorCode,
    } = useAsyncTask(asyncTask);

    return {
      execute,
      loading,
      error,
      success,
      response,
      errorCode,
      errorMessage,
    };
  };
}

export default useAsyncTask;
