import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getInvoicesAPI from '../apis/getDashboardInvoices';

const {
  hook: useDashboardInvoices,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invoices/dashboard', () => getInvoicesAPI());

export { useDashboardInvoices };

export default {
  fulfilled,
  rejected,
  pending,
};
