import { useTranslate } from '@xtreamsrl/react-i18n';
import { Updater, useQuery } from '@tanstack/react-query';
import { queryClient } from './config';

type Options = {
  staleTime?: number;
};

type Error = {
  code?: string;
  message?: string;
};

function useQueryTask<TData>(
  key: string[],
  fetchFunction: () => Promise<TData>,
  options?: Options,
) {
  const {
    data, error, refetch, isError, isLoading, isSuccess,
  } = useQuery<TData, Error>(
    key,
    () => fetchFunction(),
    {
      retry: false,
      staleTime: options?.staleTime ?? 5 * 60 * 1000,
    },
  );

  const translate = useTranslate();
  const code = error?.code;
  const errorCode = code ? +code : null;
  const message = error?.message;
  const errorMessage = message
    ? translate('errors.error') + message
    : translate('errors.dialog.default');

  return {
    isSuccess,
    isLoading,
    isError,
    refetch,
    errorCode,
    errorMessage,
    data,
  };
}

export function createQueryTask<Returned>(
  key: string[],
  fetchFunction: () => Promise<Returned>,
  options?: Options & { invalidateOn?: ((listener: () => void) => void)[] },
) {
  const { invalidateOn, ...restOptions } = options || {};

  function useAsyncTaskEmbedded() {
    const {
      isSuccess, isLoading, isError, refetch, errorCode, errorMessage, data,
    } = useQueryTask(
      key,
      fetchFunction,
      restOptions,
    );

    return {
      isSuccess,
      isLoading,
      isError,
      refetch,
      errorCode,
      errorMessage,
      data,
    };
  }

  function update(updater: Updater<Returned | undefined, Returned | undefined>) {
    return queryClient.setQueryData(key, updater);
  }

  function invalidate() {
    return queryClient.invalidateQueries(key);
  }

  function prefetch() {
    return queryClient.prefetchQuery(key, () => fetchFunction());
  }

  if (invalidateOn) {
    invalidateOn.forEach(subscribe => {
      subscribe(() => {
        invalidate();
      });
    });
  }

  return {
    hook: useAsyncTaskEmbedded,
    update,
    invalidate,
    prefetch,
  };
}
