import React from 'react';
import { useFlag } from '../context';
import { Feature } from '../types';
import { featuresMap } from '../featuresMap';

type Props<T extends React.JSXElementConstructor<React.ComponentPropsWithRef<T>>> =
  React.ComponentPropsWithRef<T> & {
    feature: Feature;
    Component: T;
  };
function FeatureComponent<T extends React.JSXElementConstructor<React.ComponentPropsWithRef<T>>>({
  feature,
  Component,
  ...props
}: Props<T>) {
  const featureFlag = useFlag(featuresMap[feature]?.flag);
  const hasAFlag = !!featuresMap[feature]?.flag;

  const isEnabled = hasAFlag ? !!featureFlag : true;

  // @ts-ignore
  return isEnabled ? <Component {...props} /> : <div />;
}

FeatureComponent.displayName = 'FeatureComponent';
export default FeatureComponent;
