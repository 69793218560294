import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { PaletteColor, Theme } from '@mui/material';

const buildContainedSecondaryStyle = (color: PaletteColor, textColor: string) => ({
  color: textColor,
  backgroundColor: color.main,
  ':hover': {
    backgroundColor: color.dark,
    boxShadow: `0 0 0 2rem ${color.dark} inset`,
  },
  ':disabled': {
    backgroundColor: color.main,
  },
});

const buildSecondaryOutlinedStyle = (color: PaletteColor, textColor: string) => ({
  color: textColor,
  textDecorationColor: 'none',
  backgroundColor: 'none',
  border: `2px solid ${color.main}`,
  ':hover': {
    textDecorationColor: 'none',
    backgroundColor: color.dark,
    border: `2px solid ${color.main}`,
    color: color.contrastText,
  },
  ':disabled': {
    textDecorationColor: 'none',
    backgroundColor: color.main,
    border: `2px solid ${color.main}`,
  },
});

export const styleOverrides: ComponentsOverrides<Theme>['MuiButton'] = {
  containedPrimary: ({
    theme: {
      palette: { primary },
    },
  }) => buildContainedSecondaryStyle(primary, 'white'),
  outlinedPrimary: ({
    theme: {
      palette: { primary },
    },
  }) => buildSecondaryOutlinedStyle(primary, primary.main),
  outlinedSecondary: ({
    theme: {
      palette: { secondary },
    },
  }) => buildSecondaryOutlinedStyle(secondary, secondary.main),
  containedSecondary: ({
    theme: {
      palette: { secondary },
    },
  }) => buildContainedSecondaryStyle(secondary, secondary.contrastText),
  text: ({
    theme: {
      palette: { grey },
    },
  }) => ({
    backgroundColor: 'transparent',
    ':hover': {
      // @ts-ignore
      backgroundColor: grey.superLight,
    },
  }),
  root: ({ theme }) => ({
    borderRadius: '100vh',
    padding: '0.5rem 1rem',
    boxShadow: 'none',
    fontWeight: 'bold',
    ':disabled': {
      opacity: 0.75,
      color: theme.palette.text.primary,
      backgroundColor: 'inherit',
    },
    ':hover, :hover > *': {
      cursor: 'pointer',
      boxShadow: 'none',
    },
  }),
};
