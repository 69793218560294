import React from 'react';
import {
  ChatIcon, EmailIcon, FaqIcon, PhoneCallIcon, SupportIcon,
} from '@financial-tool/icons';
import {
  Link, Stack, styled, Typography, useMenu, FloatingMenu,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useBreakpoints } from '@financial-tool/hooks';
import { linkStyle } from '../linkStyle';
import useCurrentUser from '../../../../user-session/hooks/useCurrentUser';
import { useInAppChat } from '../../../../_shared/app-chat';
import { useTracker, SettingsEvents } from '../../../../settings/analytics';

const StyledLink = styled(Link)(({ theme }) => ({
  ...linkStyle(theme),
  justifyContent: 'flex-start',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-end',
  },
}));

const SupportLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  wordBreak: 'keep-all',
  fontWeight: 'normal',
  ':hover': {
    fontWeight: 'bold',
  },
}));

const SupportButton: React.FC = () => {
  const t = useTranslate();
  const { openChat, closeChat } = useInAppChat();
  const {
    anchorEl, openMenu, closeMenu, isOpen,
  } = useMenu();
  const { user, company } = useCurrentUser();
  const { isMobile } = useBreakpoints();
  const { track } = useTracker();

  const openChatAndTrack = () => {
    openChat();
    track(SettingsEvents.OpenChat);
  };

  return (
    <>
      <StyledLink onClick={e => openMenu(e.currentTarget)} justifySelf="center">
        <SupportIcon />
        <Typography ml={1} style={{ wordBreak: 'keep-all' }}>
          {t('menu.support.title')}
        </Typography>
      </StyledLink>

      {isOpen && (
        <FloatingMenu
          close={() => {
            closeMenu();
            closeChat();
          }}
          anchorEl={anchorEl}
          anchorOrigin={
            isMobile
              ? {
                vertical: 'bottom',
                horizontal: 'right',
              }
              : undefined
          }
          transformOrigin={
            isMobile
              ? {
                vertical: 'top',
                horizontal: 'right',
              }
              : undefined
          }>
          <Stack spacing={1}>
            <Stack direction="row" spacing={2} alignItems="center">
              <FaqIcon />
              <SupportLink href={'https://docs.cashoo.it/F-A-Q-7714dac92f6b4399b2f9b93b79b9ccbc'}>
                {t('menu.support.faq')}
              </SupportLink>
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <ChatIcon />
              <SupportLink onClick={openChatAndTrack}>{t('menu.support.chat')}</SupportLink>
            </Stack>

            <Stack direction="row" spacing={2}>
              <EmailIcon />
              <SupportLink
                email={t('menu.support.email')}
                subject={t('menu.emailSubject', {
                  user: `${user?.firstName} ${user?.lastName}`,
                  company,
                })}>
                {t('menu.support.email')}
              </SupportLink>
            </Stack>

            <Stack direction="row" spacing={2}>
              <PhoneCallIcon />
              <SupportLink href={`tel:${t('menu.support.phonecall')}`}>
                {t('menu.support.phonecall')}
              </SupportLink>
            </Stack>
          </Stack>
        </FloatingMenu>
      )}
    </>
  );
};

SupportButton.displayName = 'SupportButton';
export default SupportButton;
