// TODO: investigate Vite for this
import './_shared/config/envVarsConfiguration';
import './_extensions/extensions';
import React from 'react';
import ReactDOM from 'react-dom';
import { config } from '@xtreamsrl/react-config';
import mixpanel from 'mixpanel-browser';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';

if (config.projectTrackingId) {
  mixpanel.init(config.projectTrackingId, { debug: true });
}

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

if (config.demo) {
  import('./_mocks/browser').then(module => module.default.start()).then(renderApp);
} else {
  renderApp();
  serviceWorker.register();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
