import React from 'react';
import { styled } from '@financial-tool/components';
import MUIMenu, { MenuProps } from '@mui/material/Menu';

const StyledMenu = styled(MUIMenu)(({ sx, theme: { palette } }) => ({
  sx,
  '.MuiPaper-root': {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    border: `1px solid ${palette.grey.light}`,
  },
}));

type Props = {
  className?: string;
  anchorEl?: MenuProps['anchorEl'];
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  open: MenuProps['open'];
  onClose?: MenuProps['onClose'];
  sx?: MenuProps['sx'];
  keepMounted?: MenuProps['keepMounted'];
  id?: MenuProps['id'];
};

const Menu: React.FC<Props> = props => (
  <StyledMenu elevation={0} disableScrollLock={false} {...props} />
);

Menu.displayName = 'Menu';

export default Menu;
