/* eslint-disable max-len */
import {
  Planning,
  PlanningCategory,
  PlanningData,
  PlanningEntryResponse,
  PlanningFlow,
  PlanningFrequency,
  PlanningResponse,
  RowEntries,
  YearTotals,
} from './types';
import { CashflowMonthItem, EstimatedCashflowUpdate } from '../dashboard/types/cashFlow';

const months = new Array(16).fill(null).map((_, i) => new Date().add(i - 3, 'M').format('YYYY-MM'));
export const getMonthIndex = (month: string) => months.findIndex(m => m === month) - 3;
const emptyRowEntries: RowEntries = months.reduce(
  (pre, m) => ({ ...pre, [m]: { amount: 0, frequency: PlanningFrequency.None, currency: 'EUR' } }),
  {},
);

const mergeWithEmptyEntries = (responseEntries: PlanningEntryResponse[]): RowEntries => responseEntries.reduce(
  (values, { month, ...params }) => ({
    ...values,
    [month]: params,
  }),
  emptyRowEntries,
);

const computeMonthsTotals = (rows: PlanningCategory[]): YearTotals => {
  const totals: YearTotals = months.reduce((pre, m) => ({ ...pre, [m]: 0 }), {});
  rows.forEach(({ values }) => {
    Object.entries(values).forEach(([month, { amount }]) => {
      totals[month] += amount;
    });
  });

  return totals;
};

export const computeCashflowUpdate = (
  cashflowMonths: CashflowMonthItem[],
  totals: YearTotals,
  flow: 'in' | 'out',
) => cashflowMonths.reduce((prev, data, i) => {
  const {
    totalRemaining,
    initialMonthAmount,
    date,
    totalPlannedExpense,
    totalPlannedIncome,
    totalActualSpent,
    totalBilledExpense,
    totalIncome,
    totalSpent,
    totalActualIncome,
    totalBilledIncome,
  } = data;
  const month = new Date(date).format('YYYY-MM');
  const today = new Date().format('YYYY-MM');

  if (new Date(month).isBefore(new Date(today))) return prev;

  const updates = {
    initialMonthAmount,
    totalRemaining,
    totalPlannedIncome,
    totalPlannedExpense,
    totalIncome,
    totalSpent,
  };

  const amount = totals[month];
  let income = totalIncome;
  let outcome = totalSpent;

  // update total planned and compute new total income/outcome
  if (flow === 'in') {
    updates.totalPlannedIncome = amount;
    income = Math.max(totalActualIncome + totalBilledIncome, amount);
    updates.totalIncome = income;
  } else {
    updates.totalPlannedExpense = amount;
    outcome = Math.max(totalActualSpent + totalBilledExpense, amount);
    updates.totalSpent = outcome;
  }

  // update total remaining and propagate diff in future months
  updates.totalRemaining = initialMonthAmount + income - outcome;
  const diff = updates.totalRemaining - totalRemaining;

  // eslint-disable-next-line no-plusplus
  for (let j = i + 1; j < cashflowMonths.length; j++) {
    cashflowMonths[j].initialMonthAmount += diff;
    cashflowMonths[j].totalRemaining += diff;
  }

  return {
    ...prev,
    [month]: updates,
  };
}, {} as { [k: string]: EstimatedCashflowUpdate });

export const mapResponseToState = (
  planningRows: PlanningResponse[],
  flow: PlanningFlow,
): PlanningData => {
  const rows = planningRows.reduce((prev, {
    name, direction, id, entries,
  }) => {
    if (direction === flow) {
      const category: PlanningCategory = {
        name,
        categoryId: id,
        values: mergeWithEmptyEntries(entries),
      };

      return [...prev, category];
    }
    return prev;
  }, [] as PlanningCategory[]);

  const totals = computeMonthsTotals(rows);

  return {
    totals,
    categories: rows,
  };
};

const initialPlanningData: PlanningData = {
  totals: months.reduce((pre, m) => ({ ...pre, [m]: 0 }), {}),
  categories: [],
};

export const planningInitialState: Planning = {
  in: initialPlanningData,
  out: initialPlanningData,
  error: false,
};

const FrequencyConfig: { [k in PlanningFrequency]: number } = {
  [PlanningFrequency.None]: Infinity,
  [PlanningFrequency.Spot]: Infinity,
  [PlanningFrequency.Monthly]: 1,
  [PlanningFrequency.Quarterly]: 3,
};

export const computeMonthsToUpdate = ({
  endDate,
  date,
  frequency,
}: {
  endDate?: string | null;
  date: string;
  frequency: PlanningFrequency;
}) => {
  const monthsToUpdate: string[] = [];
  if (frequency === PlanningFrequency.Spot || frequency === PlanningFrequency.None) return [date];

  const end = endDate
    ? new Date(endDate).format('YYYY-MM')
    : new Date().add(12, 'M').format('YYYY-MM');
  let month = new Date(date).startOf('M');

  while (month.isBefore(new Date(end), true)) {
    monthsToUpdate.push(month.format('YYYY-MM'));
    month = month.add(FrequencyConfig[frequency], 'M').startOf('M');
  }

  return monthsToUpdate;
};
