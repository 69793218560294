import { InvitationType } from '../../../company/types';
import { CashFLowHintEnum } from '../../../dashboard/types/cashFlow';
import { PlanningFrequency, UpdateMode } from '../../../planning/types';
import { TransactionType } from '../../../transactions/types/transactionFiltersParams';

const productName = 'Cashoo';

const itTranslations = {
  general: {
    demo: {
      label: 'Demo',
      keepGoing: 'Continua la demo',
      product: `${productName}`,
      title: 'Curioso?',
      body: `Registrati per scoprire il meglio di ${productName}.`,
      banner: `Stai navigando una demo pubblica di ${productName}, con dati di esempio. Alcune funzionalità potrebbero essere limitate.`,
    },
    smallScreenDialog: {
      title: 'Il tuo schermo è troppo piccolo :(',
      content:
        `Al momento ${productName} non supporta smartphone, tablet, o schermi troppo piccoli. `
        + `Per un'esperienza ottimale, usa ${productName} sul tuo PC.`,
      button: 'Torna alla Dashboard',
    },
    trapdoor: {
      title: 'Non abbiamo ancora questa funzionalità',
      content: 'Grazie per averci fatto sapere di averne bisogno!',
    },
  },
  menu: {
    menu: 'Menu',
    cdHome: 'Situazione',
    platformHome: 'Dashboard',
    clients: 'Clienti',
    forecasts: 'Pianificazione',
    planning: 'Pianificazione',
    settings: 'Collegamenti',
    transactions: 'Transazioni',
    invoices: 'Fatture',
    accountantClients: 'Elenco',
    crisis: 'Crisi d\'Impresa',
    sections: 'Sezioni',
    handle: 'Stai gestendo',
    switch: 'Cambia vista',
    cassaDigitale: 'Liquidità',
    lending: 'Finanziamenti',
    support: {
      title: 'Supporto',
      phonecall: '34255534754',
      email: 'help@cashoo.it',
      faq: 'F.A.Q.',
      chat: 'Chat',
    },
    cookie: 'Cookie policy',
    emailSubject: 'Richiesta di Supporto da {{user}} dell\'azienda {{company}}',
  },
  signup: {
    title: 'Raccontaci qualcosa di te e della tua azienda',
    firstName: 'Nome',
    lastName: 'Cognome',
    companyName: 'Nome azienda',
    companyAddress: 'Sede azienda',
    vatNumber: 'Partita IVA',
    submit: 'Vai alla dashboard',
    manualInput: 'Inserisci manualmente i dati dell\'azienda',
    vatValidation: {
      loading: 'Stiamo validando il numero della tua Partita IVA...',
      auto: '{{field}} (verrà recuperato automaticamente)',
    },
    privacy:
      'Ho preso visione dell\'<Privacy>Informativa</Privacy> e '
      + `acconsento al trattamento dei miei dati per finalità di marketing, profilazione e comunicazione dei dati a partner di ${productName}.`,
    termsAndConditions: `Accetto <Terms>termini e condizioni</Terms> per l'utilizzo della piattaforma ${productName}.`,
  },
  splash: {
    title: 'Ciao!',
    subtitle: `Benvenuto in ${productName}`,
    login: 'Accedi',
    signup: 'Registrati',
    signupCta: 'Stai entrando per la prima volta? Clicca su "Registrati"',
    emailVerification: {
      title: 'Verifica la tua email',
      content:
        'Benvenuto!\nTi abbiamo inviato una mail: clicca sul link per verificare il tuo indirizzo.\n\n',
      support: {
        message: 'Non hai ricevuto la mail? Scrivici a',
        email: 'help@cashoo.it',
      },
    },
  },
  labels: {
    from: 'da',
    till: 'a',
    vat: 'P.IVA',
    search: 'Cerca',
    filterBy: 'Filtra per:',
    show: 'Mostra',
    andIncludes: 'e includi',
    comingSoon: 'In arrivo',
    new: 'Nuovo',
    startDate: 'Data inizio',
    endDate: 'Data fine',
    minAmount: 'Importo minimo',
    maxAmount: 'Importo massimo',
  },
  time: {
    formats: {
      fullDateTime: '%d/%m/%Y %H:%M',
    },
  },
  date: {
    formats: {
      default: '%Y-%m',
      month: {
        number: '%m',
        short: '%b',
        full: '%B',
      },
      dayFullMonth: '%-d %B',
      year: '%Y',
      full: '%d/%m/%Y',
      fullDash: '%Y-%m-%d',
      fullMonthName: '%-d %B \'%y',
      fullMonthNameAndYear: '%-d %B %Y',
      monthYear: {
        short: {
          dash: '%b-%y',
          dashReverse: '%Y-%b',
          slash: '%m/%y',
          space: '%b %y',
        },
        full: {
          space: '%B %Y',
          dash: '%B-%Y',
          reverse: '%Y-%B',
        },
      },
    },
    abbr_day_names: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    day_names: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    abbr_month_names: [
      null,
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dic',
    ],
    month_names: [
      null,
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
  },
  buttons: {
    save: 'Salva',
    cancel: 'Annulla',
    confirm: 'Conferma',
    moveOn: 'Prosegui',
    edit: 'Modifica',
    delete: 'Elimina',
    disabled: 'Questa azione è disabilitata',
    logIn: 'Entra con Microsoft',
    logOut: 'Esci',
    logoutForm: `Esci da ${productName}`,
    connected: 'Connesso',
    connect: 'Connetti',
    reconnect: 'Riconnetti banca',
    update: 'Aggiorna',
    start: 'Inizia',
    close: 'Chiudi',
    invite: 'Invita',
    manage: 'Gestisci',
    goToDashboard: 'Torna alla Dashboard',
    goToClient: 'Gestisci un\'altra azienda',
    tryAgain: 'Riprova',
    verifyLinks: 'Verifica collegamenti',
    allFilters: 'Tutti i filtri',
    cancelFilters: 'Annulla',
    applyFilters: 'Applica filtri',
    filtersApplied: 'Filtri applicati',
  },
  confirmation: {
    logOut: 'Vuoi uscire dal tuo profilo?',
  },
  roles: {
    admin: 'Amministratore',
    colleague: 'Collega',
    accountant: 'Commercialista',
  },
  hints: {
    dashboard: {
      [CashFLowHintEnum.LIQUIDITY_OK]:
        'Non ci dovrebbero essere problemi di liquidità nei prossimi mesi.',
      [CashFLowHintEnum.LIQUIDITY_PROBLEM]:
        'Controlla la pianificazione, la tua liquidità potrebbe non essere sufficiente nel mese di {{month}}.',
      OUTFLOW_PLANNING_PROBLEM: {
        title: 'L\'importo pianificato ci risulta troppo basso.',
        content:
          'Considerando transazioni e debiti, le tue uscite del mese saranno almeno {{totalEstimated}}. Ti consigliamo di aggiornare la pianificazione per vedere flussi di cassa più precisi.',
      },
      INFLOW_PLANNING_PROBLEM: {
        title: 'L\'importo pianificato ci risulta troppo basso.',
        content:
          'Considerando transazioni e crediti, le tue entrate del mese saranno almeno {{totalEstimated}}. Ti consigliamo di aggiornare la pianificazione per vedere flussi di cassa più precisi.',
      },
    },
  },
  errors: {
    revokedAccess: {
      title: 'Non hai accesso a questa azienda',
      body: 'Credi sia un errore? Contatta l\'amministratore e chiedi di invitarti!',
    },
    error: 'Houdston, abbiamo un problema: ',
    options: 'Non ci sono opzioni disponibili',
    cashflowClients:
      'Per favore, importa i tuoi clienti per poter procedere al calcolo del cash flow.',
    noConnections: 'Non hai ancora collegato alcuna banca',
    missingProvider: 'Non è collegato alcun provider.',
    vatValidation: {
      notFound: 'Partita IVA non trovata.',
      404: 'Partita IVA non trovata. Se l\'errore persiste <Link>clicca qui</Link> per chiedere aiuto.',
      400: 'Partita IVA non corretta. Se l\'errore persiste <Link>clicca qui</Link> per chiedere aiuto.',
      502: 'Servizio non disponibile. Se l\'errore persiste <Link>clicca qui</Link> per chiedere aiuto.',
    },
    invitations: {
      400: 'Hai già invitato questo utente.',
      409: 'Non puoi invitare te stesso.',
      500: 'Ci dispiace, non è stato possibile inviare l\'invito. Riprova tra qualche minuto.',
    },
    form: {
      general: 'Valore non valido.',
      required: 'Questo campo è obbligatorio.',
      number: {
        integer: 'Deve essere un intero.',
        positive: 'Deve essere un numero positivo.',
      },
      date: {
        monthCode: 'Il formato deve essere yyyy-MM',
        fullDate: 'Il formato deve essere dd/MM/yyyy',
        past: 'Questa data deve essere nel futuro',
        endDate: 'La Data Fine deve essere successiva alla Data Inizio',
        parent: 'La Data Inizio deve essere successiva alla Data Fine della versione precedente',
      },
      email: 'Deve essere una mail valida',
      password:
        'Deve contenere 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale',
      confirmEmail: 'Le email devono corrispondere',
      sameEmail: 'Non puoi utilizzare la tua stessa mail',
      confirmPassword: 'Le password devono corrispondere',
      vatNumber:
        'Deve contenere 11 numeri, senza spazi e senza prefisso nazionale. Es: 10278370967',
    },
    dialog: {
      default: 'Ops, c\'è stato un errore con la tua richiesta. Riprova più tardi.',
    },
    client: 'Questo cliente non è nel DB',
  },
  months: {
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',
  },
  planning: {
    title: 'Pianificazione {{year}}',
    add: 'Aggiungi previsione',
    addIncome: 'Aggiungi Previsione in entrata',
    addExpense: 'Aggiungi Previsione in uscita',
    entryDetail: 'Dettaglio previsione',
    incomeEntry: 'Entrata pianificata',
    expenseEntry: 'Uscita pianificata',
    category: 'Categoria',
    name: 'Nome',
    amount: 'Importo',
    frequency: 'Tipo di ricorrenza',
    date: 'Mese',
    startDate: 'Mese d\'inizio',
    endDate: 'Mese di fine',
    endDateHint: 'Questa ricorrenza verrà applicata a tutti i mesi/trimestri futuri.',
    save: 'Salva Modifiche',
    categoryPlaceholder: 'Nome Categoria',
    addRecurrency: 'Applica ricorrenza',
    editRecurrence: 'Applica modifiche',
    deleteRow: 'Elimina pianificazione',
    cancel: 'Annulla',
    revert: 'Ok',
    error: {
      title: 'Ops, qualcosa è andato storto',
      body: 'Non siamo riusciti a salvare alcune delle tue modifiche, per cui potresti doverle riapplicare. Se il problema persiste, scrivici in chat per avere più supporto.',
    },
    card: {
      initialMonthAmount: 'Saldo a inizio mese',
      totalRemaining: 'Saldo a fine mese',
      delta: 'Delta stimato',
    },
    recurrence: {
      title: 'Ripeti la transazione',
      body: 'Puoi ripetere questa transazione di {{amount}} nei mesi futuri.',
    },
    update: {
      title: 'Applica modifiche',
      body: 'Vuoi applicare la modifica alla sola previsione di {{month}} o anche alle successive ricorrenze?',
      [UpdateMode.Single]: 'Solo su {{month}}',
      [UpdateMode.TillEnd]: 'A tutte le ricorrenze a partire da {{month}}',
      [UpdateMode.TillDate]: 'Alle ricorrenze da {{month}} fino a...',
    },
    delete: {
      title: {
        in: 'Sei sicuro di voler eliminare l’entrata “{{name}}”?',
        out: 'Sei sicuro di voler eliminare l’uscita “{{name}}”?',
      },
      body: 'Confermando tutte le ricorrenze passate e future di questa pianificazione verrano perse.',
    },
    remove: {
      title: 'Rimuovi ricorrenza',
      subTitle: 'Ferma la ricorrenza',
      [UpdateMode.Single]: 'Applica solo su {{month}}',
      [UpdateMode.TillEnd]: 'Ferma la ricorrenza per sempre',
      [UpdateMode.TillDate]: 'Ferma la ricorrenza per un periodo fino a...',
    },
    frequencies: {
      [PlanningFrequency.None]: 'Nessuna Ricorrenza',
      [PlanningFrequency.Spot]: 'Nessuna Ricorrenza',
      [PlanningFrequency.Monthly]: 'Mensile',
      [PlanningFrequency.Quarterly]: 'Trimestrale',
    },
    out: {
      title: 'Uscite Pianificate',
    },
    in: {
      title: 'Entrate Pianificate',
    },
  },
  forecasts: {
    title: 'Pianificazione',
    expenses: {
      title: 'Uscite',
    },
    incomes: {
      title: 'Entrate',
    },
  },
  expenses: {
    noExpenses:
      'Le uscite pianificate ci permettono di costruire il tuo flusso di cassa futuro: aggiungi i tuoi stipendi, affitti, e spese ricorrenti.',
    actions: {
      newVersion: 'Nuova versione',
      edit: 'Modifica',
      delete: 'Elimina',
    },
    buttons: {
      add: 'Aggiungi uscita',
      paid: 'Segna come pagata',
      checkPaid: 'Indeciso? Cerca un pagamento',
      mandatory: 'obbligatoria',
      history: 'Ha uno storico',
    },
    history: {
      title: 'Storico spesa',
      initial: 'Versione Iniziale',
      noDescription: 'Questa versione non ha descrizione',
      passed: 'passata',
      future: 'futura',
    },
    form: {
      name: 'Nome',
      description: 'Descrizione',
      amount: 'Ammontare',
      startDate: 'Data Inizio',
      endDate: 'Data Fine',
      endDateHelp: 'Ricorrenza infinita',
      isInvestment: 'Obbligatoria',
      frequency: 'Ricorrenza',
      changeDescription: 'Note sul cambiamento',
    },
    dialog: {
      title: {
        add: 'Aggiungi uscita',
        edit: 'Modifica ',
        newVersion: 'Nuova versione di ',
        confirmation: 'Vuoi eliminare questa spesa?',
        delete: 'In questo modo cancellerai permanentemente questa spesa.',
      },
    },
    frequency: {
      MONTHLY: 'Mensile',
      QUARTERLY: 'Trimestrale',
      YEARLY: 'Annuale',
      SPOT: 'Nessuna ricorrenza',
    },
  },
  invoices: {
    title: 'Fatture',
    emitted: 'Emesse',
    received: 'Ricevute',
    filterForInvoiceDate: 'Filtra per data fattura',
    placeholder: 'Nessuna fattura trovata',
    empty: {
      title: 'Tutte le tue fatture, sempre accessibili, sempre aggiornate',
      subtitle:
        'Riconcilieremo automaticamente le fatture con le transazioni bancarie, in modo che tu non perda più nessun pagamento. E ti segnaleremo debiti e crediti scaduti',
      cardTitle: 'Per importare le fatture ci colleghiamo al tuo Cassetto Fiscale',
      cardContent:
        'Ti serviranno le credenziali di accesso al Cassetto Fiscale dell’Agenzia delle Entrate (Codice Fiscale, Password e PIN di Fisconline). Se solitamente entri con SPID o altri metodi, abbiamo preparato un breve tutorial per guidarti nel recupero delle credenziali.',
      tutorialAction: 'Leggi la guida',
    },
    actions: {
      import: 'Importa fatture',
      reconcile: 'Riconcilia',
    },
    description: {
      ritenuta: 'Fattura {{title}} - Ritenuta d\'acconto',
      installment: 'Fattura {{title}} - Pagamento {{number}}',
      oneShot: 'Fattura {{title}} - Unica soluzione',
    },
    paidHint: {
      FOUND_MATCHING_TRANSACTION: 'Abbiamo trovato una transazione effettuata per questa fattura',
      OVERDUE_PAYMENT: 'Questa fattura è troppo vecchia per essere ancora pendente',
      IMMEDIATE_PAYMENT: 'La fattura risulta saldata immediatamente (carta, contanti, RID, ...)',
      NOTA_DI_CREDITO: 'Questa fattura è una nota di credito',
    },
    overview: {
      emitted: {
        total: 'Il tuo credito totale verso clienti è',
        noOne: 'Nessun credito verso clienti',
      },
      received: {
        total: 'Il tuo debito totale verso fornitori è',
        noOne: 'Nessun debito verso fornitori',
      },
      count: 'relativo a',
      overdue: {
        zero: 'nessuna in ritardo',
        other: '{{count}} in ritardo',
        one: '{{count}} in ritardo',
      },
      invoice: {
        zero: 'fattura, di cui', // unreachable
        one: 'fattura, di cui',
        other: 'fatture, di cui',
      },
    },
    filters: {
      all: 'Tutte le fatture',
      paid: 'Saldate',
      notSettled: 'Da saldare',
      unpaid: 'Da saldare',
      overdue: 'In ritardo',
    },
    status: {
      paid: 'Saldata',
      notSettled: 'Da saldare',
      unpaid: 'Da saldare',
      overdue: 'In ritardo',
      notOverdue: 'Scadenza futura',
    },
    table: {
      header: {
        '#': ' ',
        supplier: 'Fattura fornitori',
        client: 'Fattura clienti',
        amount: 'Ammontare',
        receivedDate: 'Ricezione',
        emittedDate: 'Emissione',
        dueDate: 'Scadenza',
        status: 'Stato',
      },
    },
    details: {
      title: 'Dettagli fattura',
      cashOut: 'Incassa in anticipo',
      payInvoice: 'Paga fattura',
      invoiceId: 'Fattura #{{invoiceId}}',
      dueDate: 'Scadenza {{dueDate}}',
      fiscalCode: 'Partita IVA {{fiscalCode}}',
      to: 'Fattura verso',
      from: 'Fatturato da',
      service: 'Fornitura servizi',
      iva: 'IVA (21%)',
      total: 'Totale da incassare',
      info: 'Come funziona il anticipo fatture?',
      new: 'Novità',
    },
  },
  clients: {
    table: {
      header: {
        name: 'Cliente',
        paymentCondition: 'Termini di Pagamento',
        turnover: 'Fatturato {{year}}',
      },
    },
    title: 'Clienti',
    labels: {
      edit: 'Modifica {{name}}',
      delay: 'Ritardo (giorni)',
      paymentConditions: 'Condizioni di Pagamento',
      piva: 'P.IVA {{piva}}',
    },
    paymentConditions: {
      IMMEDIATE: 'Immediato',
      P30FDM: '30 giorni data fattura fine mese',
      P45FDM: '45 giorni data fattura fine mese',
      P60FDM: '60 giorni data fattura fine mese',
      P30DF: '30 giorni data fattura',
      P45DF: '45 giorni data fattura',
      P60DF: '60 giorni data fattura',
    },
    buttons: {
      import: 'Importa Clienti',
    },
    delay: {
      one: 'Ritardo stimato: 1 giorno',
      other: 'Ritardo stimato: {{count}} giorni',
    },
    list: {
      noResults: 'Non ci sono clienti che corrispondono alla ricerca',
      placeholder: 'Non abbiamo tuoi clienti, al momento. Hai provato ad importare le tue fatture?',
      import: 'Importa le tue fatture e troveremo automaticamenti i tuoi clienti!',
    },
  },
  billedAmount: {
    buttons: {
      create: 'Aggiungi entrata',
    },
    labels: {
      create: 'Aggiungi entrata',
      edit: 'Modifica entrata',
      total: 'Totale: ',
      delete: 'Vuoi davvero cancellare questa entrata?',
      delete_confirmation: 'Procedendo eliminerai in modo permanente questa voce',
    },
    form: {
      client: 'Cliente',
      project: 'Nome',
      amount: 'Ammontare',
      monthCode: 'Anno e Mese',
    },
  },
  cashflow: {
    emptyState:
      'Analizza passato, presente e futuro della tua liquidità aziendale in modo semplice e intuitivo.',
    title: 'Evoluzione Saldo',
    events: {
      transaction: 'Transazione del {{date}}',
      invoice: {
        ritenuta: 'Fattura {{title}} del {{date}} - Ritenuta d\'acconto',
        installment: 'Fattura {{title}} del {{date}} - Pagamento {{number}}',
        oneShot: 'Fattura {{title}} del {{date}} - Unica soluzione',
      },
      estimatedExpense: 'Spesa pianificata per il {{date}}',
      estimatedIncome: 'Fatturazione pianificata a {{date}}',
    },
  },
  dashboard: {
    tooltip: {
      balance: 'Saldo',
      income: 'Entrate',
      spent: 'Uscite',
      startOfMonth: 'Inizio mese',
      endOfMonth: 'Fine mese',
      estimated: 'di {{estimated}} pianificate',
      estimatedBalance: {
        start:
          'Calcoliamo il saldo sulla base delle transazioni bancarie, delle fatture e delle entrate e uscite pianificate.',
        end: 'Calcoliamo il saldo sulla base delle transazioni bancarie, delle fatture e delle entrate e uscite pianificate.',
      },
    },
    incomes: {
      planned: 'Entrate Pianificate',
      billed: 'Crediti da incassare',
      actual: 'Entrate Effettive',
    },
    expenses: {
      planned: 'Uscite Pianificate',
      billed: 'Debiti da pagare',
      actual: 'Uscite Effettive',
    },
    labels: {
      cashflow: 'Cash Flow',
      total: 'Totale',
      net: 'Saldo: ',
      incomes: 'Entrate',
      income: 'Entrate',
      invoices: 'Pagamenti delle fatture',
      estimatedTransactions: 'Pianificate',
      estimatedMovements: 'Movimenti pianificati',
      balanceAt: 'Saldo {{date}}',
      spent: 'Uscite',
      delta: 'Differenza',
      paid: 'saldato',
      received: 'saldato',
      billed: 'fatturato pianificato',
      expenses: 'Uscite',
      month: 'Saldo attuale',
      eoy: 'Utile lordo a fine anno',
      revenues: 'Fatturato',
      costs: 'Costi',
      start: 'Inizio',
      end: 'Fine',
      diff: 'Delta mensile',
      actualTransactions: 'Transazioni bancarie',
      actualMovements: 'Transazioni bancarie',
      forecast: 'Entrate e Uscite pianificate',
      changes: 'Modifiche salvate: scopri il tuo flusso di cassa aggiornato!',
      credits: 'Crediti da incassare',
      debits: 'Debiti da pagare',
      monthDetail: 'Dettaglio di',
      exploreForcasts: 'Esplora la pianificazione',
    },
    balanceCard: {
      emptyState: 'Ottieni una vista completa e sempre aggiornata della tua cassa.',
      tooltip: {
        title: 'Tutte i tuoi conti, sempre accessibili, sempre aggiornati',
        content:
          'Collegare i tuoi conti bancari a Cashoo ti consente di controllare facilmente la tua liquidità e di analizzare ogni transazione. I tuoi dati sono al sicuro: utilizziamo protocoli europei PSD2 e crittografia di ultima generazione.',
        ctaText: 'Perchè collegare un conto?',
      },
      hints: {
        error: 'Uno o più dei tuoi conti collegati presenta dei problemi.',
        BALANCE_OK: 'In questo mese, il saldo disponibile coprirà tutte le uscite previste.',
        BALANCE_KO: 'Le tue disponibilità di cassa potrebbero non essere sufficienti questo mese.',
        BALANCE_WARNING:
          'Assicurati di incassare quanto pianificato per tempo: in caso contrario potresti avere problemi di liquidità.',
      },
      balance: 'Saldo disponibile',
      linkBanks: 'Collega le tue banche',
      addAccount: 'Aggiungi conti',
      connectBanks:
        'Collega i tuoi conti e dimenticati delle importazioni manuali su excel, vedi le tue transazioni e il tuo saldo e proiettati nel futuro',
      authorizeBanksText:
        'In meno di un minuto, vedrai la situazione di tutti i tuoi conti, sempre aggiornata.',
      linkedBanks: {
        one: '1 banca collegata',
        other: '{{count}} banche collegate',
        zero: 'Nessuna banca collegata',
        label: {
          one: 'Banca Collegata',
          zero: 'Banche Collegate',
          other: 'Banche Collegate',
        },
      },
      consentsToUpdate: {
        one: '1 collegamento da aggiornare',
        other: '{{count}} collegamenti da aggiornare',
        zero: 'Nessuna collegamento da aggiornare',
      },
    },
    revenueCard: {
      emptyState: 'Fatturato, debiti, crediti: non ti perderai più nessuna scadenza.',
      tooltip: {
        title: 'Tutte le tue fatture, sempre accessibili, sempre aggiornate',
        content:
          'Riconcilieremo automaticamente le fatture con le transazioni bancarie, in modo che tu non perda più nessun pagamento. E ti segnaleremo debiti e crediti scaduti.',
        ctaText: 'Recupera le credenziali',
      },
      revenue: 'Fatturato netto {{period}}',
      running:
        'Stiamo scaricando le tue fatture: entro due minuti, comporremo il tuo flusso di cassa!',
      error:
        'Qualcosa è andato storto. Ma niente panico: siamo stati avvisati e controlleremo a breve.',
      updatedAt: 'Dato aggiornato {{time}}',
      notConnected: 'Non ancora collegato',
      connect: 'Importa le fatture',
      invoicesImport:
        'Importa le tue fatture dal Cassetto Fiscale per una visione precisa di entrate e uscite future.',
      invoicesImportText: 'In due click, potrai controllare tutti i tuoi crediti e debiti.',
      hints: {
        error:
          'Alcune delle tue fatture non sembrano essere state processate correttamente. Il team di sviluppo sta verificando il problema.',
        TURNOVER_EXPLODED: {
          prefix: 'Il tuo fatturato è in forte aumento:',
          suffix: 'rispetto allo scorso anno.',
          inline: 'Il tuo fatturato è in forte aumento: {{perc}}% rispetto allo scorso anno.',
        },
        TURNOVER_INCREASED: {
          prefix: 'Il tuo fatturato è in crescita del',
          suffix: 'rispetto allo scorso anno.',
          inline: 'Il tuo fatturato è in crescita del {{perc}}% rispetto allo scorso anno.',
        },
        TURNOVER_ON_TRACK: {
          prefix: 'Il tuo fatturato è variato del',
          suffix: 'rispetto allo scorso anno.',
          inline: 'Il tuo fatturato è variato del {{perc}}% rispetto allo scorso anno.',
        },
        TURNOVER_DECREASED: {
          prefix: 'Il tuo fatturato è in calo del',
          suffix: 'rispetto allo scorso anno.',
          inline: 'Il tuo fatturato è in calo del {{perc}}% rispetto allo scorso anno.',
        },
      },
    },
    planningCard: {
      emptyState:
        'Pianifica le entrate e le uscite di cassa dei prossimi mesi e tieni il tuo saldo sotto controllo.',
      tooltip: {
        title: 'Il futuro del tuo business, a portata di mano',
        content:
          'Puoi confrontare la situazione attuale con quella pianificata, prendere decisioni informate, prevenire rischi legati alla liquidità e cogliere nuove opportunità per fare investimenti.',
      },
    },
    actions: {
      markAsPaid: {
        emitted: 'Segna come incassata',
        received: 'Segna come pagata',
      },
      goToInvoices: 'Fatture',
      goToTransactions: 'Transazioni',
      startPlanning: 'Inizia a pianificare',
    },
    insights: {
      LOAN: {
        tag: 'Finanziamenti',
        title: 'Ottieni un finanziamento di {{loan}}',
        content: 'Prevediamo una mancanza di liquidità verso {{month}}',
        offer: 'Offerta',
      },
      ENERGY_COST_TREND: {
        title: 'Caro bollette',
        content:
          'Quest\'anno hai speso <Num>{{currentAmount}}</Num> per elettricità, acqua e gas: un aumento del <Num>{{trend}}%</Num> rispetto allo stesso periodo dello scorso anno.',
        cta: 'Scopri come risparmiare',
      },
      INNOVATION_VOUCHER: {
        title: 'Voucher innovazione',
        content:
          'Hai <Num>41.756 €</Num> di spese verso fornitori iscritti al registro MIMIT. Ottieni finanziamenti a fondo perduto per la tua transizione digitale ed ecologica.',
        cta: 'Scopri di più',
      },
      ACTIVATION: {
        title: `Il tuo profilo su ${productName}`,
        bank: 'Connetti una banca',
        invoices: 'Importa le fatture',
        planning: 'Pianifica entrate e uscite',
      },
    },
  },
  settings: {
    actions: {
      go: 'Vai alla Dashboard',
      clients: 'Vai in Clienti',
      providers: 'Impostazioni Provoder',
      viewDetails: 'Vedi Informazioni',
      detailsProvider: 'Dettagli del Provider',
      editProvider: 'Modifica i Dettagli del Provider',
      hide: 'Nascondi',
      addProvider: 'Aggiungi Provider',
      changeProvider: 'Cambia Provider',
    },
    oneProvider: 'Mi spiace, questo è l\'unico Provider supportato al momento.',
    current: 'Provider Attuale',
    providers: 'Provider',
    dataProvider: 'Data Provider',
    banks: 'Banche',
    selectBank: 'Scegli la tua banca',
    banksBack: 'Indietro',
    bankConnectionsTable: {
      header: {
        name: 'Banca',
        balance: 'Saldo disponibile',
        status: 'Stato',
      },
    },
    bankConnection: {
      inactiveMessage: 'Riconnetti per vedere il tuo saldo',
      inactive: 'Inattivo',
      cta: 'Inizia da qui',
      noConnectionsText:
        'Autorizza le tue banche a condividere con Cashoo i dati di saldo e transazioni per una vista precisa della disponibilità di cassa.',
      lastUpdate: 'Ultimo Aggiornamento',
      status: 'Stato di Sync',
      renewConnection: 'Rinnova',
      delete: 'Elimina',
      add: 'Collega una banca',
      identifier: 'Identificativo',
      currency: 'Valuta',
      expiresAt: 'Scade {{date}}',
      expires: 'In scadenza',
      expired: 'Scaduto',
      updated: 'Aggiornato',
      deleteConfirmationTitle: 'Eliminazione connessione bancaria',
      deleteConfirmationBody: 'Sei sicuro di voler rimuovere l\'integrazione con "{{provider}}"?',
    },
    ade: 'Agenzia delle Entrate',
    service: 'Cassetto Fiscale',
    taxFolder: {
      updateDisabled: 'Prima di poter aggiornare nuovamente è necessario attendere almeno 2 ore.',
      ade: 'Agenzia delle Entrate',
      service: 'Cassetto Fiscale',
      title: 'Accesso al cassetto fiscale',
      subTitle:
        'Accedi al cassetto fiscale per scaricare le fatture elettroniche ricevute ed inviate.',
      action: 'Accedi',
      imageAlt: 'Logo dell\'agenzia delle entrate',
      recoverCredentials: 'Recupera credenziali',
      tutorialTitle: 'Per importare le fatture ti servono le credenziali di accesso a Fisconline',
      tutorialContent:
        'Molto probabilmente ad oggi accedi ai servizi dell\'agenzia delle entrate con SPID... Segui questa procedura per recuperare le credenziali corrette:',
      tutorialSupport:
        'Hai bisogno di supporto? Scrivici! Oppure prova a contattare il tuo commercialista, di sicuro saprà aiutarti',
      tooltipContent: 'Segui questa procedura per recuperarle:',
      readGuide: 'Leggi la guida',
      seeGuide: 'Guarda la video guida',
      invoices: {
        title: 'Importa le tue fatture',
        notConnected:
          'Importa le tue fatture dal Cassetto Fiscale per una visione precisa di entrate e uscite future.',
        subTitle: 'Connessione all’Agenzia delle Entrate - Fatture e Corrispettivi',
        fiscalCode: 'Codice fiscale personale',
        password: 'Password di Fisconline',
        pin: 'PIN di Fisconline',
        vatNumber: 'Codice delegato (PIVA)',
        secure:
          'I tuoi dati sono al sicuro, criptati in transito e a riposo. Ci connetteremo in sola lettura e non potremo mai vedere le tue credenziali.',
        credentialsError:
          'Le credenziali inserite non sono valide. Ecco una guida per il recupero delle credenziali necessarie.',
        success: 'Stiamo scaricando le tue fatture...',
      },
    },
    billingSystem: {
      title: {
        one: 'Servizio di fatturazione elettronica',
        zero: 'Servizi di fatturazione elettronica',
        other: 'Servizi di fatturazione elettronica',
      },
      table: {
        source: 'Fonte dati',
        lastUpdatedAt: 'Ultimo Aggiornamento',
      },
    },
  },
  reconciliation: {
    button: 'Riconcilia',
    types: {
      OVERDUE_PAYMENT: 'fatture scadute da più di un mese',
      IMMEDIATE_PAYMENT:
        'fatture con metodo di pagamento immediato (carta di credito, contanti, ...)',
      FOUND_MATCHING_TRANSACTION:
        'fatture con una transazione bancaria vicina alla scadenza e di importo corrispondente',
    },
    confirmation: {
      title: 'Vuoi riconciliare in automatico le tue fatture?',
      subtitle: 'Segneremo come saldate:',
      info: 'Dopo la riconciliazione, potrai sempre cambiare lo stato di una fattura manualmente.',
    },
    results: {
      title: {
        before: {
          zero: 'Purtroppo non siamo riusciti a riconciliare alcuna fattura',
          one: 'Abbiamo riconciliato ',
          other: 'Abbiamo riconciliato ',
        },
        after: {
          zero: '',
          one: ' fattura',
          other: ' fatture',
        },
      },
      actions: {
        check: 'Controlla le fatture riconciliate',
        goBack: 'Torna alle fatture',
      },
    },
  },
  accountant: {
    banner: {
      full: 'Stai visualizzando i dati dell’azienda come commercialista a supporto',
      mobile: 'Commercialista',
    },
    actions: {
      delete: 'Termina collaborazione',
      confirmDelete: {
        title: 'Sei sicuro di voler terminare la collaborazione con {{company}}?',
        body: `Non potrai più accedere ai dati di {{company}} presenti su ${productName}.`,
      },
    },
    mail: {
      subject: 'Registrazione su Cashoo - {{accountantCompanyName}}',
      body: 'Per procedere alla registrazione (gratuita) in Cashoo ed essere seguiti dal mio studio, cliccare su questo link -> https://app.cashoo.it/ .',
      inviteClients: 'Invita clienti',
    },
    switch: {
      area: 'Aziende seguite',
      ownCompany: 'La tua attività',
    },
    clients: {
      noItems: {
        title: 'Al momento non hai in gestione alcuna azienda',
        body: `Chiedi ai tuoi clienti di invitarti a collaborare su ${productName}`,
      },
      title: {
        zero: `Aziende in gestione come commercialista su ${productName}`,
        one: `Azienda in gestione come commercialista su ${productName}`,
        other: `Aziende in gestione come commercialista su ${productName}`,
      },
      headers: {
        name: 'Nome azienda',
      },
    },
  },
  company: {
    tabs: {
      partners: {
        title: 'Collaboratori',
        actions: {
          delete: 'Rimuovi dai collaboratori',
          resend: 'Invia un nuovo invito',
          confirmDelete: {
            title: 'Vuoi davvero rimuovere {{user}} dai tuoi collaboratori?',
            body: `{{user}} potrà più accedere alla tua azienda su ${productName}.`,
          },
        },
        table: {
          header: {
            name: 'Nome',
            email: 'Email',
            role: 'Ruolo in Cashoo',
            self: 'Sei tu!',
          },
          company: '{{company}} ha',
          title: {
            one: 'utente',
            zero: 'utenti',
            other: 'utenti',
          },
        },
        invitation: {
          prefix: 'Il tuo cliente',
          of: 'di',
          suffix: `ti ha invitato a collaborare su ${productName}`,
          body: `Il tuo cliente {{firstName}} {{lastName}} di {{company}} ti ha invitato a collaborare su ${productName}.`,
          info: '{{sentDate}} · {{firstName}} {{lastName}}',
          feedback: {
            title: 'Sei diventato un collaboratore di {{company}}!',
            body: 'Ora potrai accedere ai dati dell\'azienda e aiutarla nella gestione della liquidità.',
          },
          actions: {
            accept: 'Accetta',
            decline: 'Rifiuta',
          },
          status: {
            PENDING: 'In attesa di registrazione',
            REVOKED: 'L\'invito è stato revocato',
            REJECTED: 'L\'invito è stato rifiutato',
            EXPIRED: 'L\'invito è scaduto',
            RESENT: 'Un nuovo invito è stato inviato',
          },
          revoke: 'Potrai revocare gli accessi dei tuoi collaboratori in ogni momento.',
          permission:
            'Voglio inviare un invito alla mail indicata e condividere con questo utente i dati della mia azienda.',
          [InvitationType.COLLEAGUE]: {
            data: 'Inserisci i dati del tuo collega',
            email: 'Email',
            title: 'Invita i tuoi colleghi',
            body: 'Condividi le informazioni della situazione di cassa con colleghi e soci per essere sempre sulla stessa pagina.',
          },
          [InvitationType.ACCOUNTANT]: {
            data: 'Inserisci i dati del tuo commercialista',
            email: 'Email',
            title: 'Invita il tuo commercialista',
            body: `Collabora con il tuo commercialista su ${productName}: zero carte e perdite di tempo, massima produttività.`,
          },
        },
      },
    },
  },
  transactions: {
    title: 'Transazioni',
    empty: {
      title: 'Tutte i tuoi conti, sempre accessibili, sempre aggiornati',
      subtitle: `Collegare i tuoi conti bancari a ${productName} ti consente di controllare facilmente la tua liquidità e di analizzare ogni transazione.`,
      addBank: 'Aggiungi conti',
    },
    amount: 'Importo',
    executionDate: 'Data esecuzione',
    detail: 'Dettaglio transazione',
    details: {
      info: {
        id: 'Id',
        description: 'Descrizione',
      },
      loanOptions: 'Opzioni di finanziamento',
      divideCard: {
        title: 'Rateizza la spesa',
        body: 'Finanzia i {{amount}} fino a 5 anni.',
        button: 'Rateizza',
      },
      postponeCard: {
        title: 'Posticipa il pagamento',
        body: 'Recupera istantaneamente i {{amount}} sul tuo conto e ripagali il 25 del mese successivo.',
        button: 'Posticipa',
      },
      help: 'Hai bisogno di supporto? <Link>Contattaci</Link>',
    },
    noResults: 'I filtri applicati non hanno prodotto risultati.',
    placeholder:
      'Autorizza le tue banche a condividere con Cashoo i dati di saldo e transazioni per una vista precisa della disponibilità di cassa.',
    filters: {
      title: 'Filtra le transazioni',
      allBanks: 'Tutte le banche',
      allTypes: 'Tutte le transazioni',
      [TransactionType.Income]: 'Entrate',
      [TransactionType.Expense]: 'Uscite',
      startDate: 'Data Inizio',
      endDate: 'Data Fine',
      banks: 'Banche da visualizzare',
      type: 'Tipo di transazione',
      period: 'Periodo di riferimento',
      amountRange: 'Range d\'importo',
    },
    defaultPassiveTitle: 'Uscita',
    defaultActiveTitle: 'Entrata',
  },
  welcome: {
    skip: 'Salta',
    next: 'Prosegui',
    start: 'Inizia',
    demo: {
      email: 'Email',
      title: 'Inserisci la tua mail per visitare la demo di Cashoo',
      content:
        'Crediamo nella trasparenza, e non vogliamo farti perdere tempo. In questo ambiente troverai il nostro prodotto alla versione attuale, con dati di esempio di una azienda immaginaria, la fortissima Acme srl.',
    },
    default: {
      step1Title: 'Semplifica la gestione delle finanze aziendali',
      step1Content:
        'Cashoo ti aiuta a monitorare, gestire e pianificare i flussi di cassa della tua azienda. Fai tutto in autonomia, non serve alcuna formazione!',
      step2Title: 'Monitora il saldo di TUTTI i tuoi conti in tempo reale',
      step2Content:
        'Sai sempre su quanta cassa puoi contare e non impazzisci a cercare informazioni sparpagliate ovunque; puoi cercare le transazioni e controllare il saldo in un’unica vista.',
      step3Title: 'Controlla lo stato di pagamenti e incassi',
      step3Content:
        'Importi in modo automatico le fatture e fai la riconciliazione con pagamenti e incassi senza bisogno di excel; così risparmi tempo da dedicare ad altre attività importanti.',
      step4Title: 'Pianifica entrate e uscite future',
      step4Content:
        'Puoi confrontare la situazione attuale con quella pianificata, prendere decisioni informate e prevenire rischi legati alla liquidità o cogliere nuove opportunità per fare investimenti.',
    },
    accountant: {
      step1Title: 'Semplifica la gestione delle finanze dei tuoi clienti',
      step1Content:
        'Con Cashoo puoi monitorare i flussi di cassa delle aziende che segui, e notare in anticipo segnali di stress. Fai tutto in autonomia, non serve alcuna formazione!',
      step2Title: 'Monitora il saldo di TUTTI i loro conti in tempo reale',
      step2Content:
        'Sai sempre su quanta cassa possono contare e non impazzisci a recuperare o chiedere informazioni sparpagliate ovunque.',
      step3Title: 'Controlla lo stato di pagamenti e incassi',
      step3Content:
        'I tuoi clienti possono importare le loro fatture, e noi le riconciliamo in automatico con pagamenti e incassi senza bisogno di Excel; così risparmi tempo da dedicare ad altre attività più importanti.',
      step4Title: 'Aiutali nella pianificazione',
      step4Content:
        'Con il nuovo codice delle Crisi di Impresa, riconoscere in anticipo i segnali di stress è fondamentale. Con Cashoo ottieni uno strumento condiviso di lavoro in cui aiutare i tuoi clienti a pianificare il futuro ed evidenziare subito eventuali rischi futuri.',
      step5Title: 'Invita i tuoi clienti, è gratuito',
      step5Content:
        'Cashoo per i commercialisti è un servizio completamente gratuito. I primi 30 giorni sono gratuiti anche per le aziende clienti, dopodichè il servizio avrà un piccolo costo mensile.',
    },
  },
};
export default itTranslations;
