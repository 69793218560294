import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { queryClient } from '@financial-tool/data-management';
import getInvoicesFromTaxFolderAPI from '../apis/getInvoicesFromTaxFolder';
import { TaxFolderConnect } from '../types/settings';
import { DashboardRequestKeys } from '../../dashboard/asyncHandlers/keys';

const {
  hook: useGetInvoicesFromTaxFolder,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions(
  'settings/invoicesDownload',
  async (data: TaxFolderConnect) => {
    const res = await getInvoicesFromTaxFolderAPI(data);
    await queryClient.invalidateQueries([DashboardRequestKeys.Insights]);
    return res;
  },
);

export { useGetInvoicesFromTaxFolder };

export default {
  fulfilled,
  rejected,
  pending,
};
