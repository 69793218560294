import { useMediaQuery, useTheme } from '../components/_styles';

export const useBreakpoints = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return {
    isMobile,
  };
};
