import React, { useCallback } from 'react';
import { Box, ProductImg } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Form, FormInput, useForm } from '@financial-tool/forms';
import * as yup from 'yup';
import { errorMessages } from '@financial-tool/utils';
import WelcomeModalCard from '../WelcomeModalCard';
import { useTracker } from '../../../analytics';
import { WelcomeCardProps } from '../WelcomeModalCard/WelcomeModalCard';

const validationSchema = yup.object().shape({
  email: yup.string().email(errorMessages.email).required(errorMessages.form_required),
});

type Props = Omit<WelcomeCardProps, 'image' | 'titleKey' | 'contentKey' | 'disabled'>;
const DemoWelcomeCard: React.VFC<Props> = ({ goNext, ...controls }) => {
  const t = useTranslate();
  const { setEmailOnce } = useTracker();
  const { formProps } = useForm<{ email: string }>({
    validationSchema,
    initialValues: {
      email: '',
    },
  });
  const email = formProps.watch('email');

  const saveEmailAndClose = useCallback(() => {
    setEmailOnce(email);
    goNext();
  }, [setEmailOnce, email, goNext]);

  return (
    <WelcomeModalCard
      goNext={saveEmailAndClose}
      {...controls}
      disabled={!formProps.formState.isValid}
      image={<ProductImg width="100%" height="100%" />}
      titleKey="welcome.demo.title"
      contentKey="welcome.demo.content">
      <Box mt={4} width="100%">
        <Form {...formProps} onSubmit={() => {}}>
          <FormInput
            name="email"
            type="text"
            required
            label={t('welcome.demo.email')}
            placeholder={t('welcome.demo.email')}/>
        </Form>
      </Box>
    </WelcomeModalCard>
  );
};

DemoWelcomeCard.displayName = 'DemoWelcomeCard';
export default DemoWelcomeCard;
