import React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

type Props = {
  style?: LinearProgressProps['sx'];
  color?: LinearProgressProps['color'];
  value?: LinearProgressProps['value'];
  valueBuffer?: LinearProgressProps['valueBuffer'];
  variant?: LinearProgressProps['variant'];
};
const LinearProgressBar: React.FC<Props> = ({
  style,
  color = 'primary',
  value,
  valueBuffer,
  variant,
}) => (
  <LinearProgress
    color={color}
    value={value}
    variant={variant}
    valueBuffer={valueBuffer}
    sx={{
      width: '100%',
      height: '8px',
      borderRadius: '8px',
      ...style,
    }}/>
);

LinearProgressBar.displayName = 'LinearProgressBar';
export default LinearProgressBar;
