import React, { useCallback } from 'react';
import { Box, Link, Stack } from '@financial-tool/components';
import { useTranslate, useTranslateWithFormatting } from '@xtreamsrl/react-i18n';
import {
  Form, FormCheckBox, FormInput, VatFormInput,
} from '@financial-tool/forms';
import { UseFormReturn } from 'react-hook-form';
import { SignUpFormValues } from '../../hooks/useSignupForm';
import { VatInfo } from '../../types/user';

const CheckBoxLink = ({ children, href }: { children: React.ReactNode; href: string }) => (
  <Link variant="body2" color="primary" href={href} openInNewTab>
    {children}
  </Link>
);

const PRIVACY_LINK = 'https://docs.cashoo.it/Privacy-Policy-bc2441e6a5374865bbd958e3c9062e5a';
const TERMS_LINK = 'https://docs.cashoo.it/Termini-e-Condizioni-del-Servizio-74b0140ee87c475585abb20c9ab4f38c';

const components = {
  Privacy: ({ children }: { children: React.ReactNode }) => (
    <CheckBoxLink href={PRIVACY_LINK}>{children}</CheckBoxLink>
  ),
  Terms: ({ children }: { children: React.ReactNode }) => (
    <CheckBoxLink href={TERMS_LINK}>{children}</CheckBoxLink>
  ),
};

type Props = {
  formProps: UseFormReturn<SignUpFormValues, any>;
  onSubmit: (data: SignUpFormValues) => void;
};
const SignupForm: React.FC<Props> = ({ formProps, onSubmit }) => {
  const translate = useTranslate();
  const tWithFormatting = useTranslateWithFormatting();

  const setCompanyData = useCallback(
    (companyData: VatInfo) => {
      formProps.setValue('companyName', companyData.name);
      formProps.setValue('companyAddress', companyData.address);
    },
    [formProps],
  );

  return (
    <Form {...formProps} onSubmit={onSubmit}>
      <Stack direction="column" spacing={2} width="100%" mt={2}>
        <Stack spacing={2} justifyContent="center">
          <FormInput name="firstName" required label={translate('signup.firstName')} />
          <FormInput name="lastName" required label={translate('signup.lastName')} />
          <VatFormInput name="vatNumber" required onValidationSuccess={setCompanyData} />
        </Stack>
        <Box>
          <Stack>
            <FormCheckBox
              name="privacy"
              label={tWithFormatting('signup.privacy', { components })}/>
            <FormCheckBox
              name="terms"
              label={tWithFormatting('signup.termsAndConditions', { components })}/>
          </Stack>
        </Box>
      </Stack>
    </Form>
  );
};

SignupForm.displayName = 'SignupForm';

export default SignupForm;
