import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, LinearProgress } from '@financial-tool/components';
import { FeatureFlag, useFlag } from '@financial-tool/feature-flags';
import {
  CarouselContent,
  CarouselSlot,
  CarouselWrapper,
} from '../Insights/Carousel/Carousel.styled';
import { DashboardEvents, useTracker } from '../../analytics';
import { Slide, CustomSlide, Commands } from './types';
import WelcomeModalCard from './WelcomeModalCard';
import WelcomeModalPortal from './WelcomeModalPortal';
import { useWelcomeModalType } from './useWelcomeModalSlides';
import { modalList } from './utils';

const renderSlide = (slide: Slide | CustomSlide, commands: Commands) => {
  if (slide.component) {
    return React.createElement(slide.component, commands);
  }
  const { id, component, ...props } = slide;
  return <WelcomeModalCard {...props} {...commands} />;
};

const WelcomeModal: React.FC<{ close: () => void }> = ({ close }) => {
  const [position, setPosition] = useState(0);
  const allowSkipInWelcome = useFlag(FeatureFlag.AllowSkipInWelcome);
  const welcomeType = useWelcomeModalType();
  const slides = modalList[welcomeType!];

  const { track } = useTracker();

  const lastPosition = slides.length - 1;

  const isLastSlide = lastPosition === position;

  const goNext = useCallback(() => {
    if (position === lastPosition) {
      track(DashboardEvents.WelcomeEnd);
      close();
    } else {
      track(DashboardEvents.WelcomeSlideView, {
        position: position + 1,
        id: slides[position + 1].id,
      });
      setPosition(p => p + 1);
    }
  }, [position, lastPosition, track, close, slides]);

  const skip = useCallback(() => {
    track(DashboardEvents.WelcomeSkip);
    close();
  }, [track, close]);

  useEffect(() => {
    track(DashboardEvents.WelcomeView, { variant: 'default' });
    track(DashboardEvents.WelcomeSlideView, {
      position: 0,
      id: slides[0].id,
    });
  }, [slides, track]);

  return (
    <Dialog testId="welcome-modal" open onClose={() => ({})} noClose noActions maxWidth="xs">
      <CarouselWrapper>
        <WelcomeModalPortal />
        <CarouselContent pos={position} mb={8}>
          {slides.map((slide, index) => (
            <CarouselSlot key={`welcome-slide-${index}`} testId={`welcome-slide-${index}`}>
              {renderSlide(slide, {
                skip,
                goNext,
                isEnd: isLastSlide,
                skipNotAllowed: !allowSkipInWelcome || isLastSlide,
                hideControls: position !== index,
              })}
            </CarouselSlot>
          ))}
        </CarouselContent>

        {slides.length > 1 && (
          <LinearProgress
            variant="determinate"
            color="secondary"
            progress={((position + 1) * 100) / slides.length}/>
        )}
      </CarouselWrapper>
    </Dialog>
  );
};

WelcomeModal.displayName = 'WelcomeModal';

export default WelcomeModal;
