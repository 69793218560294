import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useEnvVars } from '@xtreamsrl/react-config';
import { useErrorManagement } from '@xtreamsrl/react-feedback-management';
import DemoModal from '../DemoModal';

const ErrorToast: React.VFC = () => {
  const { setErrorMessage, error } = useErrorManagement();
  const { demo } = useEnvVars();
  const handleClose = () => setErrorMessage(null);

  return demo ? (
    <DemoModal open={error !== null} closeDialog={handleClose} />
  ) : (
    <Snackbar
      data-test="error-toast"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={error !== null}
      autoHideDuration={3000}
      onClose={handleClose}>
      <Alert sx={{ width: '100%' }} onClose={handleClose} severity="error" variant="filled">
        {error?.message}
      </Alert>
    </Snackbar>
  );
};

ErrorToast.displayName = 'ErrorToast';
export default ErrorToast;
