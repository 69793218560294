import React, { useCallback, useMemo } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
  useMenu,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import {
  ArrowDownIcon, BusinessIcon, CompanyIcon, ListIcon,
} from '@financial-tool/icons';
import { NavLink } from 'react-router-dom';
import { Feature, FeatureWrapper } from '@financial-tool/feature-flags';
import { linkStyle } from '../../../_layout/components/Menu/linkStyle';
import { useAccountantMode } from '../../../user-session/hooks/useAccountantMode';
import { UserRole } from '../../../user-session/types/user';

const StyledNavLink = styled(NavLink)(({ theme }) => {
  const { padding, ...rest } = linkStyle(theme);

  return {
    ...rest,
    overflow: 'hidden',
    '&.active, &.active .MuiTypography-root': {
      '.MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
      color: theme.palette.primary.main,
    },
  };
});

const Item = styled(Stack)(({ theme }) => {
  const {
    color, justifyContent, display, width, alignItems, textDecoration,
  } = linkStyle(theme);

  return {
    color,
    justifyContent,
    display,
    width,
    alignItems,
    textDecoration,
    flexDirection: 'row',
  };
});

type Props = {
  currentWorkspace: string;
};
const WorkspaceSwitch: React.FC<Props> = ({ currentWorkspace }) => {
  const t = useTranslate();
  const {
    accountantMode,
    switchEnabled,
    pendingInvitations,
    goToWorkspace,
    switchAccountantMode,
    user,
    company,
    isHandlingACompany,
  } = useAccountantMode();

  const {
    openMenu, isOpen, anchorEl, closeMenu,
  } = useMenu();

  const menuItems = useMemo(() => {
    const myWs = user?.workspaces.find(({ roles }) => roles.find(r => r === UserRole.admin))!;
    if (accountantMode || company === myWs?.name) {
      return myWs ? [myWs] : [];
    }
    const current = user?.workspaces.find(({ name }) => name === company);
    return current ? [myWs, current] : [myWs];
  }, [accountantMode, company, user?.workspaces]);

  const toggleView = useCallback(
    (id?: string) => {
      if (id) {
        goToWorkspace(id);
      } else {
        switchAccountantMode(true);
      }
      closeMenu();
    },
    [closeMenu, goToWorkspace, switchAccountantMode],
  );

  const hideCompanyPageToAccountant = useCallback(
    e => {
      if (isHandlingACompany) {
        e.preventDefault();
      }
    },
    [isHandlingACompany],
  );

  return (
    <FeatureWrapper
      feature={Feature.COMPANY}
      fallback={
        <Item>
          <CompanyIcon />
          <Typography testId="workspace" ml={1} style={{ wordBreak: 'keep-all' }}>
            {currentWorkspace}
          </Typography>
        </Item>
      }>
      <StyledNavLink
        to={accountantMode ? 'accountant' : 'company'}
        onClick={hideCompanyPageToAccountant}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack
            direction="row"
            alignItems="center"
            style={{ overflow: 'hidden', cursor: 'pointer' }}>
            <CompanyIcon />
            <Typography testId="workspace" ml={1} noWrap>
              {accountantMode ? t('accountant.switch.area') : currentWorkspace}
            </Typography>
          </Stack>
          {switchEnabled && (
            <IconButton
              onClick={e => {
                e.preventDefault();
                openMenu(e.currentTarget);
              }}>
              <ArrowDownIcon />
            </IconButton>
          )}
        </Stack>
      </StyledNavLink>

      <Menu
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Stack spacing={1} my={1}>
          <Typography
            color="text.light"
            textTransform="uppercase"
            pl={3}
            fontWeight="bold"
            variant="body2">
            {t('menu.switch')}
          </Typography>
          <MenuItem
            variant="popup"
            icon={<ListIcon />}
            label={t('accountant.switch.area')}
            onClick={() => toggleView()}
            selected={accountantMode}
            notification={!accountantMode ? pendingInvitations.length : 0}/>

          {menuItems?.map(({ id, name, roles }) => {
            const admin = roles.find(r => r === UserRole.admin);

            return (
              <MenuItem
                variant="popup"
                key={id}
                icon={admin ? <BusinessIcon /> : <CompanyIcon />}
                label={admin ? t('accountant.switch.ownCompany') : name}
                onClick={() => toggleView(id)}
                selected={accountantMode ? false : company === name}/>
            );
          })}
        </Stack>
      </Menu>
    </FeatureWrapper>
  );
};

WorkspaceSwitch.displayName = 'WorkspaceSwitch';
export default WorkspaceSwitch;
