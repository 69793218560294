export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

type Config = {
  getToken: () => string | null;
  baseUrl: string;
  getWorkspaceId: () => string | null;
};

let config: Config = {
  getToken: () => '',
  baseUrl: '',
  getWorkspaceId: () => '',
};

export const setFetchConfig = (c: Config) => {
  config = c;
};

async function fetchWrapper<T>(
  path: string,
  method = Method.GET,
  body?: object,
  headers?: {},
  extractJson = true,
): Promise<T> {
  const token = config.getToken();

  const init = {
    method,
    headers: {
      ...(body && { 'Content-type': 'application/json; charset=UTF-8' }),
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
    credentials: 'include',
  } as RequestInit;

  let res: Response;

  const workspaceId = config.getWorkspaceId();

  const blacklist = ['/auth', '/workspaces', '/banks', '/user/me', '/invitations'];

  const match = blacklist.find(p => path.startsWith(p));
  if (!match && workspaceId) {
    path = `/workspaces/${workspaceId}${path}`;
  }

  if (body) {
    res = await fetch(config.baseUrl + path, {
      ...init,
      body: JSON.stringify({ ...body }),
    });
  } else {
    res = await fetch(config.baseUrl + path, init);
  }

  if (res.ok) {
    return extractJson ? res.json() : res;
  }

  let data;
  let message = res.statusText;
  try {
    data = await res.json();
  } catch {
    data = {};
  }

  if (data.message) {
    message = typeof data.message === 'string' ? data.message : data.message?.[0];
  }

  throw {
    code: `${res.status}`,
    message,
  };
}

const fetchWithMethods = {
  // eslint-disable-next-line max-len
  get: <T = object>(urlOrPath: string, headers?: Record<string, string>, extractJson = true) => fetchWrapper<T>(urlOrPath, Method.GET, undefined, headers, extractJson),
  // eslint-disable-next-line max-len
  post: <T = object>(
    urlOrPath: string,
    object?: any,
    headers?: Record<string, string>,
    extractJson = true,
  ) => fetchWrapper<T>(urlOrPath, Method.POST, object, headers, extractJson),
  // eslint-disable-next-line max-len
  put: <T = object>(
    urlOrPath: string,
    object?: any,
    headers?: Record<string, string>,
    extractJson = true,
  ) => fetchWrapper<T>(urlOrPath, Method.PUT, object, headers, extractJson),
  patch: <T = object>(
    urlOrPath: string,
    object?: any,
    headers?: Record<string, string>,
    extractJson = true,
  ) => fetchWrapper<T>(urlOrPath, Method.PATCH, object, headers, extractJson),
  // eslint-disable-next-line max-len
  delete: <T = object>(urlOrPath: string, headers?: Record<string, string>, extractJson = true) => fetchWrapper<T>(urlOrPath, Method.DELETE, undefined, headers, extractJson),
};

export default fetchWithMethods;
