import React from 'react';
import Dialog, { Actions } from '../Dialog/Dialog';
import Stack from '../Stack';
import Typography from '../Typography/Typography';
import { CheckIcon, ErrorIcon } from '../../icons';

type Props = {
  open: boolean;
  close: () => void;
  success: boolean;
  title: string;
  body: string;
  buttons?: Actions;
};
const FeedbackDialog: React.FC<Props> = ({
  open, success, title, body, buttons, close,
}) => (
  <Dialog open={open} mode="cta" testId="feedback-dialog" onClose={close} buttons={buttons}>
    <Stack spacing={2} alignItems="center">
      {success ? <CheckIcon fontSize="large" color="success" /> : <ErrorIcon />}
      <Typography variant="h5" fontWeight="bold" color="grey.dark">
        {title}
      </Typography>
      <Typography color="grey.dark">{body}</Typography>
    </Stack>
  </Dialog>
);

FeedbackDialog.displayName = 'FeedbackDialog';
export default FeedbackDialog;
