import { Stack, styled } from '@financial-tool/components';

export const Container = styled(Stack)(({ theme: { breakpoints } }) => ({
  height: '100vh',
  flexDirection: 'column-reverse',
  [breakpoints.up('sm')]: {
    width: '100%',
    flexDirection: 'row',
  },
}));

export const Actions = styled(Stack)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  'button:last-child': {
    marginTop: 16,
  },
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
    'button:last-child': {
      marginTop: 0,
      marginLeft: 16,
    },
  },
}));

export const ImageContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SvgContainer = styled(Stack)({
  height: '180px',
  '> svg': {
    height: '100%',
    width: 'auto',
    maxWidth: '100%',
  },
});

export const LoginContainer = styled(Stack)(({ theme: { breakpoints } }) => ({
  height: '100vh',
  [breakpoints.up('sm')]: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StyledStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  [breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));
