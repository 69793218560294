import { useCallback } from 'react';

function useOpenExternalLink() {
  const open = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  return {
    open,
  };
}

export default useOpenExternalLink;
