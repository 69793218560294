import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

type Params = {
  onDebouncedChange?: (search: string) => void;
  onClear?: () => void;
  delay?: number;
};

function useDebouncedSearchKey(params?: Params) {
  const { onDebouncedChange, onClear, delay = 500 } = params || {};

  const [keyword, setKeyword] = useState('');

  const [debounceKeyword, { isPending }] = useDebounce(keyword, delay);

  useEffect(() => {
    if (debounceKeyword) {
      onDebouncedChange?.(debounceKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceKeyword]);

  const onClearSearch = useCallback(() => {
    onClear?.();
    setKeyword('');
  }, [onClear]);

  return {
    keyword,
    onClearSearch,
    setKeyword,
    debounceKeyword,
    isPending,
  };
}

export default useDebouncedSearchKey;
