import React from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import Dialog, { Actions } from '../Dialog/Dialog';

type Props = {
  open: boolean;
  close: () => void;
  onConfirm: (e: React.MouseEvent<HTMLElement>) => void;
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  customButtons?: Actions;
  noAutoClose?: boolean;
  loading?: boolean;
};

const ConfirmationDialog: React.FC<Props> = ({
  title,
  content,
  noAutoClose = false,
  open,
  close,
  onConfirm,
  customButtons,
  loading,
}) => {
  const handleClose = () => {
    close();
  };

  const handleConfirmation = (e: React.MouseEvent<HTMLElement>) => {
    onConfirm(e);
    if (!noAutoClose) close();
  };

  const t = useTranslate();

  const dialogContent = typeof content === 'string' ? undefined : content;
  const dialogBody = typeof content === 'string' ? content : undefined;

  const defaultButtons: Actions = {
    primary: {
      testId: 'confirm-button',
      onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => handleConfirmation(e),
      text: t('buttons.confirm'),
      loading,
    },
    tertiary: {
      onClick: handleClose,
    },
  };

  const buttons = customButtons ?? defaultButtons;

  return (
    <Dialog
      testId="confirmation-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      title={title}
      body={dialogBody}
      aria-describedby="alert-dialog-description"
      buttons={buttons}>
      {dialogContent}
    </Dialog>
  );
};

ConfirmationDialog.displayName = 'ConfirmationDialog';
export default ConfirmationDialog;
