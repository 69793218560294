import { grey } from '@mui/material/colors';
import Grid from '../Grid';
import { styled } from '../_styles';

type Props = { auto?: string; position?: number };

const ColorAlternateRow = styled(Grid)<Props>(({ auto, position }) => ({
  borderRadius: '20px',
  ...(auto
    ? {
      '&:nth-of-type(odd)': {
        backgroundColor: grey[100],
      },
    }
    : {}),
  ...(!auto && position! % 2 === 0
    ? {
      backgroundColor: grey[100],
    }
    : {}),
}));

export default ColorAlternateRow;
