import React from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import {
  styled, Stack, Typography, Tooltip,
} from '@financial-tool/components';
import { useEnvVars } from '@xtreamsrl/react-config';
import { InfoIcon } from '@financial-tool/icons';
import { useBreakpoints } from '@financial-tool/hooks';

const Banner = styled(Stack)(({ theme: { palette } }) => ({
  color: palette.secondary.main,
  backgroundColor: palette.secondary.superLight,
  padding: '8px 16px',
  borderRadius: 8,
}));

const DemoBanner: React.VFC = () => {
  const t = useTranslate();
  const { isMobile } = useBreakpoints();
  const { demo } = useEnvVars();

  return demo ? (
    <Tooltip disabled={!isMobile} text={t('general.demo.banner')}>
      <Banner direction="row" spacing={2} justifyContent="center">
        <InfoIcon color="info" />
        <Typography noWrap>
          {isMobile ? t('general.demo.label') : t('general.demo.banner')}
        </Typography>
      </Banner>
    </Tooltip>
  ) : (
    <></>
  );
};

DemoBanner.displayName = 'DemoBanner';
export default DemoBanner;
