import React from 'react';
import MUIListItem, { ListItemProps } from '@mui/material/ListItem';
import MUIListItemButton from '@mui/material/ListItemButton';
import MUIListItemAvatar from '@mui/material/ListItemAvatar';
import MUIListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import Box from '../Box';
import Avatar from '../Avatar';

const ellipsedTextProp = {
  sx: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} as ListItemTextProps['primaryTypographyProps'];

type Props = {
  style?: ListItemProps['style'];
  onClick?: ListItemProps['onClick'];
  button?: boolean;
  avatar?: {
    src: string;
    alt?: string;
  };
  text: string;
  ellipse?: boolean;
};

const ListItem: React.FC<Props> = ({
  style, onClick, button, avatar, text,
}) => {
  const Wrapper = button ? <MUIListItemButton sx={{ maxWidth: '100%' }} /> : <Box />;

  return (
    <MUIListItem alignItems="flex-start" style={style} onClick={onClick}>
      {React.cloneElement(Wrapper, {
        children: (
          <>
            {avatar && (
              <MUIListItemAvatar>
                <Avatar alt={avatar.alt} src={avatar.src} />
              </MUIListItemAvatar>
            )}
            <MUIListItemText primaryTypographyProps={ellipsedTextProp} primary={text} />
          </>
        ),
      })}
    </MUIListItem>
  );
};

ListItem.displayName = 'ListItem';

export default ListItem;
