import {
  createEntityAdapter, createSelector, createSlice, EntityState,
} from '@reduxjs/toolkit';
import { compareDates } from '@financial-tool/utils';
import { RootState } from '@financial-tool/store';
import { Expense } from '../types/expense';
import loadExpenses from '../asyncHandlers/getExpenses';
import createExpense from '../asyncHandlers/createExpense';
import deleteExpense from '../asyncHandlers/deleteExpense';
import updateExpense from '../asyncHandlers/updateExpense';
import createNewExpenseVersion from '../asyncHandlers/createNewExpenseVersion';

const expensesAdapter = createEntityAdapter<Expense>({});

type State = {
  expenses: EntityState<Expense>;
};

const expensesSlice = createSlice({
  name: 'expenses',
  initialState: {
    expenses: expensesAdapter.getInitialState(),
  } as State,
  extraReducers: builder => {
    builder
      .addCase(loadExpenses.fulfilled, (state, action) => {
        expensesAdapter.setAll(state.expenses, action.payload);
      })
      .addCase(createExpense.fulfilled, (state, action) => {
        expensesAdapter.setOne(state.expenses, action.payload);
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        expensesAdapter.removeOne(state.expenses, action.payload);
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        expensesAdapter.updateOne(state.expenses, action.payload);
      })
      .addCase(createNewExpenseVersion.fulfilled, (state, action) => {
        try {
          const { startDate, seriesId } = action.payload.res;
          expensesAdapter.updateOne(state.expenses, {
            id: action.payload.id,
            changes: {
              endDate: new Date(startDate).subtract(1, 'd').toString(),
              seriesId,
            },
          });
          expensesAdapter.setOne(state.expenses, {
            ...action.payload.res,
            seriesId,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      });
  },
  reducers: {},
});
export default expensesSlice.reducer;
const selectExpenses = (state: RootState) => state.expenses.expenses;
export const selectors = expensesAdapter.getSelectors(selectExpenses);
export const selectExpesesSeries = createSelector(selectors.selectAll, expenses => {
  const seriesMap = new Map<string, { expenses: Expense[]; current?: Expense }>();

  expenses.forEach(expense => {
    if (expense.seriesId) {
      const seriesData = seriesMap.get(expense.seriesId);

      if (seriesData) {
        const newSeries = [...seriesData.expenses, expense];
        seriesMap.set(expense.seriesId, {
          expenses: newSeries,
          current: seriesData.current,
        });
      } else {
        seriesMap.set(expense.seriesId, {
          expenses: [expense],
          current: expense,
        });
      }
    }
  });

  const today = new Date().format('YYYY-MM-DD');

  seriesMap.forEach(value => {
    const newCurrent = value.expenses.find(expense => {
      const startDate = new Date(expense.startDate).format('YYYY-MM-DD');
      let endDate;
      if (expense.endDate) {
        endDate = new Date(expense.endDate).format('YYYY-MM-DD');
      }

      if (today >= startDate && endDate && today <= endDate) {
        return expense;
      }
      if (endDate === undefined && today >= startDate) {
        return expense;
      }
      return undefined;
    });
    value.expenses.sort(compareDates);
    value.current = newCurrent;
  });
  const idsFound = new Set<string>();
  // eslint-disable-next-line array-callback-return,consistent-return
  const expensesWithSeries = expenses.map(expense => {
    if (!expense.seriesId) {
      return expense;
    }
    if (!idsFound.has(expense.seriesId)) {
      idsFound.add(expense.seriesId);
      if (seriesMap.get(expense.seriesId)!.current) {
        return seriesMap.get(expense.seriesId)!.current;
      }
      return expense;
    }
  });

  return {
    series: seriesMap,
    expensesWithSeries: expensesWithSeries.filter(e => e !== undefined) as Expense[],
  };
});
