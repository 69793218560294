import React, { useCallback } from 'react';
import { CashooLogoIcon } from '@financial-tool/icons';
import { Button, styled } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useEnvVars } from '@xtreamsrl/react-config';
import { DemoEvents, useTracker } from '../../analytics/demo';

const RegisterButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'collapsed',
})<{ collapsed: boolean }>(({ collapsed }) => ({
  ...(collapsed && {
    div: {
      display: 'none',
    },
    padding: 8,
    minWidth: 'unset',
    '.MuiButton-startIcon': {
      margin: 0,
    },
  }),
  alignSelf: 'center',
  svg: {
    width: '1em',
    height: '1em',
  },
}));

type Props = {
  collapsed?: boolean;
  withIcon?: boolean;
  onClick?: () => void;
};
const DemoRegisterButton: React.FC<Props> = ({ collapsed = false, withIcon = false, onClick }) => {
  const t = useTranslate();
  const { productUrl } = useEnvVars();
  const { track } = useTracker();

  const signUpForProduct = useCallback(() => {
    track(DemoEvents.DemoSignupClick);
    window.location.replace(productUrl);
  }, [productUrl, track]);

  return (
    <RegisterButton
      collapsed={collapsed}
      startIcon={withIcon ? <CashooLogoIcon /> : undefined}
      onClick={onClick ?? signUpForProduct}
      variant="contained"
      size="large">
      {t('splash.signup')}
    </RegisterButton>
  );
};

DemoRegisterButton.displayName = 'DemoRegisterButton';
export default DemoRegisterButton;
