import React from 'react';
import MUITypography, { TypographyProps } from '@mui/material/Typography';

type Props = {
  testId?: string;
  className?: string;
  variant?: TypographyProps['variant'];
  fontSize?: TypographyProps['fontSize'];
  color?: TypographyProps['color'];
  fontWeight?: TypographyProps['fontWeight'];
  textTransform?: TypographyProps['textTransform'];
  textAlign?: TypographyProps['textAlign'];
  onClick?: TypographyProps['onClick'];
  noWrap?: TypographyProps['noWrap'];
  whiteSpace?: TypographyProps['whiteSpace'];
  mt?: TypographyProps['mt'];
  my?: TypographyProps['my'];
  ml?: TypographyProps['ml'];
  p?: TypographyProps['p'];
  pt?: TypographyProps['pt'];
  pb?: TypographyProps['pb'];
  px?: TypographyProps['px'];
  py?: TypographyProps['py'];
  pl?: TypographyProps['pl'];
  pr?: TypographyProps['pr'];
  style?: TypographyProps['sx'];
  // @ts-ignore
  component?: TypographyProps['component'];
  flex?: TypographyProps['flex'];
  textOverflow?: TypographyProps['textOverflow'];
};

const Typography: React.FC<Props> = React.forwardRef<HTMLSpanElement, Props>(
  ({ style, testId, ...props }, ref) => (
    <MUITypography data-test={testId} sx={style} {...props} ref={ref} />
  ),
);

Typography.displayName = 'Typography';

export default Typography;
