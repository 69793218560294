import { Transaction } from './transaction';

export interface TransactionFiltersParams {
  keyword: string;
  bank: 'all' | string;
  transactionType?: 'all' | TransactionType;
  minAmount?: number;
  maxAmount?: number;
  startDate: string;
  endDate: string;
}

export interface TransactionSortingParams {
  sortBy: keyof Transaction;
  direction: 'asc' | 'desc';
}

export interface TransactionPaginationParams {
  page: number;
  pageSize: number;
  hasNext: boolean;
}

export enum TransactionType {
  Income = 'INCOME',
  Expense = 'EXPENSE',
}
