import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEnvVars } from '@xtreamsrl/react-config';
import { useAuth0 } from '@auth0/auth0-react';
import {
  logout as logoutAction,
  selectIsAuthenticated,
  selectIsUserLoading,
  selectNeedSignupAuthenticated,
  setAuthLoading,
  setAuthToken,
} from '../slice/userSlice';
import { AuthEvents, useTracker } from '../analytics';

export function useAuthentication() {
  const { auth0, appUrl } = useEnvVars();
  const {
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout: logoutAuth0,
    isAuthenticated: authSuccess,
    error,
    user,
  } = useAuth0();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isUserLoading = useSelector(selectIsUserLoading);
  const needSignup = useSelector(selectNeedSignupAuthenticated);

  const { track, forgetUser } = useTracker();

  const dispatch = useDispatch();

  const restore = useCallback(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: auth0.audience,
        });
        dispatch(setAuthToken(token));
      } catch (e) {
        dispatch(setAuthLoading(false));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAccessTokenSilently]);

  const login = useCallback(
    (signup?: true) => {
      loginWithRedirect({
        audience: auth0.audience,
        screen_hint: signup ? 'signup' : undefined,
      }).then(() => {
        restore();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginWithRedirect, restore],
  );

  const logout = useCallback(() => {
    localStorage.removeItem('workspace');
    dispatch(logoutAction());
    track(AuthEvents.LogOut);
    forgetUser();
    return logoutAuth0({
      returnTo: appUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutAuth0]);

  const needEmailVerification = error?.message === 'email_verification_required';

  return {
    userEmail: user?.email,
    auth0Id: user?.sub,
    isAuthenticated,
    isLoading: isLoading || isUserLoading,
    isAuthLoading: isLoading,
    authSuccess,
    login: () => login(),
    restore,
    needSignup,
    logout,
    signup: () => login(),
    needEmailVerification,
  };
}
