import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

type Props = {
  src: string;
  width?: BoxProps['width'];
  height?: BoxProps['height'];
  p?: BoxProps['p'];
  className?: string;
};

const Image: React.VFC<Props> = ({
  src, width, height, p, className,
}) => (
  <Box component="img" src={src} width={width} height={height} p={p} className={className} />
);

Image.displayName = 'Image';

export default Image;
