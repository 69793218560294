import React from 'react';
import TabPanel from '@mui/lab/TabPanel';

type Props = {
  value: string;
};

const TabContent: React.FC<Props> = ({ value, children }) => (
  <TabPanel
    value={value}
    children={children}
    sx={{
      p: 0,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}/>
);

TabContent.displayName = 'TabContent';

export default TabContent;
