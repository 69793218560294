import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import revokeInvitationAPI from '../apis/revokeInvitation';

const {
  hook: useRevokeInvitation,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invitation/revoke', async (invitationId: string) => {
  await revokeInvitationAPI(invitationId);
  return {
    invitationId,
  };
});

export { useRevokeInvitation };

export default {
  fulfilled,
  rejected,
  pending,
};
