import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import updatePlanningrowNameAPI from '../apis/updatePlanningRowName';
import { PlanningCategory } from '../types';

const { start, fulfilled, pending } = createAsyncTaskHandlerWithActions(
  'planning/updateName',
  (row: Pick<PlanningCategory, 'categoryId' | 'name'>) => updatePlanningrowNameAPI(row),
);

export default {
  thunk: start,
  fulfilled,
  pending,
};
