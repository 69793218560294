import React, { useEffect, useState } from 'react';
import { AbsoluteBox, Button, Stack } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { createPortal } from 'react-dom';
import { MODAL_PORTAL_ID } from '../types';

type Props = {
  skip: () => void;
  goNext: () => void;
  skipNotAllowed: boolean;
  isEnd: boolean;
  disabled?: boolean;
};
const WelcomeModalControls: React.FC<Props> = ({
  skip,
  goNext,
  skipNotAllowed,
  isEnd,
  disabled = false,
}) => {
  const t = useTranslate();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return createPortal(
    <AbsoluteBox left={0} bottom={-4} zIndex={1} right={0}>
      <Stack direction="row" width="100%" justifyContent="flex-end" pb={2} pt={0} spacing={2}>
        {!skipNotAllowed && (
          <Button
            testId="welcome-skip"
            size="small"
            variant="text"
            color="secondary"
            onClick={() => {
              skip();
            }}>
            {t('welcome.skip')}
          </Button>
        )}
        <Button
          testId="welcome-next"
          size="small"
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={goNext}>
          {t(isEnd ? 'welcome.start' : 'welcome.next')}
        </Button>
      </Stack>
    </AbsoluteBox>,
    document.getElementById(MODAL_PORTAL_ID)!,
  );
};

WelcomeModalControls.displayName = 'WelcomeModalControls';
export default WelcomeModalControls;
