/* eslint-disable */
import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme } from '@mui/material/styles';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import TextField from '../TextField';
import { ArrowDownIcon } from '@financial-tool/icons';
import { ClickAwayListener } from '@mui/material';
import Stack from '../Stack/Stack';

type Props<T> = {
  label?: string;
  name: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  options: { key: string; label: string }[] | string[];
  inputRef?: React.Ref<any>;
  value?: T;
  searchable?: boolean;
  required?: boolean;
  onChange?: (event: SelectChangeEvent<T>) => void;
  style?: React.CSSProperties;
  className?: string;
  noHelperText?: boolean;
  variant?: SelectProps['variant'];
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  // eslint-disable-next-line no-prototype-builtins
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  (props, ref) => {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
      (item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild) => {
      // eslint-disable-next-line no-prototype-builtins
      if (child.hasOwnProperty('group')) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    // todo refatcor using virtualized list in components
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

function SelectField<T>({
  label,
  name,
  error,
  disabled,
  options,
  inputRef,
  helperText,
  value,
  onChange,
  searchable,
  required = false,
  className,
  noHelperText = false,
  variant,
}: Props<T>) {
  const [focusLabel, setFocusLabel] = useState(false);
  const labelStyle = focusLabel ? 'secondary' : undefined;

  if (searchable) {
    const realValue = value
      ? // @ts-ignore
        options.find(o => (typeof o === 'string' ? value === o : value === o.key))
      : undefined;
    return (
      <Autocomplete
        data-test={`select-${name}`}
        disableListWrap
        popupIcon={<ArrowDownIcon />}
        ListboxComponent={ListboxComponent}
        options={options as any}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            required={required}
            placeholder={label}
            error={error}
            helperText={helperText}
            label={label}
          />
        )}
        renderOption={(props, option: any) => [
          props,
          typeof option === 'string' ? option : option.label,
        ]}
        disabled={disabled}
        value={realValue}
        getOptionLabel={item => (typeof item === 'string' ? item : item.label)}
        onChange={(event, value1) => {
          if (!value1) {
            event.target = { value: '' } as any;
          } else {
            event.target = { value: typeof value1 === 'string' ? value1 : value1.key } as any;
          }

          onChange?.(event as any);
        }}
      />
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setFocusLabel(false)}>
      <Stack>
        <Stack spacing={1}>
          {label && (
            <InputLabel htmlFor={name} color={error ? 'error' : labelStyle} required={required}>
              {label}
            </InputLabel>
          )}
          <Select
            id={name}
            data-test={`select-${name}`}
            onFocus={() => setFocusLabel(true)}
            variant={variant}
            error={error}
            disabled={disabled}
            required={required}
            inputRef={inputRef}
            IconComponent={ArrowDownIcon}
            value={value}
            onChange={onChange}
            className={className}
          >
            {options.map(option =>
              typeof option === 'string' ? (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ) : (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ),
            )}
          </Select>
        </Stack>
        {!noHelperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </Stack>
    </ClickAwayListener>
  );
}

SelectField.displayName = 'SelectField';

export default SelectField;
