import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import deletePlanningRowAPI from '../apis/deletePlanningRow';

const {
  start, fulfilled, rejected, pending,
} = createAsyncTaskHandlerWithActions(
  'planning/delete',
  (id: string) => deletePlanningRowAPI(id),
);

export default {
  thunk: start,
  fulfilled,
  rejected,
  pending,
};
