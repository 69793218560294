import React from 'react';
import MUICardActions from '@mui/material/CardActions';

type Props = {};

const CardActions: React.FC<Props> = props => <MUICardActions {...props} />;

CardActions.displayName = 'CardActions';

export default CardActions;
