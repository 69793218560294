import React from 'react';
import MuiLinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { styled } from '../_styles';

const BorderLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 4,
  backgroundColor: theme.palette.grey.superLight,
}));

type Props = {
  variant?: 'determinate';
  progress?: number;
  color?: LinearProgressProps['color'];
};

const LinearProgress: React.FC<Props> = ({ variant, progress, color }) => (
  <Box sx={{ width: '100%' }}>
    <BorderLinearProgress variant={variant} value={progress} color={color} />
  </Box>
);

LinearProgress.displayName = 'LinearProgress';

export default LinearProgress;
