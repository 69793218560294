import { augmentDate } from './date';

function augment() {
  augmentDate();
}

augment();

// eslint-disable-next-line func-names
export default function () {
  augment();
}
