import React from 'react';
import { useController } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models';
import { useTranslate } from '@xtreamsrl/react-i18n';
import DatePicker from '../../components/DatePicker';

type Props = {
  name: string;
  label: string;
  views: CalendarPickerView[];
  inputFormat: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
  placeholder?: string;
  allowNullDates?: boolean;
  disableManualInput?: boolean;
};
const FormDatePicker: React.FC<Props> = ({
  label,
  name,
  views,
  inputFormat,
  disabled = false,
  required = false,
  helperText = ' ',
  placeholder,
  allowNullDates,
  disableManualInput,
}) => {
  const translate = useTranslate();
  const {
    field: { ref, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
  });
  return (
    <DatePicker
      disabled={disabled}
      required={required}
      error={invalid}
      allowNullDates={allowNullDates}
      disableManualInput={disableManualInput}
      {...inputProps}
      views={views}
      placeholder={placeholder}
      name={name}
      label={label}
      inputFormat={inputFormat}
      helperText={invalid ? translate(error?.message!) : helperText}/>
  );
};

FormDatePicker.displayName = 'FormDatePicker';
export default FormDatePicker;
