import React from 'react';

export type Commands = {
  skip: () => void;
  goNext: () => void;
  skipNotAllowed: boolean;
  isEnd: boolean;
  disabled?: boolean;
  hideControls?: boolean;
};

export type Slide = {
  image: React.ReactNode;
  titleKey: string;
  contentKey: string;
  id: string;
  component?: undefined;
};
export type CustomSlide = { id: string; component: React.FC<any> };
export type Slides = (Slide | CustomSlide)[];
export const MODAL_PORTAL_ID = 'modal-controls-portal';
