import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getWorkspaceUsersAPI from '../apis/getWorkspaceUsers';

const {
  hook: useGetWorkspaceUsers,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('workspaces/users', async (workspaceId: string) => getWorkspaceUsersAPI(workspaceId));

export { useGetWorkspaceUsers };

export default {
  fulfilled,
  rejected,
  pending,
};
