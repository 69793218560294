import React, { useState } from 'react';
import { NumberFormatInput } from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import Stack from '../Stack/Stack';

type Tuple = [number | undefined, number | undefined];
type Props = {
  testId?: string;
  initalState?: Tuple;
  onChange: (range: Tuple) => void;
};
const AmountRangeFilter: React.FC<Props> = ({ testId, initalState, onChange }) => {
  const t = useTranslate();
  const [range, setRange] = useState<Tuple>(initalState ?? [undefined, undefined]);

  const handleInputChange = (value: number | undefined, i: 0 | 1) => {
    setRange(prev => (i === 0 ? [value, prev[1]] : [prev[0], value]));
    onChange(i === 0 ? [value, range[1]] : [range[0], value]);
  };

  return (
    <Stack testId={testId} spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <NumberFormatInput
          onValueChange={values => handleInputChange(values.floatValue, 0)}
          onFieldCleared={() => handleInputChange(undefined, 0)}
          value={range[0]}
          decimalScale={0}
          hideCurrency
          placeholder="0"
          label={t('labels.minAmount')}/>

        <NumberFormatInput
          onValueChange={values => handleInputChange(values.floatValue, 1)}
          onFieldCleared={() => handleInputChange(undefined, 1)}
          value={range[1]}
          decimalScale={0}
          hideCurrency
          placeholder="100.000+"
          label={t('labels.maxAmount')}/>
      </Stack>
    </Stack>
  );
};

AmountRangeFilter.displayName = 'AmountRangeFilter';
export default AmountRangeFilter;
