import React from 'react';
import MUIDrawer, { DrawerProps } from '@mui/material/Drawer';

type Props = {
  variant?: DrawerProps['variant'];
  anchor: DrawerProps['anchor'];
  open: DrawerProps['open'];
  onBackdropClick?: DrawerProps['onBackdropClick'];
  onClose?: DrawerProps['onClose'];
};

const Drawer: React.FC<Props> = props => <MUIDrawer {...props} />;

Drawer.displayName = 'Drawer';
Drawer.defaultProps = { variant: 'temporary' };

export default Drawer;
