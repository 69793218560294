import React from 'react';
import {
  Box,
  Button,
  ProductImg,
  Logo,
  Stack,
  Typography,
  LoadingOverlay,
  HideOnDevice,
} from '@financial-tool/components';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useAuthentication } from '../../hooks/useAuthentication';
import SignupDialog from '../../components/SignupDialog';
import EmailVerificationNeededDialog from '../../components/EmailVerificationNeededDialog';
import { ReactComponent as BankAccounts } from '../../../dashboard/components/WelcomeModal/bank-accounts.svg';
import {
  Container,
  LoginContainer,
  StyledStack,
  Actions,
  ImageContainer,
  SvgContainer,
} from './SplashScreen.styled';

type Props = {
  loading?: boolean;
};
const SplashScreen: React.FC<Props> = ({ loading = false }) => {
  const t = useTranslate();
  const {
    login, isLoading, needSignup, logout, signup, needEmailVerification,
  } = useAuthentication();

  return (
    <Container>
      <LoginContainer>
        <StyledStack p={4} spacing={4}>
          <Stack justifyContent="center">
            <Box>
              <Logo />
            </Box>
            <HideOnDevice device="desktop">
              <SvgContainer>
                <BankAccounts />
              </SvgContainer>
            </HideOnDevice>
          </Stack>

          <Stack>
            <Typography variant="h3" fontWeight="bold" mt={3}>
              {t('splash.title')}
            </Typography>
            <Typography variant="h6" my={2}>
              {t('splash.subtitle')}
            </Typography>

            <Actions>
              <Button onClick={login} variant="contained">
                {t('splash.login')}
              </Button>
              <Button onClick={signup} variant="outlined" color="secondary">
                {t('splash.signup')}
              </Button>
            </Actions>
            <Typography mt={1} color="text.info" textAlign="left">
              {t('splash.signupCta')}
            </Typography>
          </Stack>
        </StyledStack>
      </LoginContainer>

      <ImageContainer display={{ xs: 'none', sm: 'flex' }}>
        <ProductImg p={2} width="100%" />
      </ImageContainer>

      {(isLoading || loading) && <LoadingOverlay />}
      {needSignup && <SignupDialog onClose={logout} />}
      {needEmailVerification && <EmailVerificationNeededDialog onClose={logout} />}
    </Container>
  );
};

SplashScreen.displayName = 'SplashScreen';

export default SplashScreen;
