import React from 'react';
import Stack from '../Stack';
import { styled } from '../_styles';

type Props = {
  noSeparator?: boolean;
  height?: string;
  clickable?: boolean;
};

const SeparatorRow = styled(
  (props: React.ComponentProps<typeof Stack>) => <Stack direction="row" {...props} />,
  { shouldForwardProp: name => !['noSeparator', 'height', 'clickable'].includes(name as string) },
)<Props>(({
  noSeparator, theme, height, clickable,
}) => ({
  ...(noSeparator
    ? {}
    : {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      height,
    }),
  ...(clickable
    ? {
      ':hover, :hover *': {
        backgroundColor: theme.palette.grey[100],
        cursor: 'pointer',
      },
    }
    : {}),
}));

SeparatorRow.displayName = 'SeparatorRow';

export default SeparatorRow;
