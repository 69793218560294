import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import removeUserFromWorkspaceAPI from '../apis/removeUserFromWorkspace';

const {
  hook: useRemoveUserFromWorkspace,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions(
  'workspaces/users/remove',
  async (data: { workspaceId: string; userId: string }) => removeUserFromWorkspaceAPI(data),
);

export { useRemoveUserFromWorkspace };

export default {
  fulfilled,
  rejected,
  pending,
};
