import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getAccountantClientsAPI from '../apis/getAccountantClients';

const {
  hook: useGetAccountantClients,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('accountant/clients', () => getAccountantClientsAPI());

export { useGetAccountantClients };

export default {
  fulfilled,
  rejected,
  pending,
};
