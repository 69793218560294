/* eslint-disable max-len */
import {
  createSelector, createSlice, isAnyOf, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import {
  AddRowFormValues, PlanningEntryParams, PlanningFlow, PlanningFrequency,
} from './types';
import { computeMonthsToUpdate, mapResponseToState, planningInitialState } from './utils';
import createPlanningRow from './asyncHandlers/createPlanningRow';
import getPlanning from './asyncHandlers/getPlanning';
import deletePlanningRow from './asyncHandlers/deletePlanningRow';
import updatePlanningRowName from './asyncHandlers/updatePlanningRowName';
import updatePlanning from './asyncHandlers/updatePlanning';
import { NEW_ROW_ID } from './constants';

const slice = createSlice({
  name: 'planning',
  initialState: planningInitialState,
  reducers: {
    updateEntries: (
      state,
      action: PayloadAction<AddRowFormValues & { flow: 'in' | 'out'; categoryId: string }>,
    ) => {
      const {
        amount, flow, endDate, date, frequency, categoryId,
      } = action.payload;
      const monthsToUpdate = computeMonthsToUpdate({ endDate, date, frequency });

      const category = state[flow].categories.find(({ categoryId: cid }) => cid === categoryId)!;

      Object.keys(category.values).forEach(month => {
        const isMonthInPeriod = new Date(month).isBetween(
          new Date(monthsToUpdate[0]),
          new Date(monthsToUpdate[monthsToUpdate.length - 1]),
          true,
        );
        if (isMonthInPeriod) {
          if (monthsToUpdate.includes(month)) {
            const currentMonth = category.values[month];
            const signedValue = currentMonth.amount - amount;

            currentMonth.frequency = amount === 0 ? PlanningFrequency.None : frequency;
            currentMonth.amount = amount;
            state[flow].totals[month] -= signedValue;
          } else {
            const currentMonth = category.values[month];
            state[flow].totals[month] -= currentMonth.amount;
            currentMonth.frequency = PlanningFrequency.None;
            currentMonth.amount = 0;
          }
        }
      });
    },
    updateCategoryName: (
      state,
      action: PayloadAction<{ categoryId: string; name: string; flow: 'in' | 'out' }>,
    ) => {
      const { categoryId, flow, name } = action.payload;
      const category = state[flow].categories.find(c => c.categoryId === categoryId)!;
      category.name = name;
    },
    createRow: (state, action: PayloadAction<AddRowFormValues & { flow: 'in' | 'out' }>) => {
      const { amount, flow } = action.payload;

      const rowValues = new Array(16).fill(null).reduce((values, _, i) => {
        const monthKey = new Date().add(i - 3, 'M').format('YYYY-MM');
        return {
          ...values,
          [monthKey]: {
            amount,
            currency: 'EUR',
            frequency: PlanningFrequency.Spot,
          },
        };
      }, {});

      state[flow].categories.push({
        name: '',
        categoryId: NEW_ROW_ID,
        values: rowValues,
      });
    },
    deleteRow: (state, action: PayloadAction<{ flow: 'in' | 'out'; categoryId: string }>) => {
      const { categoryId, flow } = action.payload;
      const { values } = state[flow].categories.find(({ categoryId: cid }) => cid === categoryId)!;

      state[flow].categories = state[flow].categories.filter(
        ({ categoryId: cid }) => cid !== categoryId,
      );
      Object.entries(values).forEach(([month, { amount }]) => {
        state[flow].totals[month] -= amount;
      });
    },
    setLoading: (
      state,
      action: PayloadAction<{ flow: 'in' | 'out'; categoryId: string; loading?: boolean }>,
    ) => {
      const { categoryId, flow, loading } = action.payload;
      const category = state[flow].categories.find(c => c.categoryId === categoryId)!;
      category.loading = loading;
    },
    setError: (state, { payload: error }: PayloadAction<boolean>) => {
      state.error = error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createPlanningRow.fulfilled, (state, action) => {
        const { id, direction, name } = action.payload;
        const flow = direction === PlanningFlow.Inflow ? 'in' : 'out';
        const category = state[flow].categories.find(
          ({ name: rowName, categoryId: cid }) => rowName === name && cid === NEW_ROW_ID,
        )!;
        category.categoryId = id;
        category.loading = undefined;
      })
      .addCase(createPlanningRow.pending, (state, action) => {
        const { direction, name } = action.meta.arg;
        const flow = direction === PlanningFlow.Inflow ? 'in' : 'out';
        const category = state[flow].categories.find(
          ({ name: rowName, categoryId: cid }) => rowName === name && cid === NEW_ROW_ID,
        )!;
        category.loading = true;
      })
      .addCase(getPlanning.fulfilled, (state, action) => {
        const rows = action.payload;
        state.in = mapResponseToState(rows, PlanningFlow.Inflow);
        state.out = mapResponseToState(rows, PlanningFlow.Outflow);
      })
      .addMatcher(
        isAnyOf(
          createPlanningRow.rejected,
          deletePlanningRow.thunk.rejected,
          updatePlanningRowName.thunk.rejected,
          updatePlanning.thunk.rejected,
        ),
        state => {
          state.error = true;
        },
      );
  },
});

export default slice.reducer;
export const {
  createRow, updateEntries, updateCategoryName, deleteRow, setLoading, setError,
} = slice.actions;
const selectPlanning = (state: RootState) => state.planning;
export const selectPlanningError = createSelector(selectPlanning, planning => planning.error);
export const selectIsPlanningEmpty = createSelector(
  selectPlanning,
  planning => planning.in.categories.length === 0 && planning.out.categories.length === 0,
);
export const selectInflowCategoriesData = createSelector(selectPlanning, planning => planning.in.categories.map(({ categoryId, values, loading }) => ({
  categoryId,
  values,
  loading,
})));
export const selectOutflowCategoriesData = createSelector(selectPlanning, planning => planning.out.categories.map(({ categoryId, values, loading }) => ({
  categoryId,
  values,
  loading,
})));

export const selectInflowCategories = createSelector(selectPlanning, planning => planning.in.categories.map(({ categoryId, name }) => ({
  categoryId,
  name,
})));

export const selectOutflowCategories = createSelector(selectPlanning, planning => planning.out.categories.map(({ categoryId, name }) => ({
  categoryId,
  name,
})));
export const selectInflowTotals = createSelector(selectPlanning, planning => planning.in.totals);
export const selectOutflowTotals = createSelector(selectPlanning, planning => planning.out.totals);
export const selectTotals = createSelector(
  selectPlanning,
  (state: RootState, flow: 'in' | 'out') => flow,
  (planning, flow) => planning[flow].totals,
);
export const selectCell = createSelector(
  selectPlanning,
  (state: RootState, params: Omit<PlanningEntryParams, 'value'>) => params,
  (planning, { categoryId, flow, month }) => planning[flow].categories.find(c => c.categoryId === categoryId)!.values[month],
);
export const selectCategoryName = createSelector(
  selectPlanning,
  (state: RootState, params: Omit<PlanningEntryParams, 'value' | 'month'>) => params,
  (planning, { categoryId, flow }) => planning[flow].categories.find(c => c.categoryId === categoryId)!.name,
);
export const selectCategorValues = createSelector(
  selectPlanning,
  (state: RootState, params: Omit<PlanningEntryParams, 'value' | 'month'>) => params,
  (planning, { categoryId, flow }) => planning[flow].categories.find(c => c.categoryId === categoryId)!.values,
);
export const selectEmptyRow = createSelector(
  selectPlanning,
  (state: RootState, flow: 'in' | 'out') => flow,
  (planning, flow) => planning[flow].categories.find(({ name }) => name === ''),
);
