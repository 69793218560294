import React from 'react';
import MUIGrid, { GridProps } from '@mui/material/Grid';

type Props = {
  testId?: string;
  className?: string;
  item?: boolean;
  container?: boolean;
  wrap?: GridProps['wrap'];
  flexGrow?: GridProps['flexGrow'];
  alignItems?: GridProps['alignItems'];
  justifyContent?: GridProps['justifyContent'];
  spacing?: GridProps['spacing'];
  rowSpacing?: GridProps['rowSpacing'];
  columnSpacing?: GridProps['columnSpacing'];
  width?: GridProps['width'];
  height?: GridProps['height'];
  p?: GridProps['p'];
  px?: GridProps['px'];
  py?: GridProps['py'];
  pr?: GridProps['pr'];
  xs?: GridProps['xs'];
  md?: GridProps['md'];
  sm?: GridProps['sm'];
  lg?: GridProps['lg'];
  style?: GridProps['sx'];
  mt?: GridProps['mt'];
  display?: GridProps['display'];
  direction?: GridProps['direction'];
  onClick?: GridProps['onClick'];
};

const Grid: React.FC<Props> = ({ testId, style, ...props }) => (
  <MUIGrid data-test={testId} {...props} sx={style} />
);

Grid.displayName = 'Grid';

export default Grid;
