import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { CashFlowBody } from '../types/cashFlow';
import getCashFlowAPI from '../apis/getCashFlow';

const {
  hook: useGetCashflow,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('cashflow/get', (cashflow: CashFlowBody) => getCashFlowAPI(cashflow));

export { useGetCashflow };

export default {
  fulfilled,
  rejected,
  pending,
};
