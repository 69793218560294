/* eslint-disable max-len */
import React, {
  MouseEvent, useCallback, useEffect, useMemo,
} from 'react';
import {
  CellProps, Column, Row, SortingRule, useSortBy, useTable,
} from 'react-table';
import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import {
  AbsoluteBox,
  SeparatorRow,
  Stack,
  useColors,
  Typography,
} from '@financial-tool/components';
import { ArrowDownIcon, ArrowUpIcon } from '@financial-tool/icons';
import { useBreakpoints } from '@financial-tool/hooks';
import {
  TableBody, TableCell, TableContainer, TableHeader,
} from './Table.styled';
import { useFlexLayout } from './customLayout';
import VirtualizedList, { RenderRowProps } from '../VirtualizedList';
import LinearProgressBar from '../LinearProgressBar';
import Box from '../Box';
import { ColumnsDefinition } from './types';
import { styled } from '../_styles';
import IconButton from '../IconButton';

const ArrowButton = styled((props: { onClick: (e: MouseEvent) => void }) => (
  <IconButton {...props} size="small" disableRipple />
))({
  bgcolor: 'transparent',
  padding: 0,
});

type SortIconProps = {
  orderDesc?: boolean;
  selected?: boolean;
  onClick: (desc: boolean) => void;
};
export const SortIcon: React.FC<SortIconProps> = ({ orderDesc, selected, onClick }) => {
  const { grey } = useColors();
  return (
    <div style={{ cursor: 'pointer' }}>
      <Stack spacing={-1}>
        <ArrowButton
          onClick={e => {
            e.stopPropagation();
            onClick(false);
          }}>
          <ArrowUpIcon style={{ color: !orderDesc && selected ? grey.main : grey.light }} />
        </ArrowButton>
        <ArrowButton
          onClick={e => {
            e.stopPropagation();
            onClick(true);
          }}>
          <ArrowDownIcon style={{ color: orderDesc && selected ? grey.main : grey.light }} />
        </ArrowButton>
      </Stack>
    </div>
  );
};

type Props<T extends object> = {
  listTestId?: string;
  columnsDefinition: ColumnsDefinition<T>;
  data: T[];
  noItemsPlaceholder: React.ReactNode;
  loading?: boolean;
  keyExtractor: (data: T) => string | number;
  infiniteLoader?: {
    isItemLoaded: (index: number) => boolean;
    loadMoreItems: (startIndex: number, stopIndex: number) => void;
    threshold?: number;
  };
  itemCount: number;
  itemsToShowBeforeScroll?: number;
  serverSideSorting?: boolean;
  style?: React.CSSProperties;
  initialSort?: { id: string; desc: boolean }[];
  onSortChange?: (sorting: SortingRule<T>[]) => void;
  rowTestIdBuilder?: (data: T) => string;
  customHeaderContent?: React.ReactNode;
  onRowClick?: (data: T, index: number) => void;
  rowHeight?: number;
  rowPropsOverride?: Partial<React.ComponentProps<typeof SeparatorRow>>;
  hideContentOnMobile?: boolean;
};

function Table<T extends object>({
  columnsDefinition,
  data,
  loading,
  style: tableStyle,
  infiniteLoader,
  noItemsPlaceholder,
  itemCount,
  listTestId,
  keyExtractor,
  serverSideSorting,
  initialSort = [],
  onSortChange,
  rowTestIdBuilder,
  itemsToShowBeforeScroll,
  customHeaderContent,
  onRowClick,
  rowHeight = 80,
  rowPropsOverride,
  hideContentOnMobile,
}: Props<T>) {
  const t = useTranslate();
  const d = useDateTimeFormat();
  const { isMobile } = useBreakpoints();
  const threshold = itemsToShowBeforeScroll && itemsToShowBeforeScroll > itemCount
    ? itemCount
    : itemsToShowBeforeScroll;
  const height = tableStyle?.height && tableStyle.height < itemCount * rowHeight
    ? itemCount * rowHeight
    : tableStyle?.height ?? itemCount * rowHeight;
  const style = { ...tableStyle, height: threshold ? rowHeight * threshold : height };

  const columns = useMemo(
    () => columnsDefinition.map(c => {
      const OriginalCellComponent = c.Cell;

      const col = {
        // @ts-ignore
        id: c.id,
        accessor: c.accessor,
        width: c.width ?? null,
        Cell: (props: CellProps<T>) => (
          // @ts-ignore
          <OriginalCellComponent
            cellValue={props.value}
            rowData={props.row.original}
            translate={t}
            dateTimeFormat={d}/>
        ),
        align: c.align,
        disableSortBy: !c.sortable,
        truncate: c.truncate,
      } as Column<T>;
      if (c.Header) {
        const OriginalHeaderComponent = c.Header;

        col.Header = typeof OriginalHeaderComponent === 'string' ? (
          c.Header
        ) : (
        // @ts-ignore
          <OriginalHeaderComponent translate={t} dateTimeFormat={d} />
        );
      }
      return col;
    }),
    [columnsDefinition, t, d],
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      // @ts-ignore
      data,
      disableMultiSort: true,
      manualSortBy: serverSideSorting || !!infiniteLoader,
      initialState: {
        sortBy: initialSort,
      },
    },
    useFlexLayout,
    useSortBy,
  );

  useEffect(() => {
    if (sortBy !== initialSort) {
      onSortChange?.(sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  function rowComponent(rowProps: RenderRowProps<Row<T>>) {
    const row = rowProps.data[rowProps.index];
    prepareRow(row);
    return (
      <SeparatorRow
        data-test={
          rowTestIdBuilder
            ? rowTestIdBuilder(row.original)
            : `${listTestId}-${keyExtractor(row.original)}`
        }
        noSeparator={rowProps.data.length === rowProps.index + 1}
        alignItems="center"
        py={1}
        px={2}
        clickable={!!onRowClick}
        onClick={() => onRowClick?.(row.original, rowProps.index)}
        height={`${rowHeight}px`}
        {...row.getRowProps()}
        {...rowPropsOverride}
        style={{ ...row.getRowProps().style, ...rowProps.style }}>
        {row.cells.map(cell => (
          <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
        ))}
      </SeparatorRow>
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoRenderComponent = useCallback(React.memo(rowComponent), [
    onRowClick,
    rowHeight,
    rowPropsOverride,
  ]);

  const rowKeyExtractor = useCallback(
    (index: number, rs: Row<T>[]) => keyExtractor(rs[index].original),
    [keyExtractor],
  );

  // Render the UI for your table
  return (
    <TableContainer direction="column">
      {customHeaderContent ?? (
        <TableHeader>
          {headerGroups.map(headerGroup => (
            <Stack
              direction="row"
              alignItems="center"
              py={1}
              {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justifyContent={(column as any).align}>
                    <Box>{column.render('Header')}</Box>
                    {column.canSort && (
                      <SortIcon
                        selected={column.isSorted}
                        orderDesc={column.isSortedDesc}
                        onClick={(desc: boolean) => column.toggleSortBy(desc)}/>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </Stack>
          ))}
        </TableHeader>
      )}
      {!(hideContentOnMobile && isMobile) && (
        <TableBody>
          {loading && (
            <AbsoluteBox top={2} left={0} right={0}>
              <LinearProgressBar />
            </AbsoluteBox>
          )}
          {data.length === 0 && !loading ? (
            <Stack height={rowHeight} alignItems="center" justifyContent="center">
              {typeof noItemsPlaceholder === 'string' ? (
                <Typography variant="body1" color="text.info">
                  {noItemsPlaceholder}
                </Typography>
              ) : (
                noItemsPlaceholder
              )}
            </Stack>
          ) : (
            <VirtualizedList
              style={style}
              testId={listTestId}
              itemSize={rowHeight}
              keyExtractor={rowKeyExtractor}
              infiniteLoader={
                /**
                   this is needed to reset scroll if loading is true and the data changes,
                   otherwise the scroll position is kept and a lot of load more are fired
                   one after another to try to reach that scroll position in the infinite scroll.
                   The workaround is to disable infinite loading when there is no data
                   * */
                data.length ? infiniteLoader : undefined
              }
              RowComponent={memoRenderComponent}
              list={rows}
              itemCount={itemCount}/>
          )}
        </TableBody>
      )}
    </TableContainer>
  );
}

const MemoTable = React.memo(Table) as typeof Table;

Table.displayName = 'Table';
export default MemoTable;
