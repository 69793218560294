import React from 'react';
import InputBase from '@mui/material/InputBase';
import { useTranslate } from '@xtreamsrl/react-i18n';
import InputAdornment from '@mui/material/InputAdornment';
import { CloseIcon, SearchIcon } from '../../icons';
import { styled } from '../_styles';

const IconWrapper = styled('div')(({ theme }) => ({
  padding: '10px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey.light,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  borderRadius: '100px',
  backgroundColor: 'white',
  border: `2px solid ${theme.palette.grey.light}`,
  height: '40px',
  '&:hover, &.Mui-focused': {
    borderColor: theme.palette.grey.main,
    svg: {
      fill: theme.palette.grey.main,
    },
  },
  color: 'inherit',
}));

type Props = {
  onChange: (search: string) => void;
  value: string;
  autoFocus?: boolean;
};
const SearchBar: React.FC<Props> = ({ onChange, autoFocus, value }) => {
  const translate = useTranslate();

  return (
    <StyledInputBase
      data-test="searchbar"
      autoFocus={autoFocus}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconWrapper
            onClick={() => onChange('')}
            sx={{
              visibility: value !== '' ? 'visible' : 'hidden',
              '.MuiSvgIcon-root:hover': {
                cursor: 'pointer',
              },
            }}>
            <CloseIcon />
          </IconWrapper>
        </InputAdornment>
      }
      onChange={e => onChange(e.target.value)}
      placeholder={translate('labels.search')}/>
  );
};

SearchBar.displayName = 'SearchBar';
export default React.memo(SearchBar);
