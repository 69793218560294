import React from 'react';
import MUIAppbar, { AppBarProps } from '@mui/material/AppBar';

type Props = {
  color?: AppBarProps['color'];
  position?: AppBarProps['position'];
  style?: AppBarProps['sx'];
};
const Appbar: React.FC<Props> = ({
  color, position, style, children,
}) => (
  <MUIAppbar color={color} position={position} sx={style}>
    {children}
  </MUIAppbar>
);

Appbar.displayName = 'Appbar';
export default Appbar;
