import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getInvitationsForUserAPI from '../apis/getInvitationsForUser';

const {
  hook: useGetInvitationsForUser,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invitations', () => getInvitationsForUserAPI());

export { useGetInvitationsForUser };

export default {
  fulfilled,
  rejected,
  pending,
};
