import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { AppStartListening } from '@financial-tool/store';
import getInvoicesFromTaxFolderActions from './settings/asyncHandlers/getInvoicesFromTaxFolder';
import getUserDataActions from './user-session/asyncHandlers/getUserData';
import { selectCurrentWorkspace, selectUser } from './user-session/slice/userSlice';

const hotjarListenerMiddleware = createListenerMiddleware();
export const startAppListening = hotjarListenerMiddleware.startListening as AppStartListening;

// todo move this
export const changeRoute = createAction('routing/change-route');

type Hotjar =
  | {
    fireEvent: (eventName: string) => void;
    identifyUser: (userId: string, data: object) => void;
  }
  | undefined;

// eslint-disable-next-line prefer-destructuring
function hotjar(): Hotjar {
  const { hj } = window as any;
  if (hj) {
    return {
      fireEvent: eventName => hj('event', eventName),
      identifyUser: (userId, data) => hj('identify', userId, data),
    };
  } else {
    return undefined;
  }
}

startAppListening({
  actionCreator: getInvoicesFromTaxFolderActions.fulfilled,
  effect: () => {
    hotjar()?.fireEvent('invoice-imported');
  },
});

// TODO: track bank connected

startAppListening({
  actionCreator: getUserDataActions.fulfilled,
  effect: (action, { getOriginalState }) => {
    const user = action.payload;
    const ws = selectCurrentWorkspace(getOriginalState());
    if (ws) {
      hotjar()?.identifyUser(user.id, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        company: ws.name,
      });
    }
  },
});

startAppListening({
  actionCreator: changeRoute,
  effect: (_, { getOriginalState }) => {
    const user = selectUser(getOriginalState());
    const ws = selectCurrentWorkspace(getOriginalState());
    if (user && ws) {
      hotjar()?.identifyUser(user.id, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        company: ws.name,
      });
    }
  },
});

export { hotjarListenerMiddleware, hotjar };
