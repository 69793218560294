import { styled } from '../_styles';
import Stack from '../Stack';
import Box from '../Box';

export const TableContainer = styled(Stack)(() => ({
  backgroundColor: 'white',
  boxShadow: '0px 4px 14px 4px rgba(0, 0, 0, 0.05)',
  borderRadius: 8,
}));

export const TableHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingY: theme.spacing(1),
  fontWeight: 'bold',
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '8px 8px 0 0',
}));

export const TableBody = styled(Box)(({ theme }) => ({
  flex: 1,
  flexBasis: 0,
  position: 'relative',
  padding: theme.spacing(1),
}));

export const TableCell = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  '&:not(:last-child)': {
    marginRight: theme.spacing(1),
  },
}));

TableCell.displayName = 'TableCell';
