import {
  IconButton, MENU_WIDTH, NAVBAR_HEIGHT, styled,
} from '@financial-tool/components';
import Drawer from '@mui/material/Drawer';
import { Tabs } from '@mui/material';

// TODO: ivestigate theme type issue
const openedMixin = (theme: any): any => ({
  width: MENU_WIDTH,
  transition: theme.transitions.create(['width', 'visibility'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: any): any => ({
  transition: theme.transitions.create(['width', 'visibility'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

export const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    position: 'relative',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open
      ? {
        ...openedMixin(theme),
      }
      : {
        ...closedMixin(theme),
      }),

    '& .MuiDrawer-paper': {
      ...(open
        ? {
          ...openedMixin(theme),
        }
        : {
          ...closedMixin(theme),
          'p.MuiTypography-root': {
            visibility: 'hidden',
          },
        }),
      borderRight: `2px solid ${theme.palette.grey.superLight}`,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      justifySelf: 'center',
      top: NAVBAR_HEIGHT,
      bottom: '0',
      left: '0',
      height: 'unset',
      padding: '30px',
      paddingTop: '40px',
      [theme.breakpoints.up('sm')]: {
        padding: '16px 0',
      },
      overflow: 'visible',
    },
  },
}));

export const MenuTabs = styled(Tabs)(() => ({
  borderRight: 1,
  borderColor: 'divider',
  width: '100%',
  '.MuiTabs-indicator': {
    left: 0,
    width: 4,
    borderRadius: '0 8px 8px 0',
  },
  '.MuiTab-root': {
    textTransform: 'unset',
  },
}));

export const CloseMenuButton = styled(IconButton)(({ theme: { palette } }) => ({
  position: 'absolute',
  right: -10,
  backgroundColor: 'white',
  border: `2px solid ${palette.grey.superLight}`,
  width: '24px',
  height: '24px',
  svg: {
    width: '16px',
    height: '16px',
  },
  '&:hover': {
    backgroundColor: 'white',
  },
}));
