import { fetch } from '@financial-tool/fetch';
import { Invoice } from '../types/invoice';

export default function patchInvoiceAPI(
  installmentId: string,
  body: {
    paid: boolean;
  },
) {
  return fetch.patch<Invoice>(`/invoicing/installments/${installmentId}`, body);
}
