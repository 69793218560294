import React from 'react';
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import Stack from '../Stack';

type Props = {
  variant: 'circular';
  color?: 'primary' | 'secondary' | 'text';
  size?: CircularProgressProps['size'];
};

const Loading: React.FC<Props> = ({ variant, size, color = 'primary' }) => {
  if (variant === 'circular') {
    return (
      <Stack position="relative" role="progressbar" alignItems="center" justifyContent="center">
        <CircularProgress
          variant="determinate"
          sx={{
            // @ts-ignore
            color: theme => theme.palette[color].main,
            opacity: 0.2,
          }}
          size={size}
          thickness={4}
          value={100}/>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            // @ts-ignore
            color: theme => theme.palette[color].main,
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
              strokeDasharray: '10px, 200px',
            },
          }}
          size={size}
          thickness={4}
          value={10}/>
      </Stack>
    );
  }
  return <></>;
};

Loading.displayName = 'Loading';

export default Loading;
