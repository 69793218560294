import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import deleteBilledAmountAPI from '../apis/deleteBilledAmount';

const {
  hook: useDeleteBilledAmount,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('estimated/delete', async (id: string) => {
  await deleteBilledAmountAPI(id);
  return id;
});

export { useDeleteBilledAmount };

export default {
  fulfilled,
  rejected,
  pending,
};
