import React, { useCallback, useEffect } from 'react';
import { useTranslate, useTranslateWithFormatting } from '@xtreamsrl/react-i18n';
import {
  Link, Loading, Stack, Typography,
} from '@financial-tool/components';
import { FormInput, useFormContext } from '@financial-tool/forms';
import { errorMessages } from '@financial-tool/utils';
import { FormInputProps } from '../FormInput/FormInput';
// todo move this in shared folder
import useValidateVat from '../../../user-session/hooks/useGetVatInfo';
import { VatInfo } from '../../../user-session/types/user';
import { AuthEvents, useTracker } from '../../../user-session/analytics';
import { useAuthentication } from '../../../user-session/hooks/useAuthentication';

type Props = Omit<FormInputProps, 'label' | 'type'> & {
  label?: string;
  onValidationSuccess?: (companyData: VatInfo) => void;
};

const calendlyLink = {
  Link: ({ children }: { children: React.ReactNode }) => (
    <Link
      href="https://calendly.com/cashoo-setup/15min"
      openInNewTab
      color="error"
      variant="caption">
      {children}
    </Link>
  ),
};

const VatFormInput: React.FC<Props> = ({
  name, label, onValidationSuccess, ...props
}) => {
  const translate = useTranslate();
  const formatting = useTranslateWithFormatting();
  const { track } = useTracker();
  const { userEmail } = useAuthentication();
  const { watch, formState } = useFormContext();
  const value = watch(name);
  const companyName = watch('companyName');
  const companyAddress = watch('companyAddress');

  const invalid = name in formState.errors;
  const {
    getVatInfo,
    response,
    error: invalidVat,
    errorMessage,
    errorInfo,
    success,
    loading,
  } = useValidateVat();

  useEffect(() => {
    if (!invalid && value.length === 11) {
      getVatInfo(value);
    }
  }, [value, getVatInfo, invalid]);

  const trackVatAttempt = useCallback(() => {
    if (userEmail && value !== '' && invalid) {
      track(AuthEvents.VatAttempt, { vatCode: value, loggedEmail: userEmail });
    }
  }, [value, invalid, userEmail, track]);

  useEffect(() => {
    if (success && response && onValidationSuccess) {
      onValidationSuccess(response);
    }
  }, [value, getVatInfo, invalid, response, onValidationSuccess, success]);

  useEffect(() => {
    if (invalidVat && userEmail) {
      track(AuthEvents.VatAttempt, { vatCode: value, loggedEmail: userEmail, error: errorInfo });
    }
  }, [value, invalidVat, userEmail, track, errorInfo]);

  let helperText = (
    <Typography variant="caption" color="text.info">
      {translate(errorMessages.vatNumber)}
    </Typography>
  );

  if (invalidVat && errorMessage) {
    helperText = (
      <Typography variant="caption" color="error">
        {formatting(errorMessage, { components: calendlyLink })}
      </Typography>
    );
  }

  if (loading) {
    helperText = (
      <Stack direction="row" spacing={1} alignItems="center">
        <Loading variant="circular" size="15px" />
        <Typography variant="caption">{translate('signup.vatValidation.loading')}</Typography>
      </Stack>
    );
  }

  if (!loading && companyName && !invalidVat) {
    helperText = (
      <Typography
        variant="caption"
        color="primary"
        textTransform="capitalize">{`${companyName.toLowerCase()} - ${companyAddress.toLowerCase()}`}</Typography>
    );
  }

  return (
    <FormInput
      {...props}
      onBlur={trackVatAttempt}
      name={name}
      placeholder="Es: 10278370967"
      label={label ?? translate('signup.vatNumber')}
      error={invalidVat}
      helperText={helperText}
      type="text"/>
  );
};

VatFormInput.displayName = 'VatFormInput';
export default VatFormInput;
