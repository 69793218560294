import React from 'react';
import MUICardHeader, { CardHeaderProps } from '@mui/material/CardHeader';

type Props = {
  avatar?: CardHeaderProps['avatar'];
  title: CardHeaderProps['title'];
  subheader?: CardHeaderProps['subheader'];
  action?: CardHeaderProps['action'];
};

const CardHeader: React.FC<Props> = props => <MUICardHeader sx={{ padding: 0 }} {...props} />;

CardHeader.displayName = 'CardHeader';

export default CardHeader;
