import React, { useMemo } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList, { TabListProps } from '@mui/lab/TabList';
import MUITab from '@mui/material/Tab';
import { styled } from '../_styles';
import ScrollableContainer from '../ScrollableContainer';
import Box from '../Box';

const StyledTabs = styled(TabList)({
  padding: '5px',
  marginBottom: '16px',
  minHeight: 'unset',
  '.MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(MUITab)(({ theme }) => ({
  padding: '6px 12px',
  minHeight: 'unset',
  fontWeight: 700,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '15px',
  },
}));

type Props = {
  currentTab: string;
  onChange: TabListProps['onChange'];
  list: { icon?: React.ReactElement; value: string; label: string }[];
  scrollableContent?: boolean;
};

const Tabs: React.FC<Props> = ({
  currentTab, onChange, list, scrollableContent, children,
}) => {
  const tabsElements = useMemo(
    () => list.map(l => (
      <StyledTab
        data-test={`${l.value}-tab`}
        key={l.value}
        icon={l.icon}
        iconPosition={l.icon ? 'start' : undefined}
        value={l.value}
        label={l.label}
        disableRipple/>
    )),
    [list],
  );

  const Container = scrollableContent ? ScrollableContainer : Box;

  return (
    <TabContext value={currentTab}>
      <StyledTabs onChange={onChange}>{tabsElements}</StyledTabs>
      <Container>{children}</Container>
    </TabContext>
  );
};

Tabs.displayName = 'Tabs';

export default Tabs;
