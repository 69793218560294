import React from 'react';
import loadingAnimation from './loading-animation.gif';

const width = 100;
const LoadingAnimation: React.VFC = () => (
  <img src={loadingAnimation} alt="loading..." height={width / 2} width={width} />
);

LoadingAnimation.displayName = 'LoadingAnimation';

export default LoadingAnimation;
