import React, { useState } from 'react';
import MUITextField, { TextFieldProps } from '@mui/material/TextField';
import { CalendarPickerView } from '@mui/x-date-pickers/internals/models';
// eslint-disable-next-line import/no-extraneous-dependencies
import MUIDatePicker from '@mui/lab/DesktopDatePicker';
import MUIPickersDay from '@mui/lab/PickersDay';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { errorMessages } from '@financial-tool/utils';
import { ClickAwayListener, InputAdornment, InputLabel } from '@mui/material';
import { CloseIcon } from '@financial-tool/icons';
import { styled, useColors } from '../_styles';
import Stack from '../Stack';

const StyledDataPicker = styled(MUIDatePicker)({});

type Props = {
  label: string;
  value: Date | undefined;
  views: readonly CalendarPickerView[];
  inputFormat: string;
  onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  helperText?: React.ReactNode;
  error?: boolean;
  id?: string;
  placeholder?: string;
  allowNullDates?: boolean;
  disableManualInput?: boolean;
};

const DatePicker: React.FC<Props> = ({
  error,
  helperText = ' ',
  name,
  disabled = false,
  required,
  label,
  value,
  views,
  inputFormat,
  onChange,
  placeholder,
  id,
  allowNullDates = false,
  disableManualInput = false,
}) => {
  const t = useTranslate();
  const colors = useColors();
  const [dateValue, setDateValue] = useState<Date | undefined | null>(value);
  const [focusLabel, setFocusLabel] = useState(false);
  const [open, setOpen] = useState(false);
  const labelStyle = focusLabel ? 'secondary' : undefined;

  return (
    <ClickAwayListener onClickAway={() => setFocusLabel(false)}>
      <Stack spacing={1}>
        <InputLabel htmlFor={name} color={error ? 'error' : labelStyle} required={required}>
          {label}
        </InputLabel>
        <StyledDataPicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderDay={(date, selectedDate, props) => <MUIPickersDay {...props} />}
          value={dateValue}
          views={views}
          disabled={disabled}
          inputFormat={inputFormat}
          // @ts-ignore
          PaperProps={{ 'data-test': 'calendar-picker' }}
          InputAdornmentProps={{
            position: 'start',
            sx: {
              '.MuiIconButton-root': {
                ':hover': { backgroundColor: 'transparent' },
                marginLeft: 0,
                paddingX: 0,
              },
              svg: {
                fill: colors.grey.light,
              },
            },
          }}
          onChange={
            ((date: Date, inputValue: string | undefined) => {
              setDateValue(date);
              if (allowNullDates) onChange(date);
              if (
                !allowNullDates
                && ((inputValue === undefined && date !== null)
                  || (inputValue !== undefined && Date.isValidFormat(inputValue, inputFormat)))
              ) {
                onChange(date);
              }
            }) as any
          }
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
            const {
              inputProps, error: validationError, InputProps, ...par
            } = params;
            return (
              <MUITextField
                id={id}
                required={required}
                name={name}
                onClick={disableManualInput ? () => setOpen(true) : undefined}
                onFocus={() => setFocusLabel(true)}
                helperText={validationError === true ? t(errorMessages.fullDate) : helperText}
                error={validationError === true ? validationError : error}
                type="date"
                InputProps={{
                  ...InputProps,
                  endAdornment: allowNullDates && (
                    <InputAdornment
                      sx={{ cursor: 'pointer' }}
                      position="end"
                      onClick={e => {
                        e.stopPropagation();
                        onChange(null);
                        setDateValue(null);
                      }}>
                      <CloseIcon />
                    </InputAdornment>
                  ),
                }}
                {...par}
                sx={{
                  '.MuiOutlinedInput-input': {
                    textTransform: 'capitalize',
                    cursor: disableManualInput ? 'pointer' : undefined,
                  },
                }}
                inputProps={{
                  ...inputProps,
                  readOnly: false,
                  placeholder,
                  disabled: disableManualInput,
                }}/>
            );
          }}/>
      </Stack>
    </ClickAwayListener>
  );
};

DatePicker.displayName = 'DatePicker';
export default DatePicker;
