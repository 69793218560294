import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { InvoiceDirectionEnum } from '../types/invoice';
import getInvoicesOverviewAPI from '../apis/getInvoicesOverview';

const {
  hook: useFetchInvoicesOverview,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('invoices/get-overview', (direction: InvoiceDirectionEnum) => getInvoicesOverviewAPI(direction));

export { useFetchInvoicesOverview };

export default {
  fulfilled,
  rejected,
  pending,
};
