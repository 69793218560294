/* eslint-disable no-nested-ternary */
import React from 'react';
import { ButtonProps } from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '../Stack';
import Loading from '../Loading';

type Props = {
  testId?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  style?: ButtonProps['sx'];
  href?: string;
};
const Button: React.FC<Props> = ({
  testId,
  type,
  disabled,
  loading,
  className,
  variant,
  children,
  onClick,
  size,
  color,
  startIcon,
  endIcon,
  style,
  href,
}) => (
  <LoadingButton
    href={href}
    data-test={testId}
    loadingPosition={endIcon ? 'end' : startIcon ? 'start' : undefined}
    loadingIndicator={<Loading size={18} color="text" variant="circular" />}
    disabled={disabled}
    disableRipple
    type={type}
    loading={loading}
    className={className}
    variant={variant}
    size={size}
    color={color}
    startIcon={startIcon}
    endIcon={endIcon}
    onClick={onClick}
    sx={style}>
    <Stack
      direction="row"
      alignItems="center"
      pr={loading ? 3 : undefined}
      style={{ whiteSpace: 'nowrap' }}>
      {children}
    </Stack>
  </LoadingButton>
);

Button.displayName = 'Button';
export default Button;
