import React, { useCallback, useEffect } from 'react';
import { useDialog } from '@financial-tool/components';
import WelcomeModal from './WelcomeModal';
import useCurrentUser from '../../../user-session/hooks/useCurrentUser';
import { useSetUserAsWelcomed } from '../../../user-session/asyncHandlers/setUserAsWelcomed';

const WelcomeModalManager: React.FC = () => {
  const [isOpen, openDialog, closeDialog] = useDialog(false);

  const { execute } = useSetUserAsWelcomed();

  const { user } = useCurrentUser();

  const close = useCallback(() => {
    execute();
    closeDialog();
  }, [closeDialog, execute]);

  useEffect(() => {
    if (user && !user.welcomed) {
      openDialog();
    }
  }, [openDialog, user]);

  if (!isOpen) {
    return null;
  } else {
    return <WelcomeModal close={close} />;
  }
};

WelcomeModalManager.displayName = 'WelcomeModal';

export default WelcomeModalManager;
