import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getVatInfoAPI from '../apis/getVatInfo';

const {
  hook: useGetVatInfo,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('vat/info', (vatNumber: string) => getVatInfoAPI(vatNumber));

export { useGetVatInfo };

export default {
  fulfilled,
  rejected,
  pending,
};
