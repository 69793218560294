import {
  DeepPartial,
  FormState,
  UnpackNestedValue,
  useForm as useReactHookForm,
  useFormContext as useReactHookFormContext,
  UseFormReset,
  UseFormReturn,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type FormProps<T> = {
  initialValues: UnpackNestedValue<DeepPartial<T>>;
  validationSchema: yup.ObjectSchema<any>;
};

export type ReturnUserForm<T> = {
  formProps: UseFormReturn<T>;
  reset: UseFormReset<T>;
  formState: FormState<T>;
};

export function useForm<T extends object>({
  initialValues,
  validationSchema,
}: FormProps<T>): ReturnUserForm<T> {
  const formProps = useReactHookForm<T>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: initialValues,
  });

  return {
    formProps,
    reset: formProps.reset,
    formState: formProps.formState,
  };
}

export function useFormContext() {
  const { setError, watch, formState } = useReactHookFormContext();

  return {
    setError,
    watch,
    formState,
  };
}
