/* eslint-disable max-len */
import React, { useMemo, useRef, useState } from 'react';
import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import { StringDateFormat } from '@financial-tool/i18n';
import {
  Card, ColoredChip, Popper, Stack, Typography, useMenu,
} from '@financial-tool/components';
import { CalendarIcon } from '@financial-tool/icons';
import { PickersDay } from '@mui/x-date-pickers';
import StaticDatePicker from '../StaticDatePicker';
import { StaticDatePickerProps } from '../StaticDatePicker/StaticDatePicker';

type Props = { testId?: string } & (
  | {
    variant: undefined;
    selected: string;
    onChange: (value: string) => void;
  }
  | {
    variant: 'range';
    selected: [string, string];
    onChange: (value: [string, string]) => void;
  }
);

const DateFilter: React.FC<Props> = ({
  testId, variant, selected, onChange,
}) => {
  const t = useTranslate();
  const [range, setRange] = useState(variant === 'range' ? selected : undefined);
  const pendingChangeRef = useRef(false);
  const {
    openMenu, closeMenu, isOpen, anchorEl,
  } = useMenu();
  const f = useDateTimeFormat();

  const closeMenuAndFlushChanges = () => {
    closeMenu();
    if (pendingChangeRef.current && variant === 'range' && range) {
      onChange(range.map(d => new Date(d).toISOString()) as [string, string]);
      pendingChangeRef.current = false;
    }
  };

  // TODO: handle tuple type better
  const handleChange = (newValue: string | null, index: number) => {
    const selectedValue = f(new Date(newValue!), StringDateFormat.FULL_DATE_DASH);

    if (variant === 'range' && selected[index] !== selectedValue) {
      const newRange = range!.map((date, i) => (i === index ? selectedValue : date));
      setRange(newRange as [string, string]);
      if (index === 1) {
        onChange(newRange.map(d => new Date(d).toISOString()) as [string, string]);
        closeMenu();
      } else {
        pendingChangeRef.current = true;
      }
    } else if (variant === undefined && selected !== selectedValue) {
      onChange(newValue ? new Date(selectedValue).toISOString() : new Date().toISOString());
      closeMenu();
    }
  };

  const renderDay: StaticDatePickerProps['renderDay'] = (day, selecteDays, pickersDayProps) => {
    const isBetween = range
      ? new Date(day).isBetween(new Date(range[0]), new Date(range[1]))
      : false;
    return (
      <PickersDay
        {...pickersDayProps}
        className={isBetween ? 'range' : ''}
        data-test={f(new Date(day), StringDateFormat.FULL_DATE_DASH)}/>
    );
  };

  const label = useMemo(
    () => (variant === 'range'
      ? selected.map(v => f(v, StringDateFormat.FULL_DATE_MONTH_NAME)).join(' - ')
      : f(selected, StringDateFormat.FULL_DATE_MONTH_NAME)),
    [f, selected, variant],
  );

  return (
    <>
      <ColoredChip
        data-test={testId}
        variant="filter"
        className="selected"
        clickable
        icon={<CalendarIcon style={{ fontSize: 16, marginLeft: 12 }} />}
        onClick={e => (isOpen ? closeMenuAndFlushChanges() : openMenu(e.currentTarget))}
        label={label}
        color="secondary"/>

      <Popper open={isOpen} anchorEl={anchorEl} onClickAway={closeMenuAndFlushChanges}>
        <Card mt={2}>
          {variant === 'range' && (
            <Stack spacing={2} direction="row" justifyContent="space-evenly">
              <Typography flex={1} textAlign="center" variant="body2" color="text.info">
                {t('labels.startDate')}
              </Typography>
              <Typography flex={1} textAlign="center" variant="body2" color="text.info">
                {t('labels.endDate')}
              </Typography>
            </Stack>
          )}
          <Stack spacing={2} direction="row">
            <StaticDatePicker
              views={['day']}
              value={new Date(variant === 'range' ? selected[0] : selected)}
              renderDay={variant === 'range' ? renderDay : undefined}
              onChange={v => handleChange(v, 0)}
              shouldDisableDate={day => (variant === 'range' && range ? new Date(day).isAfter(new Date(range[1])) : false)
              }/>

            {variant === 'range' && (
              <StaticDatePicker
                views={['day']}
                value={new Date(selected[1])}
                renderDay={renderDay}
                shouldDisableDate={day => (range ? new Date(day).isBefore(new Date(range[0])) : false)
                }
                onChange={v => handleChange(v, 1)}/>
            )}
          </Stack>
        </Card>
      </Popper>
    </>
  );
};

DateFilter.displayName = 'DateFilter';
export default DateFilter;
