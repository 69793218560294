import { useCallback, useEffect, useRef } from 'react';

const openPopupWindow = (link: string) => window.open(
  link,
  '',
  'top=10, left=10, width=500, height=800, status=no, menubar=no, toolbar=no scrollbars=no',
);

function useWindowWithCallback(
  onClose?: (callbackUrl: string) => void,
  config = {
    pollInterval: 500,
    firstWait: 5000,
  },
) {
  const onCloseRef = useRef(onClose);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  const handleCallback = useCallback(
    (url: string, openInPopup: boolean | undefined = false) => {
      const popup = openInPopup ? openPopupWindow(url) : window.open(url, '_blank');

      if (popup) {
        setTimeout(() => {
          const int = setInterval(() => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              const callbackUrl = popup?.location.href;
              popup.close();
              clearInterval(int);
              onCloseRef.current?.(callbackUrl);
              // eslint-disable-next-line no-empty
            } catch (e) {
              // popup?.location.href will throw for security reason until the
              // popup do not return on the same domain
            }
          }, config.pollInterval);
        }, config.firstWait);
      }
    },
    [config.firstWait, config.pollInterval],
  );

  return {
    open: handleCallback,
  };
}

export default useWindowWithCallback;
