import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import { BilledAmountEntity } from '../types/billedAmount';
import deleteBilledAmount from '../asyncHandlers/deleteBilledAmount';
import updateBilledAmount from '../asyncHandlers/updateBilledAmount';
import createBilledAmount from '../asyncHandlers/createBilledAmount';
import loadBilledAmounts from '../asyncHandlers/loadBilledAmounts';

export const billedAmountsAdapter = createEntityAdapter<BilledAmountEntity>({});

const billedAmountsSlice = createSlice({
  name: 'billedAmounts',
  initialState: billedAmountsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadBilledAmounts.fulfilled, (state, action) => {
        billedAmountsAdapter.setAll(state, action.payload);
      })
      .addCase(createBilledAmount.fulfilled, (state, action) => {
        billedAmountsAdapter.addOne(state, action.payload);
      })
      .addCase(deleteBilledAmount.fulfilled, (state, action) => {
        billedAmountsAdapter.removeOne(state, action.payload);
      })
      .addCase(updateBilledAmount.fulfilled, (state, action) => {
        billedAmountsAdapter.updateOne(state, action.payload);
      });
  },
});
const selectBilledAmounts = (state: RootState) => state.billedAmounts;
const selectors = billedAmountsAdapter.getSelectors(selectBilledAmounts);

export const selectBilledAmountsEntities = selectors.selectAll;

export default billedAmountsSlice.reducer;
