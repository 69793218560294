import { fetch } from '@financial-tool/fetch';
import { queryStringify } from '@financial-tool/utils';
import { InvoiceDirectionEnum, InvoicesOverview } from '../types/invoice';

export default function getInvoicesOverviewAPI(direction: InvoiceDirectionEnum) {
  return fetch.get<InvoicesOverview>(
    `/invoicing/overview?${queryStringify(
      { direction },
      {
        encode: false,
        arraySeparator: ',',
        skipNull: true,
      },
    )}`,
  );
}
