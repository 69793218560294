import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { BilledAmountEntity } from '../types/billedAmount';
import updateBilledAmountAPI from '../apis/updateBilledAmount';

const {
  hook: useUpdateBilledAmount,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions(
  'estimated/update',
  async (billedAmount: BilledAmountEntity) => {
    const updatedBilledAmount = await updateBilledAmountAPI(billedAmount);
    const { id, ...changes } = updatedBilledAmount;
    return {
      id,
      changes: { ...changes },
    };
  },
);

export { useUpdateBilledAmount };

export default {
  fulfilled,
  rejected,
  pending,
};
