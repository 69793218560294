import React from 'react';
import { styled } from '../_styles';
import Box from '../Box';

type Props = {
  device: 'mobile' | 'desktop';
};

const Container = styled(Box, { shouldForwardProp: prop => prop !== 'device' })(
  ({ device }: Props) => ({ theme: { breakpoints } }) => ({
    [breakpoints[device === 'mobile' ? 'down' : 'up']('sm')]: {
      display: 'none',
    },
  }),
);
const HideOnDevice: React.FC<Props> = ({ children, device }) => (
  <Container device={device}>{children}</Container>
);

HideOnDevice.displayName = 'HideOnDevice';
export default HideOnDevice;
