import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';

import { RevenueData } from './types/settings';
import getInvoicesSync from './asyncHandlers/getInvoicesSync';
import getInvoicesFromTaxFolder from './asyncHandlers/getInvoicesFromTaxFolder';

type State = {
  revenueData: RevenueData | null;
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    value: null,
    revenueData: null,
  } as State,
  extraReducers: builder => {
    builder
      .addCase(getInvoicesSync.fulfilled, (state, action) => {
        state.revenueData = action.payload;
      })
      .addCase(getInvoicesFromTaxFolder.fulfilled, state => {
        state.revenueData = { ...state.revenueData, turnover: NaN, running: true };
      });
  },
  reducers: {},
});
export default settingsSlice.reducer;
const selectSettingsState = (state: RootState) => state.settings;

export const selectRevenueData = createSelector(selectSettingsState, state => state.revenueData);
export const selectParsingError = createSelector(
  selectSettingsState,
  state => !!state.revenueData?.invoicesWithErrors,
);
