import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import getUserWorkspaceAPI from '../apis/getUserWorkspace';

const {
  hook: useGetUserWorkspace,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('workspaces/userWorkspace', async (workspaceId: string) => getUserWorkspaceAPI(workspaceId));

export { useGetUserWorkspace };

export default {
  fulfilled,
  rejected,
  pending,
};
