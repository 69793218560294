/* eslint-disable max-len */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import { UserRole } from '../../user-session/types/user';
import getWorkspaceUsers from '../../workspaces/asyncHandlers/getWorkspaceUsers';
import removeUserFromWorkspace from '../../workspaces/asyncHandlers/removeUserFromWorkspace';
import { WorkspaceUser } from '../types';
import revokeInvitation from '../asyncHandlers/revokeInvitation';
import resendInvitation from '../asyncHandlers/resendInvitation';

type State = {
  users: WorkspaceUser[];
};

const companySlice = createSlice({
  name: 'company',
  initialState: {
    users: [],
  } as State,
  extraReducers: builder => {
    builder
      .addCase(getWorkspaceUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(removeUserFromWorkspace.fulfilled, (state, action) => {
        const { userId } = action.meta.arg;

        if (userId) {
          state.users = state.users.filter(({ userId: id }) => id !== userId);
        }
      })
      .addCase(revokeInvitation.fulfilled, (state, action) => {
        const { invitationId } = action.payload;
        state.users = state.users.filter(({ invitation }) => (invitation ? invitation.id !== invitationId : true));
      })
      .addCase(resendInvitation.fulfilled, (state, action) => {
        const { id, status, lastSentDate } = action.payload;
        state.users = state.users.map(({ invitation, ...rest }) => (invitation && invitation.id === id
          ? {
            ...rest,
            invitation: {
              id,
              status,
              lastSentDate,
            },
          }
          : { ...rest, invitation }));
      });
  },
  reducers: {},
});
export default companySlice.reducer;
const selectCompanyState = (state: RootState) => state.company;
export const selectCompanyUsers = createSelector(selectCompanyState, company => {
  const dividedByRole = [...company.users].reduce(
    (sorted, user) => {
      const role = user.roles[0];
      return { ...sorted, [role]: [...sorted[role], user] };
    },
    {
      [UserRole.admin]: [],
      [UserRole.accountant]: [],
      [UserRole.colleague]: [],
    },
  );

  return [
    ...dividedByRole[UserRole.admin],
    ...dividedByRole[UserRole.accountant],
    ...dividedByRole[UserRole.colleague],
  ];
});
