import React from 'react';
import {
  Dialog, Link, Stack, Typography,
} from '@financial-tool/components';
import { LogoutRoundedIcon } from '@financial-tool/icons';
import { useTranslate } from '@xtreamsrl/react-i18n';

const EmailVerificationNeededDialog: React.VFC<{ onClose: () => void }> = ({ onClose }) => {
  const translate = useTranslate();
  return (
    <Dialog
      open
      testId="email-verification-dialog"
      onClose={onClose}
      closeIcon={<LogoutRoundedIcon />}
      title={translate('splash.emailVerification.title')}
      titleProps={{ variant: 'h4', fontWeight: 'bold', textAlign: 'center' }}>
      <Typography textAlign="center" style={{ whiteSpace: 'break-spaces' }}>
        {translate('splash.emailVerification.content')}
      </Typography>
      <Stack justifyContent="center" direction="row" spacing={2}>
        {translate('splash.emailVerification.support.message')}
        <Link email={translate('splash.emailVerification.support.email')}>
          <Typography ml={0.5} style={{ wordBreak: 'keep-all' }}>
            {translate('splash.emailVerification.support.email')}
          </Typography>
        </Link>
      </Stack>
    </Dialog>
  );
};

EmailVerificationNeededDialog.displayName = 'EmailVerificationNeededDialog';
export default EmailVerificationNeededDialog;
