import React, { useContext } from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import Stack from '../Stack';
import ProductImg from '../ProductImg';
import Dialog from '../Dialog';
import Typography from '../Typography';
import { styled } from '../_styles';

type TrapDoorContextType = {
  isOpen: boolean;
  openTrapDoor: () => void;
  closeTrapDoor: () => void;
};
const TrapDoorContext = React.createContext<TrapDoorContextType>({
  isOpen: false,
  openTrapDoor: () => {},
  closeTrapDoor: () => {},
});

export const useTrapDoor = () => {
  const { openTrapDoor } = useContext(TrapDoorContext);

  return {
    openTrapDoor,
  };
};

export const TrapDoorProvider = TrapDoorContext.Provider;

const StyledStack = styled(Stack)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  [breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    'div:last-child': {
      marginLeft: '48px',
    },
  },
}));
const TrapDoorDialog: React.VFC = () => {
  const t = useTranslate();
  const { isOpen, closeTrapDoor } = useContext(TrapDoorContext);

  return (
    <Dialog
      testId="trapdoor-dialog"
      open={isOpen}
      maxWidth="md"
      onClose={closeTrapDoor}
      noClose
      buttons={{ primary: { onClick: closeTrapDoor, text: t('buttons.close') } }}>
      <StyledStack>
        <Stack spacing={1}>
          <Typography fontWeight="bold" color="secondary" textTransform="uppercase">
            {t('general.demo.product')}
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            {t('general.trapdoor.title')}
          </Typography>
          <Typography color="text.light">{t('general.trapdoor.content')}</Typography>
        </Stack>

        <Stack direction="column" flex={1} alignItems="flex-end" justifyContent="center">
          <ProductImg width="100%" p="16px 0 0 0" />
        </Stack>
      </StyledStack>
    </Dialog>
  );
};

TrapDoorDialog.displayName = 'TrapDoorDialog';
export default TrapDoorDialog;
