import React, { useEffect } from 'react';
import {
  Appbar,
  DemoBanner,
  HideOnDevice,
  IconButton,
  Logo,
  Stack,
  styled,
  Toolbar,
  Typography,
  useMenu,
} from '@financial-tool/components';
import { MenuIcon } from '@financial-tool/icons';
import { Tab, Tabs } from '@mui/material';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { FeatureWrapper, Feature } from '@financial-tool/feature-flags';
import { useEnvVars } from '@xtreamsrl/react-config';
import LoggedUser from './LoggedUser';
import { User } from '../../../user-session/types/user';
import { linkStyle } from '../Menu/linkStyle';
import { MenuItem } from '../Menu/menuLinks';
import PopupMobileMenu from '../Menu/PopupMobileMenu';
import AccountantBanner from '../../../accountant/component/AccountantBanner';

type Props = {
  user: User | null;
  items: MenuItem[];
  initialSelectedItem?: string;
};

const MenuTabs = styled(Tabs)(({ theme }) => {
  const { color, ...rest } = linkStyle(theme);
  return {
    minHeight: '64px',
    '.MuiTabs-scroller': {
      display: 'flex',
      alignItems: 'center',
    },
    '.MuiTabs-indicator': {
      height: 4,
      borderRadius: '8px',
    },
    '.MuiTab-root': {
      textTransform: 'unset',
      whiteSpace: 'nowrap',
      flexGrow: 0,
      color,
      '&.Mui-selected .MuiTypography-root': {
        ...rest['&.active, &.active .MuiTypography-root'],
      },
    },
  };
});

const MenuButton = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey.superLight,
  borderRadius: 30,
  padding: '8px 16px',
}));

const LogoWrapper = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '263px',
  },
  alignItems: 'flex-start',
}));

const MenuAppbar: React.FC<Props> = ({ user, items }) => {
  const t = useTranslate();
  const config = useEnvVars();
  const [selectedItem, setSelectedItem] = React.useState(1);
  const {
    openMenu, closeMenu, isOpen, anchorEl,
  } = useMenu();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window.location.origin.includes(config.appUrl)) return setSelectedItem(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, window.location.origin]);

  const current = items[1];

  return (
    <Appbar position="fixed" color="inherit" style={{ width: '100vw', right: 'auto' }}>
      <Toolbar disableGutters style={{ justifyContent: 'space-between', width: '100vw' }}>
        <Stack direction="row" alignItems="center">
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <FeatureWrapper feature={Feature.CREDIMI_LINKS}>
            <HideOnDevice device="mobile">
              <MenuTabs
                value={selectedItem}
                onChange={(e, v) => {
                  setSelectedItem(v);
                }}>
                {items.map(({ onClick, translationLabel, testId }) => (
                  <Tab
                    data-test={`${testId}-tab`}
                    key={translationLabel}
                    id={translationLabel}
                    onClick={onClick}
                    label={<Typography>{t(`menu.${translationLabel}`)}</Typography>}/>
                ))}
              </MenuTabs>
            </HideOnDevice>
          </FeatureWrapper>
        </Stack>
        <FeatureWrapper feature={Feature.CREDIMI_LINKS}>
          <HideOnDevice device="desktop">
            <Stack>
              <MenuButton
                onClick={e => openMenu(e.currentTarget)}
                spacing={1}
                direction="row"
                alignItems="center">
                <Typography>{t(`menu.${current.translationLabel}`)}</Typography>

                <IconButton
                  size="large"
                  disableRipple
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ padding: 0 }}
                  color="inherit">
                  <MenuIcon />
                </IconButton>
              </MenuButton>

              <PopupMobileMenu
                title={t('menu.menu')}
                menuItems={items}
                exactLinks={false}
                isOpen={isOpen}
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                footer={<LoggedUser user={user} />}/>
            </Stack>
          </HideOnDevice>
        </FeatureWrapper>
        <DemoBanner />
        <AccountantBanner />
        <LoggedUser user={user} />
      </Toolbar>
    </Appbar>
  );
};

MenuAppbar.displayName = 'MenuAppbar';
export default MenuAppbar;
