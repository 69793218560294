import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
// todo move this into shared configuration provider
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import dayjs from 'dayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { I18nProvider } from '@xtreamsrl/react-i18n';
import { RequestsProvider } from '@financial-tool/data-management';
import { store } from '@financial-tool/store';
import { useEnvVars } from '@xtreamsrl/react-config';
import {
  ErrorToast,
  ThemeProvider,
  SuccessToast,
  RevokedAccessScreen,
  useDialog,
  TrapDoorDialog,
  TrapDoorProvider,
} from '@financial-tool/components';
import { GlobalFeedbackProvider } from '@xtreamsrl/react-feedback-management';
import { FeatureFlagProvider } from '@financial-tool/feature-flags';
import { Main } from './_layout';
import { bootstrap } from './_shared/init';

import 'dayjs/locale/it';

bootstrap();

function App() {
  const [isOpen, openTrapDoor, closeTrapDoor] = useDialog();
  const locale = 'it';
  const { auth0, flagKey } = useEnvVars();

  return (
    <Auth0Provider
      domain={auth0.domain}
      audience={auth0.audience}
      clientId={auth0.clientId}
      redirectUri={window.location.origin}
      cacheLocation="localstorage">
      <FeatureFlagProvider apiKey={flagKey}>
        <RequestsProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('it')}>
            <Router>
              <Provider store={store}>
                <GlobalFeedbackProvider>
                  <I18nProvider locale={locale} changeLocale={() => {}}>
                    <ThemeProvider>
                      <TrapDoorProvider value={{ isOpen, openTrapDoor, closeTrapDoor }}>
                        <ErrorToast />
                        <SuccessToast />
                        <RevokedAccessScreen />
                        <TrapDoorDialog />
                        <Main />
                      </TrapDoorProvider>
                    </ThemeProvider>
                  </I18nProvider>
                </GlobalFeedbackProvider>
              </Provider>
            </Router>
          </LocalizationProvider>
        </RequestsProvider>
      </FeatureFlagProvider>
    </Auth0Provider>
  );
}

if ((window as any).Cypress) {
  import('./cypressThings').then(module => module.makeCypressHappy(store));
}

export default App;
