import React from 'react';
import CollapseMUI, { CollapseProps } from '@mui/material/Collapse';

type Props = {
  open: CollapseProps['in'];
  timeout: CollapseProps['timeout'];
  unmountOnExit?: CollapseProps['unmountOnExit'];
};

const Collapse: React.FC<Props> = ({ open, ...props }) => (
  <CollapseMUI in={open} color="red" {...props} />
);

Collapse.displayName = 'Collapse';

export default Collapse;
