import React from 'react';
import CashooLogo from './cashoo-logo.png';

type Props = {};

const Logo: React.VFC<Props> = () => <img src={CashooLogo} height={30} alt="logo" />;

Logo.displayName = 'Logo';

export default Logo;
