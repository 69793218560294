export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  invitationStatus?: InvitationStatus;
  workspaces: Workspace[];
  welcomed?: boolean;
};

export type Workspace = {
  id: string;
  name: string;
  vatNumber: string;
  roles: UserRole[];
  users: { id: string; roles: UserRole[] }[];
};

export enum InvitationStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  REVOKED = 'REVOKED',
}

export enum UserRole {
  admin = 'admin',
  accountant = 'accountant',
  colleague = 'colleague',
}

export type UserParams = {
  firstName: string;
  lastName: string;
  companyName: string;
  vatNumber: string;
  companyAddress: string;
};

export type VatInfo = {
  countryCode: string;
  vatNumber: string;
  name: string;
  address: string;
};
