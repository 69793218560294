import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { Theme } from '@mui/material';
import { scrollbarStyle } from './ScrollableContainer/ScrollableContainer.styled';
import { styleOverrides as ButtonStyle } from './Button/Button.style.overrides';
import {
  adornmentStyleOverrides,
  lableStyleOverrides,
  textFieldStyleOverrides,
} from './TextField/TextField.style.overrides';
import { checkboxStyleOverrides } from '../forms/FormCheckBox/Checkbox.style.overrides';
import { datePickerStyleOverrides } from './DatePicker/DatePicker.style.overrides';
import { dialogStyleOverrides } from './Dialog/Dialog.style.overrides';

const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 2px 4px rgba(3, 50, 67, 0.2)',
    '0 4px 8px rgb(3 50 67 / 20%)',
    '0 6px 16px rgb(3 50 67 / 20%)',
    ...new Array(21).fill('0 6px 16px rgb(3 50 67 / 20%)'),
  ] as Shadows,
  palette: {
    primary: {
      superLight: '#D6EDFC',
      light: '#8CCDF5',
      main: '#0076BF',
      dark: '#0F69A1',
    },
    secondary: {
      superLight: '#D6EDFC',
      main: '#0076BF',
      light: '#8CCDF5',
      dark: '#0F69A1',
      contrastText: '#FFFFFF',
    },
    success: {
      superLight: '#E0ECE0',
      light: '#95BE94',
      main: '#599A57',
      dark: '#417140',
    },
    warning: {
      light: '#F8E673',
      superLight: '#FDF8D7',
      main: '#e1b614',
      dark: '#B39E1A',
    },
    error: {
      light: '#EF8181',
      superLight: '#FADBDB',
      main: '#E73939',
      dark: '#A92A2A',
    },
    text: {
      primary: '#033243',
      main: '#033243',
      light: '#3F6471',
      info: '#8CA1A9',
      link: {
        light: '#8CCDF5',
        main: '#0076BF',
        dark: '#1369A3',
      },
    },
    background: {
      default: '#F6F8F9',
      light: '#F0F3F4',
      overlay: 'rgba(3, 50, 67, 0.75)',
    },
    incomes: {
      superLight: '#E0ECE0',
      light: '#95BE94',
      main: '#599A57',
      dark: '#417140',
    },
    expenses: {
      light: '#EF8181',
      superLight: '#FADBDB',
      main: '#E73939',
      dark: '#A92A2A',
    },
    grey: {
      main: '#8CA1A9',
      dark: '#476976',
      light: '#D1D9DC',
      superLight: '#E8ECED',
      50: '#F6F8F9',
      100: '#E8ECED',
      200: '#D1D9DC',
      300: '#D1D9DC',
      400: '#8CA1A9',
      500: '#8CA1A9',
      600: '#476976',
      700: '#476976',
    },
    // @ts-ignore
    black: {
      main: '#033243',
    },
    neutral: {
      light: '#8CCDF5',
      main: '#0076BF',
      dark: '#1369A3',
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: ({ theme: t }: { theme: Theme }) => ({
          backgroundColor: t.palette.grey.main,
        }),
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '.custom-scrollbar': scrollbarStyle,
        body: {
          maxWidth: '100vw',
          overflow: 'hidden',
          ...scrollbarStyle,
        },
        '.recharts-tooltip-wrapper': {
          zIndex: 1000,
        },
        html: {
          fontSize: '16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: ButtonStyle,
    },
    MuiInputLabel: { styleOverrides: lableStyleOverrides },
    MuiInputBase: { styleOverrides: textFieldStyleOverrides },
    MuiCheckbox: { styleOverrides: checkboxStyleOverrides },
    // @ts-ignore
    MuiCalendarPicker: { styleOverrides: datePickerStyleOverrides },
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiInputAdornment: { styleOverrides: adornmentStyleOverrides },
    MuiSelect: {
      styleOverrides: {
        iconOpen: ({ theme: t }) => ({ fill: t.palette.secondary.main }),
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: ({ theme: t }) => ({
            color: t.palette.secondary.main,
            '.MuiSelect-icon': {
              color: t.palette.secondary.main,
            },
            fieldset: {
              border: 'none',
            },
            '.MuiSelect-select': {
              padding: 0,
              '&:focus': {
                backgroundColor: 'white',
              },
            },
            '&.MuiInput-underline:after, &.Mui-focused:after, &.MuiInput-underline:before, &.MuiInput-underline:hover:before':
              {
                border: 'none',
                transform: 'unset',
                transition: 'unset',
                animation: 'unset',
              },
          }),
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicatorOpen: ({ theme: t }) => ({ svg: { color: t.palette.secondary.main } }),
        endAdornment: { 'button:hover': { backgroundColor: 'unset' } },
      },
    },
    // TODO: remove this when we have the menu/select items ui specs
    MuiMenuItem: {
      variants: [
        {
          // @ts-ignore
          props: { variant: 'popup' },
          style: ({ theme: t }) => ({
            color: t.palette.text.light,
            '.MuiListItemIcon-root': {
              color: t.palette.text.light,
            },
            '&.Mui-selected, &.Mui-selected:hover, &:hover': {
              color: t.palette.grey.main,
              backgroundColor: 'white',
              '.MuiListItemIcon-root': {
                color: t.palette.grey.main,
              },
            },
          }),
        },
      ],
      styleOverrides: {
        root: ({ theme: t }) => ({
          '&.Mui-selected, &.Mui-selected:hover, &:hover': {
            color: 'white',
            backgroundColor: t.palette.secondary.main,
            '.MuiListItemIcon-root': {
              color: 'white',
            },
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          backgroundColor: t.palette.background.default,
          color: t.palette.grey.light,
          padding: 0,
          margin: 8,
        }),
      },
    },
    ...dialogStyleOverrides,
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          borderBottom: `2px solid ${t.palette.grey.superLight}`,
          boxShadow: 'none',
          '.MuiToolbar-root': {
            padding: '0 16px',
          },
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '.BaseBadge-badge': {
            position: 'initial',
            transform: 'none',
            marginLeft: '8px',
            '&.MuiBadge-invisible': { display: 'none' },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ theme: t }) => ({ '&.MuiSvgIcon-colorDisabled': { color: t.palette.grey.main } }),
      },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: ({ theme: t }) => ({ backgroundColor: t.palette.grey.main }) },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: ({ theme: t }) => ({
          backgroundColor: t.palette.success.superLight,
          color: t.palette.success.main,
          borderRadius: '0.5rem',
        }),
        filledError: ({ theme: t }) => ({
          backgroundColor: t.palette.error.superLight,
          color: t.palette.error.main,
          borderRadius: '0.5rem',
        }),
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'filter', color: 'secondary' },
          style: ({ theme: { palette } }) => ({
            fontSize: '16px',
            backgroundColor: palette.background.default,
            border: `2px solid ${palette.grey.light}`,
            color: palette.text.light,
            fontWeight: 'bold',
            '&.selected, &:hover': {
              color: 'white',
              backgroundColor: palette.secondary.main,
              border: `2px solid ${palette.secondary.main}`,
            },
            '.MuiChip-avatarColorSecondary': {
              backgroundColor: 'white',
            },
          }),
        },
        {
          props: { variant: 'avatar', color: 'secondary' },
          style: ({ theme: { palette } }) => ({
            backgroundColor: 'white',
            color: palette.text.light,
            fontWeight: 'bold',
            '.MuiChip-label': {
              display: 'none',
            },
            '.MuiChip-avatar': {
              margin: 4,
            },
            '&.selected, &:hover': {
              color: 'white',
              backgroundColor: palette.secondary.main,
            },
            '.MuiChip-avatarColorSecondary': {
              backgroundColor: 'white',
            },
          }),
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          // @ts-ignore
          props: { variant: 'filled' },
          style: ({ theme: t }) => ({
            color: t.palette.secondary.contrastText,
            backgroundColor: t.palette.secondary.main,
            '&:hover': {
              backgroundColor: t.palette.secondary.dark,
            },
          }),
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme: t }) => ({
          color: t.palette.secondary.main,
          height: 8,
          padding: '8px 0',
          '& .MuiSlider-thumb': {
            height: 22,
            width: 22,
            backgroundColor: t.palette.secondary.main,
            border: '2px solid white',
            boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.02), 0px 2px 3px 1px rgba(0, 0, 0, 0.12)',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-rail': {
            color: t.palette.grey.light,
            height: 8,
          },
        }),
      },
    },
  },
});
export default theme;
