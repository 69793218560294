import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import setUserAsWelcomed from '../apis/setUserAsWelcomed';

const {
  hook: useSetUserAsWelcomed,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('user/me/welcome', () => setUserAsWelcomed());

export { useSetUserAsWelcomed };

export default {
  fulfilled,
  rejected,
  pending,
};
