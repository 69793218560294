import {
  AnyAction,
  combineReducers,
  configureStore,
  Middleware,
  TypedStartListening,
  CombinedState,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThunkDispatch } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { config } from '@xtreamsrl/react-config';
import billedAmountsReducer from '../../estimated-billed-amounts/slice/billedAmountsSlice';
import cashFlowReducer from '../../dashboard/slice/cashFlowSlice';
import userReducer from '../../user-session/slice/userSlice';
import expensesReducer from '../../expenses/slice/expensesSlice';
import settingsReducer from '../../settings/slice';
import invoicesReducer from '../../invoices/slice';
import transactionsReducer from '../../transactions/slice';
import { hotjarListenerMiddleware } from '../../hotjarIntegration';
import companyReducer from '../../company/slice/companySlice';
import planningReducer from '../../planning/slice';
import deletePlanningRow from '../../planning/asyncHandlers/deletePlanningRow';

const appReducer = combineReducers({
  billedAmounts: billedAmountsReducer,
  cashflow: cashFlowReducer,
  user: userReducer,
  expenses: expensesReducer,
  settings: settingsReducer,
  invoices: invoicesReducer,
  company: companyReducer,
  transactions: transactionsReducer,
  planning: planningReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  switch (action.type) {
    case 'state/reset':
      return appReducer(
        /*
         * This is due to the fact that we want to reset only part of the state.
         * As the other pieces of state are undefined,
         * they will be populated according to their initial state.
         */
        // @ts-ignore
        {
          user: state.user,
        },
        action,
      );
    default:
      return appReducer(state, action);
  }
};

// eslint-disable-next-line max-len
const blockActionMiddleware: Middleware<
{},
CombinedState<{}>,
ThunkDispatch<CombinedState<{}>, any, any>
> = st => next => action => {
  if (!config.demo) {
    return next(action);
  }

  const {
    thunk, fulfilled, pending, rejected,
  } = deletePlanningRow;

  const whitelist = [
    'planning/get',
    fulfilled.type,
    pending.type,
    rejected.type,
    'planning/setError',
  ];
  const isWhitelisted = !whitelist.some(str => action.type.includes(str));

  if (action.type.includes('planning') && isWhitelisted) {
    st.dispatch(thunk('demo'));
    return null;
  }

  return next(action);
};
export const store = configureStore({
  reducer: rootReducer,
  // eslint-disable-next-line max-len
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([hotjarListenerMiddleware.middleware, blockActionMiddleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
