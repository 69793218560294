import { useSelector } from 'react-redux';
import {
  selectCompany,
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectUser,
} from '../slice/userSlice';
import { UserRole } from '../types/user';

const useCurrentUser = () => {
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);
  const workspace = useSelector(selectCurrentWorkspace);
  const workspaceId = useSelector(selectCurrentWorkspaceId);

  return {
    user,
    company,
    workspace,
    workspaceId,
    admin: workspace?.roles.some(r => r === UserRole.admin),
  };
};
export default useCurrentUser;
