import { fetch } from '@financial-tool/fetch';
import { queryStringify } from '@financial-tool/utils';
import { PlanningEntryBody, PlanningResponse } from '../types';

export default function updatePlanningAPI({ categoryId, ...entry }: PlanningEntryBody) {
  return fetch.post<PlanningResponse>(
    `/plannings/${categoryId}/entries?${queryStringify({
      start: new Date().subtract(3, 'M').format('YYYY-MM'),
      end: new Date().add(12, 'M').format('YYYY-MM'),
    })}`,
    entry,
  );
}
