import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import {
  Invoice,
  InvoiceOverviewFilters,
  InvoicesFilters,
  InvoicesOverview,
  InvoiceStatusEnum,
  TableInvoice,
} from './types/invoice';
import getInvoices from './asyncHandlers/getInvoices';
import patchInvoice from './asyncHandlers/patchInvoice';
import getInvoicesOverview from './asyncHandlers/getInvoicesOverview';
import getDashboardInvoices from './asyncHandlers/getDashboardInvoices';

const invoicesAdapter = createEntityAdapter<Invoice>({
  selectId: invoice => invoice.installmentId,
});

type InvoicesState = {
  filters: InvoicesFilters;
  periodHasChanged: boolean;
  invoices: EntityState<Invoice>;
  summary: InvoicesOverview;
  hasNext: boolean;
  totalCount: number;
};

function getInvoiceStatus(invoice: Invoice): InvoiceStatusEnum {
  if (invoice.paid) {
    return 'paid';
  } else if (new Date(invoice.dueDate).isBefore(new Date())) {
    return 'overdue';
  } else {
    return 'unpaid';
  }
}

export const changeFilters = createAction<Partial<InvoicesFilters>>('invoices/change-filters');
export const removeInvoice = createAction<Partial<string>>('invoices/mark-as-paid');

const defaultFilters: InvoicesFilters = {
  startDate: new Date().startOf('y').toISOString(),
  endDate: new Date().endOf('M').toISOString(),
  sortDirection: 'desc',
  sortBy: 'invoiceDate',
  keyword: '',
  direction: 'emitted',
  pageIndex: 0,
  status: 'all',
};

const slice = createSlice({
  name: 'invoices',
  initialState: {
    invoices: invoicesAdapter.getInitialState(),
    hasNext: true,
    totalCount: 0,
    summary: {
      credits: null,
      debits: null,
      overdue: null,
      unpaid: null,
    },
    filters: defaultFilters,
  } as InvoicesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(removeInvoice, (state, action) => {
        const invoice = state.invoices.entities[action.payload];
        state.invoices = invoicesAdapter.removeOne(state.invoices, action.payload);
        if (invoice) {
          const summary = state.summary[invoice.direction === 'emitted' ? 'credits' : 'debits'];
          if (summary) {
            summary.number -= 1;
            summary.amount -= invoice.amount;
          }
        }
      })
      .addCase(changeFilters, (state, action) => {
        const pageIndexChanged = Object.keys(action.payload).length === 1 && 'pageIndex' in action.payload;

        let somethingChanged = false;
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in action.payload) {
          const objectKey = key as keyof InvoicesFilters;
          if (state.filters[objectKey] !== action.payload[objectKey]) {
            // @ts-ignore
            state.filters[objectKey] = action.payload[objectKey];
            somethingChanged = true;
          }
        }
        if (somethingChanged) {
          state.filters.pageIndex = action.payload.pageIndex || 0;
        }

        if (!pageIndexChanged) {
          state.invoices = invoicesAdapter.removeAll(state.invoices);
        }
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        if (action.meta.arg.pageIndex === 0) {
          state.invoices = invoicesAdapter.removeAll(state.invoices);
        }
        state.invoices = invoicesAdapter.addMany(state.invoices, action.payload.data);
        state.hasNext = action.payload.hasNext;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getDashboardInvoices.fulfilled, (state, action) => {
        state.invoices = invoicesAdapter.removeAll(state.invoices);
        state.summary.credits = action.payload.credits.overview;
        state.summary.debits = action.payload.debits.overview;
        state.invoices = invoicesAdapter.addMany(state.invoices, [
          ...action.payload.credits.installments,
          ...action.payload.debits.installments,
        ]);
      })
      .addCase(getInvoicesOverview.fulfilled, (state, action) => {
        state.summary = action.payload;
      })
      .addCase(patchInvoice.fulfilled, (state, action) => {
        const invoice = action.payload;

        // todo
        if (!['all', getInvoiceStatus(invoice)].includes(state.filters.status)) {
          state.invoices = invoicesAdapter.removeOne(state.invoices, action.payload.installmentId);
        } else {
          state.invoices = invoicesAdapter.updateOne(state.invoices, {
            id: action.payload.installmentId,
            changes: { paid: invoice.paid },
          });
        }
        if (state.summary.unpaid && state.summary.overdue) {
          if (invoice.paid) {
            state.summary.unpaid.amount -= invoice.amount;
            state.summary.unpaid.number -= 1;
            if (new Date(invoice.dueDate).isBefore(new Date())) {
              state.summary.overdue.amount -= invoice.amount;
              state.summary.overdue.number -= 1;
            }
          } else {
            state.summary.unpaid.amount += invoice.amount;
            state.summary.unpaid.number += 1;
            if (new Date(invoice.dueDate).isBefore(new Date())) {
              state.summary.overdue.amount += invoice.amount;
              state.summary.overdue.number += 1;
            }
          }
        }
      });
  },
});
const selectInvoices = (state: RootState): InvoicesState => state.invoices;
export const selectInvoicesFilters = createSelector(
  selectInvoices,
  invoicesState => invoicesState.filters,
);

export const selectInvoicesOverviewFilters = createSelector(
  selectInvoicesFilters,
  filters => ({
    startDate: filters.startDate,
    endDate: filters.endDate,
    direction: filters.direction,
  } as InvoiceOverviewFilters),
);
export const selectInvoicesFiltersKeyword = createSelector(
  selectInvoicesFilters,
  filters => filters.keyword,
);
export const selectInvoicesHasNext = createSelector(
  selectInvoices,
  invoicesState => invoicesState.hasNext,
);

export const selectInvoicesTotalCount = createSelector(
  selectInvoices,
  invoicesState => invoicesState.totalCount,
);

export const selectInvoicesSummary = createSelector(
  selectInvoices,
  invoicesState => invoicesState.summary,
);

const selectInvoicesData = createSelector(selectInvoices, invoicesState => invoicesState.invoices);

export const selectInvoicesDirection = createSelector(
  selectInvoices,
  invoicesState => invoicesState.filters.direction,
);

export const invoicesSelectors = invoicesAdapter.getSelectors(selectInvoicesData);
export const selectAllInvoices = invoicesSelectors.selectAll;
// eslint-disable-next-line max-len
export const selectAllTableInvoices = createSelector(selectAllInvoices, invoices => invoices.map(i => {
  const status = getInvoiceStatus(i);

  return {
    amount: i.amount,
    supplier: i.counterParty,
    client: i.counterParty,
    dueDate: i.dueDate,
    invoiceDate: i.invoiceDate,
    emittedDate: i.invoiceDate,
    invoiceId: i.invoiceId,
    installmentType: i.installmentType,
    installmentId: i.installmentId,
    installmentOrdinal: i.installmentOrdinal,
    direction: i.direction === 'emitted' ? 'in' : 'out',
    invoiceTitle: i.invoiceTitle,
    paidHint: i.paidHint,
    status,
  } as TableInvoice;
}));

export const selectDashboardInvoices = createSelector(selectAllInvoices, invoices => ({
  credits:
    invoices.filter(inv => inv.direction === 'emitted' && getInvoiceStatus(inv) !== 'paid') ?? [],
  debits:
    invoices.filter(inv => inv.direction === 'received' && getInvoiceStatus(inv) !== 'paid') ?? [],
}));

export default slice.reducer;
