import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import loadExpensesAPI from '../apis/loadExpenses';

const {
  hook: useGetExpenses,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('expenses/load', () => loadExpensesAPI());

export { useGetExpenses };

export default {
  fulfilled,
  rejected,
  pending,
};
