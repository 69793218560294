import React from 'react';
import { Breakpoint } from '@mui/material';
import Hidden from '@mui/material/Hidden';

type Props = {
  show: Partial<{ [key in Breakpoint]: boolean }>;
};

const breaks: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

const ResponsiveVisibility: React.FC<Props> = props => {
  const { show, children } = props;
  const actualShow = breaks.reduce((acc, breakPoint, index, array) => {
    const currentValue = show[breakPoint];
    const previousBreak = array[index - 1] ?? true;
    let actualValue = currentValue;
    if (previousBreak && currentValue === undefined) {
      actualValue = show[previousBreak];
    }
    return {
      ...acc,
      [breakPoint]: actualValue,
    };
  }, {} as { [key in Breakpoint]: boolean });

  return (
    <Hidden
      implementation="css"
      xsUp={!actualShow.xs}
      smUp={!actualShow.sm}
      lgUp={!actualShow.lg}
      mdUp={!actualShow.md}
      xlUp={!actualShow.xl}>
      {children}
    </Hidden>
  );
};

ResponsiveVisibility.displayName = 'ResponsiveVisibility';

export default ResponsiveVisibility;
