import React from 'react';
import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps,
  ClickAwayListenerProps,
} from '@mui/material';

type Props = {
  open: PopperProps['open'];
  anchorEl: PopperProps['anchorEl'];
  placement?: PopperProps['placement'];
  onClickAway: ClickAwayListenerProps['onClickAway'];
};
const Popper: React.FC<Props> = ({
  open,
  anchorEl,
  onClickAway,
  placement = 'bottom-start',
  children,
}) => (
  <MuiPopper open={open} anchorEl={anchorEl} placement={placement}>
    <ClickAwayListener onClickAway={onClickAway}>
      <div>{children}</div>
    </ClickAwayListener>
  </MuiPopper>
);

Popper.displayName = 'Popper';
export default Popper;
