import React, { useEffect } from 'react';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Divider, Menu, Stack, styled, Typography,
} from '@financial-tool/components';
import { FeatureWrapper } from '@financial-tool/feature-flags';
import { MenuItem } from '../menuLinks';
import { linkStyle } from '../linkStyle';

const StyledNavLink = styled(NavLink)(({ theme }) => linkStyle(theme));

type Props = {
  title: string;
  menuItems: MenuItem[];
  isOpen: boolean;
  exactLinks?: boolean;
  anchorEl: HTMLElement | null;
  closeMenu: () => void;
  footer?: React.ReactNode;
};
const PopupMobileMenu: React.FC<Props> = ({
  title,
  menuItems,
  isOpen,
  anchorEl,
  closeMenu,
  footer,
  exactLinks = true,
}) => {
  const t = useTranslate();
  const { pathname } = useLocation();

  useEffect(() => {
    closeMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Menu
      anchorEl={anchorEl}
      onClose={closeMenu}
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Stack spacing={1} my={1}>
        <Typography
          color="text.light"
          textTransform="uppercase"
          pl={3}
          fontWeight="bold"
          variant="body2">
          {title}
        </Typography>
        {menuItems?.map(({
          icon, path, translationLabel, onClick, feature,
        }) => (
          <FeatureWrapper key={translationLabel} feature={feature}>
            <StyledNavLink exact={exactLinks} key={translationLabel} onClick={onClick} to={path}>
              <Stack
                direction="row"
                alignItems="center"
                style={{ overflow: 'hidden', cursor: 'pointer' }}>
                {icon}
                <Typography ml={1} noWrap>
                  {t(`menu.${translationLabel}`)}
                </Typography>
              </Stack>
            </StyledNavLink>
          </FeatureWrapper>
        ))}
        {footer && (
          <Stack alignItems="center">
            <Divider width="90%" />
            {footer}
          </Stack>
        )}
      </Stack>
    </Menu>
  );
};

PopupMobileMenu.displayName = 'PopupMobileMenu';
export default PopupMobileMenu;
