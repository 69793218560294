import { AuthEvents } from './events';

type VatAttemptProps = {
  vatCode: string;
  loggedEmail: string;
  error?: {
    code: number;
    message: string;
  };
};
export const authEventsMapping = {
  [AuthEvents.Login]: () => ({
    type: 'login',
  }),
  [AuthEvents.Onboard]: () => ({
    type: 'signup',
  }),
  [AuthEvents.LogOut]: () => ({
    type: 'logout',
  }),
  [AuthEvents.VatAttempt]: (props: VatAttemptProps) => ({
    type: 'vatAttempt',
    props,
  }),
};
