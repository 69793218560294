import { ColumnDefinition } from './types';

const getTableProps = (props: any) => [
  props,
  {
    style: {
      width: '100%',
    },
  },
];

const getRowStyles = (props: any) => [
  props,
  {
    style: {
      display: 'flex',
      flex: '1 0 auto',
      minWidth: '100%',
      flexWrap: 'nowrap',
    },
  },
];

const getHeaderProps = (props: any, { column }: { column: ColumnDefinition }) => [
  props,
  {
    style: {
      boxSizing: 'content-box',
      flex: column.width ? 'unset' : '1',
      width: column.width ?? 'auto',
      justifyContent: column.align,
    },
  },
];

const getCellProps = (props: any, { cell }: { cell: { column: ColumnDefinition } }) => [
  props,
  {
    style: {
      boxSizing: 'content-box',
      flex: cell.column.width ? 'unset' : '1',
      width: cell.column.width ?? 'auto',
      textAlign: cell.column.align,
      ...(cell.column.truncate ? { minWidth: 0 } : {}),
    },
  },
];

export function useFlexLayout(hooks: any) {
  hooks.getTableProps.push(getTableProps);
  hooks.getRowProps.push(getRowStyles);
  hooks.getHeaderGroupProps.push(getRowStyles);
  hooks.getFooterGroupProps.push(getRowStyles);
  hooks.getHeaderProps.push(getHeaderProps);
  hooks.getCellProps.push(getCellProps);
}

useFlexLayout.pluginName = 'useFlexLayoutCustom';
