/* eslint-disable max-len */
import {
  createAction,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '@financial-tool/store';
import { UserState } from '../types/userState';
import getUserDataThunk from '../asyncHandlers/getUserData';
import signup from '../asyncHandlers/signup';
import getInvitationsForUser from '../../company/asyncHandlers/getInvitationsForUser';
import { Invitation } from '../../company/types';
import { InvitationStatus, UserRole, Workspace } from '../types/user';
import getUserWorkspace from '../../workspaces/asyncHandlers/getUserWorkspace';
import getAccountantClients from '../../accountant/asyncHandlers/getAccountantClients';
import removeUserFromWorkspace from '../../workspaces/asyncHandlers/removeUserFromWorkspace';
import setUserAsWelcomed from '../asyncHandlers/setUserAsWelcomed';

const invitationsAdapter = createEntityAdapter<Invitation>();

const initialState: UserState = {
  user: null,
  defaultWorkspace: undefined,
  currentWorkspace: localStorage.getItem('workspace') ?? undefined,
  token: null,
  loading: true,
  needSignup: undefined,
  accountantMode: undefined,
  invitations: invitationsAdapter.getInitialState(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload === null) {
        state.loading = false;
      }
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNeedSignup: (state, action: PayloadAction<boolean>) => {
      state.needSignup = action.payload;
      state.loading = false;
    },
    toggleAccountantMode: (state, action: PayloadAction<boolean>) => {
      state.accountantMode = action.payload;
    },
    addWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.user!.workspaces.push(action.payload);
    },
    setCurrentWorkspace: (state, action: PayloadAction<string>) => {
      state.currentWorkspace = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signup.fulfilled, state => {
        state.needSignup = false;
      })
      .addCase(getUserDataThunk.fulfilled, (state, action) => {
        const adminWs = action.payload.workspaces.find(
          ({ roles }) => !!roles.find(r => r === UserRole.admin),
        )!.id;
        state.user = action.payload;
        state.defaultWorkspace = state.defaultWorkspace ?? adminWs;
        state.loading = false;
      })
      .addCase(getUserWorkspace.fulfilled, (state, action) => {
        const workspace = {
          ...action.payload,
          roles: action.payload.users.find(({ id }) => state.user?.id === id)!.roles,
        };

        state.currentWorkspace = workspace.id;
      })
      .addCase(getInvitationsForUser.fulfilled, (state, action) => {
        invitationsAdapter.setAll(state.invitations, action.payload);
      })
      .addCase(getAccountantClients.fulfilled, (state, action) => {
        const nonAccWorkspaces = state.user!.workspaces.filter(
          ({ id }) => !action.payload.find(ws => ws.id === id),
        );
        state.user!.workspaces = [...nonAccWorkspaces, ...action.payload];
      })
      .addCase(removeUserFromWorkspace.fulfilled, (state, action) => {
        const { workspaceId, userId } = action.meta.arg;

        if (userId === state.user!.id) {
          state.user!.workspaces = state.user!.workspaces.filter(
            ({ id: wid }) => wid !== workspaceId,
          );
          if (state.user!.workspaces.length === 1) {
            state.currentWorkspace = state.user!.workspaces[0].id;
          }
        }
      })
      .addCase(setUserAsWelcomed.fulfilled, state => {
        if (state.user) {
          state.user.welcomed = true;
        }
      });
  },
});

export const selectUserState = (state: RootState) => state.user;
export const selectUser = createSelector(selectUserState, state => state.user);
export const selectToken = createSelector(selectUserState, state => state.token);
export const selectUserWorkspaces = createSelector(selectUser, user => user?.workspaces);
export const selectIsAuthenticated = createSelector(selectUserState, state => !!state.token);
export const selectIsUserLoading = createSelector(selectUserState, state => state.loading);
export const selectNeedSignupAuthenticated = createSelector(
  selectUserState,
  state => state.needSignup,
);
export const selectCurrentWorkspaceId = createSelector(
  selectUserState,
  state => state.currentWorkspace,
);
export const selectCurrentWorkspace = createSelector(
  selectUserState,
  state => state.user?.workspaces.find(({ id }) => id === state.currentWorkspace) ?? undefined,
);
export const selectDefaultWorkSpace = createSelector(
  selectUserState,
  state => state.defaultWorkspace,
);
export const selectCompany = createSelector(
  selectUserWorkspaces,
  selectCurrentWorkspace,
  selectDefaultWorkSpace,
  (workspaces, currentWs, defaultWsId) => {
    const currentCompany = currentWs?.name;
    const defaultCompany = workspaces?.find(({ id }) => defaultWsId === id)!.name;
    return currentCompany ?? defaultCompany;
  },
);
export const selectAccountantMode = createSelector(selectUserState, state => state.accountantMode);
export const selectAccountantClients = createSelector(
  selectUserWorkspaces,
  workspaces => workspaces?.filter(({ roles }) => roles.find(role => role === UserRole.accountant)) ?? [],
);
export const selectInvitationsState = createSelector(selectUserState, state => state.invitations);

export const logout = createAction('user/logout');

export const selectInvitations = invitationsAdapter.getSelectors(selectInvitationsState);
export const selectInvitationsByStatus = (status: InvitationStatus) => createSelector(selectInvitations.selectAll, invitations => invitations.filter(({ status: invitationStatus }) => status === invitationStatus));

export const {
  setAuthToken,
  setNeedSignup,
  setAuthLoading,
  toggleAccountantMode,
  addWorkspace,
  setCurrentWorkspace,
} = userSlice.actions;

export default userSlice.reducer;
