import Theme from '../../../_shared/components/theme';

export const linkStyle = (theme: typeof Theme) => ({
  width: '100%',
  color: theme.palette.text.light,
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '8px 16px',
  '&.billed': {
    alignItems: 'flex-start',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.light,
  },
  '&.active, &.active .MuiTypography-root': {
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
});
