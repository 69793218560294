import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { BilledAmount } from '../types/billedAmount';
import createBilledAmountAPI from '../apis/createBilledAmount';

const {
  hook: useCreateBilledAmount,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions('estimated/create', (billedAmount: BilledAmount) => createBilledAmountAPI(billedAmount));

export { useCreateBilledAmount };

export default {
  fulfilled,
  rejected,
  pending,
};
