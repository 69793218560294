import React from 'react';
import Box from '../Box';
import { alpha } from '../_styles';

const PercentageBar: React.VFC<{ percentage: number; color: string }> = ({ percentage, color }) => (
  <Box position="absolute" bottom="0px" width="100%" bgcolor={alpha(color, 0.2)} height="10px">
    <Box position="absolute" width={`${percentage * 100}%`} bgcolor={color} height="10px" />
  </Box>
);

PercentageBar.displayName = 'PercentageBar';

export default PercentageBar;
