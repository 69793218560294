import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import { PlanningEntryBody } from '../types';
import updatePlanningAPI from '../apis/updatePlanning';

const {
  start, pending, rejected, fulfilled,
} = createAsyncTaskHandlerWithActions(
  'planning/update',
  (entry: PlanningEntryBody) => updatePlanningAPI(entry),
);

export default {
  thunk: start,
  pending,
  rejected,
  fulfilled,
};
