import React, { useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { EuroIcon } from '@financial-tool/icons';
import { ClickAwayListener, InputLabel } from '@mui/material';
import TextField from '../TextField';
import Stack from '../Stack';
import NumberFormat from '../NumberFormat';

type Props = {
  onValueChange: (values: NumberFormatValues) => void;
  error?: boolean;
  helperText?: string;
  inputRef?: (instance: any) => void;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: number;
  label: string;
  decimalScale?: number;
  hideCurrency?: boolean;
  onFieldCleared?: () => void;
};
const NumberFormatInput: React.FC<Props> = ({
  onValueChange,
  value,
  error,
  helperText,
  inputRef,
  name,
  disabled,
  required,
  placeholder,
  label,
  decimalScale = 2,
  hideCurrency = false,
  onFieldCleared,
}) => {
  const [focusLabel, setFocusLabel] = useState(false);
  const labelStyle = focusLabel ? 'secondary' : undefined;

  return (
    <ClickAwayListener onClickAway={() => setFocusLabel(false)}>
      <Stack>
        <InputLabel htmlFor={name} color={error ? 'error' : labelStyle} required={required}>
          {label}
        </InputLabel>
        <NumberFormat
          id={name}
          value={value ?? ''}
          onValueChange={onValueChange}
          onFocusCapture={({ target }) => {
            setFocusLabel(true);
            target.select();
          }}
          onFieldCleared={onFieldCleared}
          error={error}
          helperText={helperText}
          displayType={'input'}
          thousandSeparator="."
          suffix={hideCurrency ? undefined : '\xa0€'}
          decimalSeparator=","
          decimalScale={decimalScale}
          fixedDecimalScale={true}
          inputRef={inputRef}
          startIcon={<EuroIcon />}
          customInput={TextField}
          name={name}
          disabled={disabled}
          placeholder={placeholder}/>
      </Stack>
    </ClickAwayListener>
  );
};

NumberFormatInput.displayName = 'NumberFormatInput';
export default NumberFormatInput;
