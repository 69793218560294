import { createAsyncTaskHandlerWithActions } from '@financial-tool/request-management';
import patchInvoiceAPI from '../apis/patchInvoice';

const {
  hook: useGetPatchInvoice,
  fulfilled,
  rejected,
  pending,
} = createAsyncTaskHandlerWithActions(
  'invoices/patch',
  (data:
  { installmentId: string; body: { paid: boolean }
  }) => patchInvoiceAPI(data.installmentId, data.body),
);

export { useGetPatchInvoice };

export default {
  fulfilled,
  rejected,
  pending,
};
