import React from 'react';
import { ForecastIcon } from '@financial-tool/icons';
import Indicator from '../Indicator';

type Props = {};

const ForecastIndicator: React.VFC<Props> = () => (
  <Indicator>
    <ForecastIcon
      sx={{
        color: 'grey.dark',
        width: '100%',
        height: '100%',
      }}/>
  </Indicator>
);

ForecastIndicator.displayName = 'ForecastIndicator';

export default ForecastIndicator;
