import React from 'react';
import { ReactComponent as Image } from './planning.svg';

type Props = {};

const PlanningImage: React.VFC<Props> = () => <Image />;

PlanningImage.displayName = 'PlanningImage';

export default PlanningImage;
