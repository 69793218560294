import React from 'react';
import MUIAvatar, { AvatarProps } from '@mui/material/Avatar';

/**
 *
 * Returns the two initials of the input string or the initials of the strings in the input array.
 *
 * ex: 'John Doe' => 'JD' || ['John', 'Doe'] => 'JD'
 *
 */
export const toStringAvatar = (name: string | string[]) => {
  if (typeof name === 'string') {
    return `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  }
  return name.reduce((prev, string) => `${prev}${string.toUpperCase()[0]}`, '');
};

type Props = {
  bgColor?: AvatarProps['color'];
  src?: AvatarProps['src'];
  style?: AvatarProps['sx'];
  alt?: AvatarProps['alt'];
  children?: AvatarProps['children'];
};

const Avatar = React.forwardRef<HTMLDivElement, Props>(({ bgColor, style, ...rest }, ref) => (
  <MUIAvatar ref={ref} sx={{ bgcolor: bgColor, ...(style || {}) }} {...rest} />
));

Avatar.displayName = 'Avatar';

export default Avatar;
