import { configureEnvVars } from '@xtreamsrl/react-config';

configureEnvVars(env => ({
  env: env.REACT_APP_ENV,
  isLocal: env.REACT_APP_ENV === 'local',
  version: env.REACT_APP_VERSION,
  taxFolderGuide: env.REACT_APP_TAX_FOLDER_GUIDE,
  taxFolderVideo: env.REACT_APP_TAX_FOLDER_VIDEO,
  projectTrackingId: env.REACT_APP_MIXPANEL_TRACKING_ID,
  auth0: {
    domain: env.REACT_APP_AUTH0_DOMAIN,
    audience: env.REACT_APP_AUTH0_AUDIENCE,
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
  },
  appUrl: env.REACT_APP_URL,
  productUrl: env.REACT_APP_PRODUCT_URL,
  apiBaseUrl: env.REACT_APP_SERVER_URL,
  flagKey: env.REACT_APP_FEATURE_FLAGS_KEY,
  futuro: {
    dashboardUrl: env.REACT_APP_FUTURO_DASHBOARD,
    lendingUrl: env.REACT_APP_FUTURO_LENDING,
  },
  demo: env.REACT_APP_DEMO === 'true',
}));
