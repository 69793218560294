import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import Typography from '../Typography';
import Stack from '../Stack';
import { AbsoluteBox } from '../Box';

type Props = {
  value: number;
  color: 'primary' | 'secondary' | 'success';
  total: number;
  completed: number;
};

const PercentageCircularIndicator: React.FC<Props> = ({
  value, color, total, completed,
}) => (
  <Stack position="relative" role="progressbar" alignItems="center" justifyContent="center">
    <CircularProgress
      variant="determinate"
      sx={{
        // @ts-ignore
        color: theme => theme.palette.grey[300],
        opacity: 0.2,
      }}
      size={40}
      thickness={4}
      value={100}/>
    <CircularProgress
      variant="determinate"
      disableShrink
      sx={{
        // @ts-ignore
        color: theme => theme.palette[color].main,
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      }}
      size={40}
      thickness={4}
      value={value * 100}/>
    <AbsoluteBox left={0} right={0} top={0} bottom={0} style={{ textAlign: 'center' }}>
      <Typography variant="subtitle1" component="span" style={{ lineHeight: '40px' }}>
        {completed}
      </Typography>
      <Typography
        color="grey.400"
        variant="subtitle1"
        component="span"
        style={{ lineHeight: '40px' }}>
        /{total}
      </Typography>
    </AbsoluteBox>
  </Stack>
);

PercentageCircularIndicator.displayName = 'PercentageCircularIndicator';

export default PercentageCircularIndicator;
