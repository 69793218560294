import React from 'react';
import PageContainer from '../PageContainer';
import Stack from '../Stack';
import ScrollableContainer from '../ScrollableContainer';
import LinearProgressBar from '../LinearProgressBar';

type Props = {
  header?: React.ReactElement;
  loading?: boolean;
};

const ScrollablePage: React.FC<Props> = ({ header = null, loading, children }) => (
  <PageContainer>
    <Stack height="100%" alignItems="center" spacing={2}>
      {header}
      <ScrollableContainer flexGrow={1} width="100%">
        {loading && <LinearProgressBar />}
        {!loading && children}
      </ScrollableContainer>
    </Stack>
  </PageContainer>
);

ScrollablePage.displayName = 'ScrollablePage';

export default ScrollablePage;
